import React from 'react';
import { IconSubsidiaryCompany } from 'assets/icons';

interface OrgSubsidiaryCompanyProps {
	filteredSubsidiaryCompanies: CorporateStructure[];
	organizationList: OrganizationDTO[];
}

function OrgSubsidiaryCompany({ filteredSubsidiaryCompanies, organizationList }: OrgSubsidiaryCompanyProps) {

	// Render individual subsidiary company
	const renderCompany = (companyId: number, index: number) => {
		const company = organizationList.find((org) => org.id === companyId);
		return (
			<div
				className="border-bottom border-gray-200 p-2 d-flex align-items-center"
				key={companyId + '-' + index}
			>
				<IconSubsidiaryCompany className="me-2 smallIcon" />
				<span
					className="d-inline-block text-truncate"
					title={company?.organizationName}
				>
					{company?.organizationName || 'Unknown Company'}
				</span>
			</div>
		);
	};

	return (
		<div className="card p-3 shadow mb-3 rounded flex-1">
			<div className="card-body">
				<div className="d-flex align-items-center mb-3">
					<div className="rounded-circle bg-secondary-100 p-2 me-3">
						<IconSubsidiaryCompany />
					</div>
					<h5 className="mb-0">{
						filteredSubsidiaryCompanies.length > 1 ? 'Subsidiary Organizations' : 'Subsidiary Organization'
					}</h5>
				</div>
				<div className="container-fluid px-0">
					<div className="row">
						<div className="col-12">
							{filteredSubsidiaryCompanies.length > 0 ? (
								filteredSubsidiaryCompanies.map((c, index) =>
									renderCompany(c.subsidiaryCompanyId, index)
								)
							) : (
								<small className="text-muted fst-italic ps-5">
									Not found
								</small>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrgSubsidiaryCompany;
