import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	isOpen: boolean;
	isLoading: boolean;
	idPTDProduct: number | undefined;
	idPTDVendor: number | undefined;
	isDeleting: 'product' | 'vendor' | 'both' | undefined;
	onRequestClose: () => void;
	deletePTDVendor: (idPTDProduct: number | undefined, idPTDVendor: number | undefined, isDeleting: 'product' | 'vendor' | 'both') => Promise<boolean>;
	label: string;
}

function DeletePTDRowModal(props: IProps) {
	const { isOpen, isLoading, idPTDVendor, idPTDProduct, isDeleting, onRequestClose, deletePTDVendor, label } = props;

	async function onDeletePTDVendor() {
		if ((idPTDVendor !== undefined || idPTDProduct !== undefined) && isDeleting !== undefined) {
			await deletePTDVendor(idPTDProduct, idPTDVendor, isDeleting);
			onRequestClose();
		}
	}

	return (
		<div>
			<ModalOverlay
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				confirmButtonChildren="Delete"
				cancelButtonChildren="Cancel"
				confirmButtonAction={onDeletePTDVendor}
				cancelButtonAction={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
			>
				<h3>{label || 'Are you sure you want to delete this item?'}</h3>
				<p>This action cannot be undone.</p>
			</ModalOverlay>
		</div>
	);
}

export default DeletePTDRowModal;
