import React from 'react';
import { ReactComponent as IconLocationSecondary } from 'assets/icons/icon-location-secondary.svg';
import Pill from 'screens/Requests/components/pills/Pill';
import useBreakpointPills from 'utils/useBreakpointPills';

interface IProps {
	orgFacilities?: OrganizationFacilityDTO[];
}

const OrgFacilitiesCard = (props: IProps) => {
	const [showMore, setShowMore] = React.useState(false);
	const truncateNumOfPills = useBreakpointPills();

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	if (props.orgFacilities == undefined || props.orgFacilities.length == 0) {
		return <React.Fragment />;
	}

	return (
		<div className="card shadow p-3 mb-2 rounded">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconLocationSecondary />
				</div>
				<h5 className="mb-0">Facilities / Plants</h5>
			</div>
			<hr />
			<div>
				<div className="d-flex flex-wrap">
					{props?.orgFacilities && props.orgFacilities.length > 0 ? (
						<React.Fragment>
							{props.orgFacilities.slice(0, showMore ? props.orgFacilities.length : truncateNumOfPills).map((facility, sckey) => (
								<Pill
									className="text-primary fw-bold"
									key={`facility-${sckey}`}
								>
									{facility.facilityLocationCity + ', ' + facility.facilityLocationCountry}
								</Pill>
							))}

							{/* Conditionally render three dots when showing less */}
							{!showMore && props.orgFacilities.length > truncateNumOfPills && (
								<span className="fw-bold align-self-end">...</span>
							)}
						</React.Fragment>
					) : (
						<p className="text-gray-600 fst-italic">No certifications and standards specified</p>
					)}
				</div>

				{/* Show More/Show Less Button */}
				{props.orgFacilities.length > truncateNumOfPills && (
					<button onClick={toggleShowMore} className="btn btn-link">
						{showMore ? 'Show Less' : 'Show More'}
					</button>
				)}
			</div>
		</div>

	);
};

export default OrgFacilitiesCard;