import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Buttons } from 'screens/ProductCatalog/components/columns';
import AuthContext from 'context/Auth/AuthContext';
import { usePTD } from 'context/PTD/PTDContext';

interface IProps {
	formMethods: UseFormReturn;
	idPTDList: number;
	isEditingCurrentRow: boolean;
	rowIsDisabled: boolean;
	submitForm: (fields: FieldValues) => Promise<void>;
	closeAddRow: () => void;
	onClickEditOrDuplicate: (type: 'edit' | 'duplicate') => void;
	openDeleteModal: (idPTDList: number) => void;
	onClickView?: () => void;
	isSavingRow: boolean;
	isDuplicateEntryAllowed?: boolean;
}

function ActionCell({
	formMethods,
	idPTDList,
	isEditingCurrentRow,
	rowIsDisabled,
	submitForm,
	closeAddRow,
	onClickEditOrDuplicate,
	openDeleteModal,
	onClickView,
	isSavingRow,
	isDuplicateEntryAllowed = true,
}: IProps) {
	const { currentUser } = React.useContext(AuthContext);
	const { getPTDAccess } = usePTD();
	const access = getPTDAccess();

	const accessForList = access
		? access.find(
			(acc) =>
				acc.email === currentUser.email &&
					acc.premiumTradeDirectoryId === idPTDList
		  )
		: null;
	const canEdit =
		accessForList &&
		(accessForList.permission === 'Edit' ||
			accessForList.permission === 'Suggest');

	const handleDuplicateClick = isDuplicateEntryAllowed
		? () => onClickEditOrDuplicate('duplicate')
		: undefined;

	return (
		<React.Fragment>
			<Buttons
				isEditing={isEditingCurrentRow}
				disabled={rowIsDisabled}
				onClickSave={formMethods.handleSubmit(submitForm)}
				onClickCancel={closeAddRow}
				onClickEdit={() => onClickEditOrDuplicate('edit')}
				onClickDelete={() => openDeleteModal(idPTDList)}
				onClickDuplicate={handleDuplicateClick}
				onClickView={onClickView}
				isSaving={isSavingRow}
				canEdit={canEdit}
			/>
		</React.Fragment>
	);
}

export default ActionCell;
