import classNames from 'classnames';
import { HeadingProps } from '../../types/interfaces';
import React, { PropsWithChildren } from 'react';

function H4({ children, className }: PropsWithChildren<HeadingProps>) {
	return (
		<h4 className={classNames(className, 'text-xl font-bold dark:text-white')}>
			{children}
		</h4>
	);
}

export default H4;
