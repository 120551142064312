import React from 'react';
import classNames from 'classnames';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { IconKeyboardLeft, IconKeyboardRight } from 'assets/icons';
import { Spinner, IconButton } from 'components/core';

interface IProps {
	className?: string;
	filterable?: boolean;
	TableComponent?: any;
	columns?: any[];
	data?: any[];
	getTableProps?: (...args: any[]) => {};
	getTheadFilterThProps?: (...args: any[]) => {};
	getTheadGroupProps?: (...args: any[]) => {};
	getTheadProps?: (...args: any[]) => {};
	getTheadThProps?: (...args: any[]) => {};
	getTbodyProps?: (...args: any[]) => {};
	getTrProps?: (...args: any[]) => {};
	getTdProps?: (...args: any[]) => {};
	minRows?: number;
	hasActions?: boolean;
	resizable?: boolean;
	sortable?: boolean;
	filtered?: any[];
	defaultFiltered?: any;
	onFilteredChange?: any;
	noDataText?: string;
	showPagination?: boolean;
	onPageChange?: (pageIndex: number) => void;
	page?: number;
	// handles clicks on each data table row, if not provided nothing happens
	onRowClick?: ({ row, original }: { row?: any, original?: any }) => void;
	loading?: boolean;
}

const defaultPageSize = 10;

const DataTable = (props: IProps) => {
	const onNavigate = (e: any, callback: () => void) => {
		// TODO: Find a better way to animate the page
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		callback();
	};

	const getTrProps = (_: any, rowInfo: any) => {
		const { onRowClick } = props;
		if (onRowClick) {
			return {
				className: 'tr cursor-pointer',
				onClick(e: any, handleOriginal: any) {
					onRowClick(rowInfo);
					if (handleOriginal) {
						handleOriginal();
					}
				}
			};
		} else {
			return {
				className: 'tr border-bottom border-primary-100'
			};
		}
	};

	const {
		className,
		filterable,
		hasActions,
		TableComponent,
		columns,
		data,
		showPagination = true,
		getTableProps = () => {
			return { className: 'table shadow rounded-bottom' };
		},
		getTheadFilterThProps = () => {
			return { className: 'th' };
		},
		getTheadGroupProps = () => {
			return { className: 'thead theadgroup' };
		},
		getTheadProps = () => {
			return { className: 'thead border-bottom border-top border-primary-200' };
		},
		getTheadThProps = () => {
			return { className: 'th' };
		},
		getTbodyProps = () => {
			return { className: 'tbody' };
		},
		getTdProps = () => {
			return { className: 'td text-start' };
		},
		minRows = 0,
		resizable = false,
		sortable = false,
		noDataText,
		filtered,
		defaultFiltered,
		onFilteredChange,
		onPageChange,
		page,
		loading,
	} = props;
	return (
		<ReactTable
			className={classNames('data-table', hasActions !== false && 'has-actions', className)}
			filterable={filterable}
			onFilteredChange={onFilteredChange}
			filtered={filtered}
			defaultFilterMethod={(filter, row) => {
				const isNewRow = row.id === 0 || row.id === 'duplicate'; // Identify new rows
				if (isNewRow) {
					return true; // Always show new or duplicate rows
				}
				return String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase());
			}}
			defaultFiltered={defaultFiltered}
			defaultPageSize={showPagination ? defaultPageSize : data?.length }
			TableComponent={TableComponent}
			columns={columns}
			data={data}
			showPagination={data !== undefined && data.length !== 0 && showPagination}
			getTableProps={getTableProps}
			getTheadFilterThProps={getTheadFilterThProps}
			getTheadGroupProps={getTheadGroupProps}
			getTheadProps={getTheadProps}
			getTheadThProps={getTheadThProps}
			getTbodyProps={getTbodyProps}
			getTrProps={getTrProps}
			getTdProps={getTdProps}
			minRows={minRows}
			resizable={resizable}
			loading={loading}
			LoadingComponent={() => <div className={classNames('-loading', loading && '-active')}>
				<div className="-loading-inner">
					<Spinner className="spinner" />
				</div>
			</div>}
			sortable={sortable}
			PreviousComponent={p => (
				<IconButton
					onClick={(e: any) => onNavigate(e, p.onClick)}
					disabled={p.disabled}
					className="btn-link"
				>
					<IconKeyboardLeft />
				</IconButton>
			)}
			NextComponent={p => (
				<IconButton
					onClick={(e: any) => onNavigate(e, p.onClick)}
					disabled={p.disabled}
					className="btn-link"
				>
					<IconKeyboardRight />
				</IconButton>
			)}
			onPageChange={onPageChange}
			noDataText={!loading && noDataText}
			page={page}
		/>
	);
};

export default DataTable;