import React from 'react';
import { Page, PageHero } from 'components/core';
import TitleBar from 'screens/Components/TitleBar';

function PTDProductsOverview() {
	const pageTitle = 'Products Overview';

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<PageHero title="Coming soon!" />
			<div className="container-fluid">
				<h2>We're working hard to bring something amazing!</h2>
				<p className="mt-4">Our team is putting the finishing touches on a brand new experience, and we can't wait to share it with you.</p>
			</div>
		</Page>
	);
}

export default PTDProductsOverview;
