import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import {
	DynamicTableCell,
	DynamicTableData,
	TableProps,
} from '../../types/interfaces';
import TableFilterModal from '../modals/TableFilterModal';
import { useState } from 'react';
import H6 from '../headings/h6';
import { IconSliders } from 'assets/icons';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends string | number>(
	order: Order,
	orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DynamicTableData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly DynamicTableCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort, headCells } = props;
	const createSortHandler =
    (property: keyof DynamicTableData) =>
    	(event: React.MouseEvent<unknown>) => {
    		onRequestSort(event, property);
    	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', paddingLeft: '1rem' }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							<span className="font-semibold">{headCell.label}</span>
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
  setOpenFilterModal: (open: boolean) => void;
  title?: string;
}

export function EnhancedTableToolbar({
	setOpenFilterModal,
	title,
}: EnhancedTableToolbarProps) {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 2 },
				justifyContent: 'space-between',
			}}
		>
			<H6>{title}</H6>
			<Tooltip title="Filter list">
				<button
					className="border-0 bg-transparent"
					type="button"
					onClick={() => {
						setOpenFilterModal(true);
					}}
				>
					<IconSliders aria-hidden="true" className="h-6 w-6" />
				</button>
			</Tooltip>
		</Toolbar>
	);
}
export default function EnhancedTable({
	rows,
	columns,
	title,
	defaultRowsPerPage,
}: TableProps) {
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof DynamicTableData>(
		columns[0].id
	);
	const [selected, setSelected] = React.useState<readonly number[]>([]);
	const [page, setPage] = React.useState(0);
	//const [dense, setDense] = React.useState(defaultDense ?? false);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		defaultRowsPerPage || 10
	);
	const [openFilterModal, setOpenFilterModal] = useState(false);

	const handleRequestSort = (
		_: React.MouseEvent<unknown>,
		property: keyof DynamicTableData
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (_: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
	//   setDense(event.target.checked);
	// };

	const handleModalClose = (e: boolean) => {
		setOpenFilterModal(e);
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			stableSort(rows, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rows, rowsPerPage]
	);

	return (
		<React.Fragment>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						setOpenFilterModal={setOpenFilterModal}
						title={title}
					/>
					<TableContainer>
						<Table
							sx={{ width: '100%', tableLayout: 'auto', mx: 1 }}
							aria-labelledby="tableTitle"
							size="small"
							//size={dense ? "small" : "medium"}
						>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy as string}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
								headCells={columns}
							/>
							<TableBody>
								{visibleRows.map((row, key) => {
									const isItemSelected = isSelected(row.id as number);

									return (
										<TableRow
											hover={true}
											onClick={(event) => handleClick(event, row.id as number)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id + '' + key}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{Object.keys(row)
												.filter((key) => key !== 'id')
												.map((key, indexColumn) => (
													<TableCell
														sx={{
															maxWidth: '150px', // Set the max width of the cell
															whiteSpace: 'nowrap', // Prevent wrapping
															overflow: 'hidden', // Hide overflowing text
															textOverflow: 'ellipsis', // Truncate text with ellipsis
														}}
														align={
															columns.find((column) => column.id === key)
																?.numeric
																? 'right'
																: 'left'
														}
														key={indexColumn}
													>
														{row[key as keyof object]}
													</TableCell>
												))}
										</TableRow>
									);
								})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											//height: (dense ? 33 : 53) * emptyRows,
											height: 33 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						size="small"
					/>
				</Box>
				{/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
			</Box>
			<TableFilterModal
				openModal={openFilterModal}
				onCloseModal={(e) => handleModalClose(e)}
			/>
		</React.Fragment>
	);
}
