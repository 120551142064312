import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconDelete } from 'assets/icons/icon-delete.svg';
import { DataTable, Button, IconButton } from 'components/core';
import { IconChevronDown, IconChevronUp } from 'assets/icons';

interface ProductTypeTableProps {
	data: any;
	columns: any[];
	showDeleteProductTypeModal: () => void;
	disabled: boolean;
	onClickAddProduct: () => void;
	productTypeName?: string;
	emptyTableTitle?: string;
}

const ProductTable: React.FC<ProductTypeTableProps> = ({
	data,
	columns,
	showDeleteProductTypeModal,
	disabled,
	onClickAddProduct,
	productTypeName,
	emptyTableTitle,
}) => {
	const [openProdTable, setOpenProdTable] = useState(true);

	function toggleOpenProdTable() {
		setOpenProdTable(open => !open);
	}

	return (
		<div className={classNames('mb-4')}>
			<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
				<h4 className="text-dark mb-0">{productTypeName}</h4>
				<Button
					onClick={onClickAddProduct}
					disabled={disabled}
					className="ms-auto text-primary py-0 fs-14 fw-normal"
				>
					<IconAdd className="me-1 svg-20" />Add Product
				</Button>
				<Button
					onClick={showDeleteProductTypeModal}
					disabled={disabled}
					className=" ms-2 text-primary py-0 fs-14 fw-normal"
				>
					<IconDelete className="me-1 svg-20" />Delete Product Category
				</Button>
				<IconButton onClick={toggleOpenProdTable} className="text-primary bg-light">
					{openProdTable ? <IconChevronUp /> : <IconChevronDown />}
				</IconButton>
			</div>

			{openProdTable && <DataTable
				columns={columns}
				data={data}
				sortable={true}
				noDataText={emptyTableTitle}
				showPagination={true}
				resizable={true}
			/>}
		</div>
	);
};

export default ProductTable;