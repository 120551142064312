'use client';

import { useEffect, useState } from 'react';
import {
	CustomFormData,
	FieldConfig,
	TableFilterModalProps,
} from '../../types/interfaces';
import CustomForm from '../forms/CustomForm';
import React from 'react';
import { Dialog } from '@mui/material';

const formFields: FieldConfig[] = [
	{
		name: 'search',
		label: 'Search',
		rules: { required: 'Enter some keywork in the field.' },
		fieldType: {
			type: 'input',
			attributes: {
				type: 'text',
			},
		},
	},
];

export function TableFilterModal({
	openModal,
	onCloseModal,
}: TableFilterModalProps) {
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleFormSubmit = (data: CustomFormData) => {
		console.log('Form Data:', data);
		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);
		}, 1500);
	};

	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	return (
		<Dialog
			open={open}
			onClose={(e) => {
				setOpen(false);
				onCloseModal(false);
			}}
			className="relative z-50"
		>
			{/* Backdrop */}
			<div className="fixed inset-0 bg-black/30" aria-hidden="true"></div>

			{/* Center the panel */}
			<div className="fixed inset-0 flex items-center justify-center">
				<div className="bg-white p-4 rounded shadow-lg">
					{/* Heading */}
					<h2
						className="text-lg text-base font-semibold leading-6 text-gray-900"
						id="dialog-title"
					>
					Filter list
					</h2>
					<p>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
						You can filter by multiple columns. Please select by which columns
						you want to filter.
							</p>
						</div>
						<CustomForm
							onSubmit={handleFormSubmit}
							fields={formFields}
							isLoadingForm={isLoading}
						/>
					</p>
					<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
						<button
							type="button"
							data-autofocus={true}
							onClick={() => {
								setOpen(false);
								onCloseModal(false);
							}}
							className="mt-3 inline-flex w-full justify-center rounded-md bg-white
	                        px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
	                        hover:bg-gray-50 sm:mt-0 sm:w-auto"
						>
						Cancel
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default TableFilterModal;
