import React, { createContext, useReducer, ReactNode } from 'react';
import reducer, { initialState } from './reducer';
import { State, ActionTypes } from './types';

interface StoreProps {
  children: ReactNode;
}

interface StoreContextProps {
  state: State;
  dispatch: React.Dispatch<ActionTypes>;
}

export const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export const StoreProvider: React.FC<StoreProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<StoreContext.Provider value={{ state, dispatch }}>
			{children}
		</StoreContext.Provider>
	);
};
