import React from 'react';
import { FormGroup } from 'components/core';
import { OrganizationSelect } from 'components/scopematch/OrganizationSelect';
import { usePTD } from 'context/PTD/PTDContext';
import { isString } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';
import classNames from 'classnames';

interface TableColumnVendorNameProps {
	vendorFormMethods: UseFormReturn<any, object>;
	row: any;
	id: string | undefined;
	editTable: VendorTable | ProductTable | null;
}

function TableColumnVendorName({
	vendorFormMethods,
	row,
	id,
	editTable,
}: TableColumnVendorNameProps) {
	// Get PTD
	const {
		getCompanyProfile,
		getOrganizationList,
		addOrganization,
		getSinglePTD,
	} = usePTD();
	const PTD: PTD | undefined = getSinglePTD(id);
	const companyProfile = getCompanyProfile();
	const organizationList = getOrganizationList();

	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return (
				<Controller
					key={'vendorId'}
					control={vendorFormMethods.control}
					defaultValue={row.value ?? ''}
					rules={{
						required: 'This is a required field.',
					}}
					name="specs.vendorId"
					render={({ field, fieldState }) => {
						return (
							<FormGroup
								field={field}
								fieldState={fieldState}
								className="mb-0 pb-0"
							>
								<OrganizationSelect
									id="vendorId"
									organizationType={companyProfile?.organizationType}
									vendorOrganizationType={PTD?.vendorType.toLowerCase() as any}
									showAddInstitution={true}
									name={field.name}
									value={
										isString(field.value)
											? organizationList?.find(
												(org) => org.organizationName === field.value
											  )?.id || ''
											: field.value || ''
									}
									setValue={vendorFormMethods.setValue}
									onChange={field.onChange}
									onAddInstitution={(result: OrganizationDTO) =>
										result && addOrganization(result)
									}
									onMenuOpen={vendorFormMethods.clearErrors}
									lightButtonText={'Add Vendor'}
									className={classNames(
										'p-1 fs-14 table-select',
										!!fieldState.error?.message
											? 'custom-input-error'
											: 'custom-input'
									)}
									error={!!fieldState.error?.message}
								/>
							</FormGroup>
						);
					}}
				/>
			);
		}
	}

	if (row.value === undefined || row.value.length === 0) {
		return (
			<Text>
				<i className="text-muted">Can`t find name</i>
			</Text>
		);
	}
	return <Text>{row.value}</Text>;
}

export default TableColumnVendorName;
