import classNames from 'classnames';
import { HeadingProps } from '../../types/interfaces';
import React, { PropsWithChildren } from 'react';


function H2({ children, className, attributes }: PropsWithChildren<HeadingProps>) {
	return (
		<h2 className={classNames(className, 'text-2xl font-bold dark:text-white')} {...attributes}>
			{children}
		</h2>
	);
}

export default H2;
