import React, { useContext, useEffect, useMemo, useState } from 'react';
import { HeaderProps } from 'vna/types/interfaces';
import './Header.scss'; // Import the CSS file
import { StoreContext } from 'vna/store/context';
import H5 from 'vna/components/headings/h5';
import { IconCompanyProfile } from 'assets/icons';
import AuthContext from 'context/Auth/AuthContext';
import { LoadingPage } from 'components/core';

function Header({ setShowMobileSidebar }: HeaderProps) {
	const store = useContext(StoreContext);
	const [headerTitle, setHeaderTitle] = useState<string | null>(null);
	const [organizationName, setOrganizationName] = useState<
		string | undefined
	>();

	const { companyProfile, isInitializing } = React.useContext(AuthContext);

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { state } = store;

	const ptd = useMemo(() => ({ ...state.ptd }), [state.ptd]);
	const fileData = useMemo(() => ({ ...state.fileData }), [state.fileData]);

	useEffect(() => {
		setHeaderTitle(ptd.name || fileData.name || null);
	}, [fileData.name, ptd.name]);

	useEffect(() => {
		setOrganizationName(companyProfile?.organizationName);
	}, [companyProfile?.organizationName]);

	if (isInitializing) {
		return <LoadingPage />;
	}

	return (
		<div className="navbar position-fixed shadow bg-white titlebar px-1 py-3 header-vna">
			<div className="container-fluid d-flex justify-content-between align-items-center">
				<div>
					<button
						className="navbar-toggler m-1 d-lg-none"
						type="button"
						aria-controls="navbarToggleExternalContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={() => setShowMobileSidebar(true)}
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<a className="navbar-brand">
						{headerTitle || (
							<span className="fw-normal fst-italic text-muted">
								AML not choosen
							</span>
						)}
					</a>
				</div>
				<H5 className="mb-0 fw-normal">
					<IconCompanyProfile className="me-1 mb-1" />
					{organizationName || ''}
				</H5>
			</div>
		</div>
	);
}

export default Header;
