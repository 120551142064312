import React, { useCallback } from 'react';
import TitleBar from 'screens/Components/TitleBar';
import AddProductModal from 'screens/PremiumTradeDirectory/components/modals/AddProductModal';
import DeletePTDRowModal from 'screens/PremiumTradeDirectory/components/modals/DeletePTDRowModal';
import PTDAPIs from 'api/PTDAPI';
import AuthContext from 'context/Auth/AuthContext';
import { IconAdd } from 'assets/icons';
import { Button, Page, LoadingPage } from 'components/core';
import { usePTD } from 'context/PTD/PTDContext';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PremiumTradeDirectoryListByProduct from 'screens/PremiumTradeDirectory/listsByView/PremiumTradeDirectoryListByProduct';
import PremiumTradeDirectoryListFlat from 'screens/PremiumTradeDirectory/listsByView/PremiumTradeDirectoryListFlat';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';
import scopeMatchAPI from 'api/ScopeMatchAPI';

const ERROR_MESSAGES = {
	CATALOG_LOAD_ERROR: 'Could not load the product catalog!',
};

function PremiumTradeDirectoryListDetails() {
	const params = useParams();
	const id = params.id;
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;
	const isMounted = React.useRef(true);

	// Get PTD
	const {
		getSinglePTDList,
		fetchProductsForPTDList,
		addProduct,
		updateProduct,
		deleteProduct,
		getPTDAccess,
		isInitializingPTDProducts,
	} = usePTD();

	const {
		fetchVendorsForPTDList,
		deletePTDVendor,
	} = usePTDVendors();

	const PTDList: PremiumTradeDirectory | undefined = getSinglePTDList(
		idPTDList,
		listName
	);
	const PTDAccess = getPTDAccess();

	// General Page Info
	const pageTitle = PTDList
		? PTDList.premiumTradeDirectoryName
		: 'Single Premium Trade Directory';
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const { currentUser } = React.useContext(AuthContext);
	const [layout, setLayout] = React.useState<'product' | 'vendor' | 'flat'>(
		'product'
	);
	const [productSpecsList, setProductSpecsList] = React.useState<
		ProductTypeDTO[] | undefined
	>(undefined);

	// Access
	const accessForList = PTDAccess
		? PTDAccess.find(
			(acc) =>
				acc.email === currentUser.email &&
					acc.premiumTradeDirectoryId === idPTDList
		  )
		: null;
	const canEdit =
		(accessForList &&
			(accessForList.permission === 'Edit' ||
				accessForList.permission === 'Suggest')) ||
		true;

	// Modals
	const [showAddProductModal, setAddProductModal] = React.useState<{
		isOpen: boolean;
		product?: PTDProduct;
	}>({ isOpen: false });
	const [showDeleteModal, setDeleteModal] = React.useState<{
		isOpen: boolean;
		idPTDProduct: number | undefined;
		idPTDVendor: number | undefined;
		label: string;
		isDeleting: 'product' | 'vendor' | 'both' | undefined;
	}>({
		isOpen: false,
		idPTDProduct: undefined,
		idPTDVendor: undefined,
		label: '',
		isDeleting: undefined,
	});

	// Table
	const vendorFormMethods = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});

	// Memoize fetchData using useCallback
	const fetchProductsForPTDListCallback = useCallback(
		(signal) => {
			fetchProductsForPTDList(idPTDList, signal);
		},
		[idPTDList, fetchProductsForPTDList]
	);

	// Memoize fetchData using useCallback
	const fetchVendorsForPTDListCallback = useCallback(
		(signal) => {
			fetchVendorsForPTDList(idPTDList, signal);
		},
		[idPTDList, fetchVendorsForPTDList]
	);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchProductsForPTDListCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchProductsForPTDListCallback]);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchVendorsForPTDListCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchVendorsForPTDListCallback]);

	React.useEffect(() => {
		isMounted.current = true;

		const fetchSpecs = async () => {
			try {
				const productTypeListResult =
					await scopeMatchAPI.product.GetProductTypeList();
				if (productTypeListResult && isMounted.current) {
					setProductSpecsList(
						productTypeListResult.filter(
							(p) => p.productSpecCodes?.length !== 0
						)
					);
					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted.current) {
					toast.error(ERROR_MESSAGES.CATALOG_LOAD_ERROR);
				}
			}
		};
		fetchSpecs();

		return () => {
			isMounted.current = false;
		};
	}, []);

	/// Add/Edit Product Modal
	async function addUpdateProduct(
		fields: Partial<PTDProductRequest>,
		id?: number
	): Promise<boolean> {
		if (idPTDList !== undefined && fields.name !== undefined) {
			setIsLoading(true);
			const newProduct: PTDProductRequest = {
				...fields,
				id,
				name: fields.name,
				productsTypePickListItemId: fields.productsTypePickListItemId || 0,
				pickListItemIds: fields.pickListItemIds || [],
				speckListItemIds: fields.speckListItemIds || [],
				premiumTradeDirectoryId: idPTDList,
			};

			try {
				const response: PTDProduct | null =
					await PTDAPIs.premiumTradeDirectories.UpdateProduct(newProduct);

				if (response) {
					if (id) {
						updateProduct(response);
					} else {
						addProduct(response);
					}
					toast.success('Product was added in the list');
					onCloseAddProductModal(); // Ensure the modal is closed after submission
					return true;
				} else {
					onCloseAddProductModal(); // Ensure the modal is closed after submission
					return false;
				}
			} catch (error) {
				console.error(error);
				toast.error('An error occurred while adding the product');
				return false;
			} finally {
				setIsLoading(false);
			}
		}

		return false;
	}
	/// Add/Edit Product Modal End

	// Delete product modal start
	async function onDeleteProduct(idPTDProduct: number): Promise<boolean> {
		setIsLoading(true);
		try {
			const response: string | null =
				await PTDAPIs.premiumTradeDirectories.DeleteProduct({
					productId: idPTDProduct,
				});

			if (response) {
				deleteProduct(idPTDProduct);
				toast.success(response);
				onCloseDeleteModal();
				return true;
			} else {
				onCloseDeleteModal();
				return false;
			}
		} finally {
			setIsLoading(false);
		}
	}
	// Delete product modal end

	// Delete vendor modal start
	async function onDeletePTDVendor(
		productId: number,
		idPTDVendor: number
	): Promise<boolean> {
		setIsLoading(true);
		try {
			const response: string | null =
				await PTDAPIs.premiumTradeDirectories.DeleteVendor(idPTDVendor);

			if (response) {
				deletePTDVendor(productId, idPTDVendor);
				toast.success(response);
				onCloseDeleteModal(); // Ensure the modal is closed after submission
				return true;
			} else {
				onCloseDeleteModal(); // Ensure the modal is closed after submission
				return false;
			}
		} finally {
			setIsLoading(false);
		}
	}
	// Delete vendor modal end

	// Delete modal start
	async function onDelete(
		productId: number | undefined,
		idPTDVendor: number | undefined,
		isDeleting: 'product' | 'vendor' | 'both' | undefined
	): Promise<boolean> {
		if (isDeleting === 'vendor') {
			if (productId !== undefined && idPTDVendor !== undefined) {
				return await onDeletePTDVendor(productId, idPTDVendor);
			} else {
				return false;
			}
		} else if (isDeleting === 'product') {
			if (productId) {
				return await onDeleteProduct(productId);
			} else {
				return false;
			}
		} else if (isDeleting === 'both') {
			let vendorRes = false,
				productRes = false;
			if (productId !== undefined && idPTDVendor !== undefined) {
				vendorRes = await onDeletePTDVendor(productId, idPTDVendor);
			}
			if (productId) {
				productRes = await onDeleteProduct(productId);
			}
			return vendorRes && productRes;
		}
		return false;
	}
	// Delete modal end

	const handleLayoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLayout(e.target.value as 'product' | 'vendor' | 'flat');
	};

	const onCloseDeleteModal = () => {
		setDeleteModal({
			isOpen: false,
			idPTDProduct: undefined,
			idPTDVendor: undefined,
			label: '',
			isDeleting: undefined,
		});
	};

	const onOpenAddProductModal = () => {
		setAddProductModal({ isOpen: true });
	};

	const onCloseAddProductModal = () => {
		setAddProductModal({ isOpen: false });
	};

	if (isLoading === true || isInitializingPTDProducts) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...vendorFormMethods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar pageTitle={pageTitle} />
					</div>
				</div>
				<div className="container-fluid mb-2 px-5 py-3">
					<div className="row">
						<div className="col-9">
							<div
								className="btn-group"
								role="group"
								aria-label="Basic radio toggle button group"
							>
								<input
									type="radio"
									className="btn-check"
									name="btnradio"
									id="btnradio1"
									autoComplete="off"
									value="product"
									checked={layout === 'product'}
									onChange={handleLayoutChange}
								/>
								<label className="btn btn-outline-primary" htmlFor="btnradio1">
									Product View
								</label>

								{/* <input
									type="radio"
									className="btn-check"
									name="btnradio"
									id="btnradio2"
									autoComplete="off"
									value="vendor"
									checked={layout === 'vendor'}
									onChange={handleLayoutChange}
								/>
								<label className="btn btn-outline-primary" htmlFor="btnradio2">
									Vendor View
								</label> */}

								<input
									type="radio"
									className="btn-check"
									name="btnradio"
									id="btnradio3"
									autoComplete="off"
									value="flat"
									checked={layout === 'flat'}
									onChange={handleLayoutChange}
								/>
								<label className="btn btn-outline-primary" htmlFor="btnradio3">
									Flat View
								</label>
							</div>
						</div>
						<div className="col-3">
							{canEdit ? (
								<Button
									onClick={onOpenAddProductModal}
									loading={false}
									className="btn-primary ms-auto w-100"
								>
									<IconAdd /> Add Product
								</Button>
							) : null}
						</div>
					</div>
					{/* list by product */}
					{layout === 'product' ? (
						<PremiumTradeDirectoryListByProduct
							id={id}
							vendorFormMethods={vendorFormMethods}
							idPTDList={idPTDList}
							canEdit={canEdit}
							setAddProductModal={setAddProductModal}
							setDeleteModal={setDeleteModal}
							productSpecsList={productSpecsList}
						/>
					) : layout === 'flat' ? (
						<PremiumTradeDirectoryListFlat
							id={id}
							vendorFormMethods={vendorFormMethods}
							idPTDList={idPTDList}
							addUpdateProduct={addUpdateProduct}
							setAddProductModal={setAddProductModal}
							setDeleteModal={setDeleteModal}
							productSpecsList={productSpecsList}
						/>
					) : (
						''
					)}
				</div>

				<AddProductModal
					isOpen={showAddProductModal.isOpen}
					product={showAddProductModal.product}
					onRequestClose={onCloseAddProductModal}
					addUpdateProduct={addUpdateProduct}
					productSpecsList={productSpecsList}
				/>

				<DeletePTDRowModal
					isOpen={showDeleteModal.isOpen}
					isLoading={isLoading}
					idPTDProduct={showDeleteModal.idPTDProduct}
					idPTDVendor={showDeleteModal.idPTDVendor}
					isDeleting={showDeleteModal.isDeleting}
					onRequestClose={onCloseDeleteModal}
					deletePTDVendor={onDelete}
					label={showDeleteModal.label}
				/>
			</Page>
		</FormProvider>
	);
}

export default PremiumTradeDirectoryListDetails;
