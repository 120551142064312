import React, { PropsWithChildren } from 'react';
import { AccordionContentProps } from '../../types/interfaces';

function AccordionContent({ children, accordionIndex }: PropsWithChildren<AccordionContentProps>) {
	return (
		<div id={`collapse-${accordionIndex}`} className="accordion-collapse collapse" aria-labelledby={`heading-${accordionIndex}`} data-bs-parent="#accordion">
			<div className="accordion-body">
				{ children }
			</div>
		</div>
	);
}

export default AccordionContent;
