/* eslint-disable max-len */
import React from 'react';
import { Button, Page } from 'components/core';
import AuthContext from 'context/Auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import OnboardingContainer from './components/OnboardingContainer';
import ScopeMatchLogo from './components/Logo';
import classNames from 'classnames';
const WelcomePage = () => {
	const authCxt = React.useContext(AuthContext);
	const navigate = useNavigate();
	const onboardingComplete = authCxt.currentUser.onboardingComplete;

	return (
		<Page
			title="Welcome to ScopeMatch"
			className={classNames(
				'onboarding-wrapper',
				onboardingComplete === true && 'invite-overflow'
			)}
		>
			{onboardingComplete === true && <ScopeMatchLogo />}
			<OnboardingContainer>
				<div className="py-3">
					<h2 className="">Welcome to ScopeMatch</h2>
					<p>
						Welcome to ScopeMatch! You are now set to create opportunities,
						identify and mitigate risks through a expanding network of strategic
						connections aligned with you the direction of your business.
					</p>
					<p>
						Click on the links below to complete your profile and start
						searching for strategic collaborations.
					</p>
					<p>
						Friendly Reminder : The more complete and accurate your profile is
						set up - the better the chances for finding meaning connections that
						align with your business and needs to improve your strategic
						sourcing.
					</p>
				</div>
				<hr className="mt-4 mb-6" />
				<h4 className="mt-4 mb-3">Next Steps</h4>
				<div className="d-flex">
					<div className="card rounded shadow me-3 onboarding-sucess-card p-3">
						<div className="card-body d-flex flex-column">
							<h5 className="card-title">Review your company information</h5>
							<p className="card-text">
								Review the information in your company profile, and make sure it
								is correct
							</p>
							<Button
								onClick={handleRefreshUserCompanyProfile}
								className="btn-primary me-auto mt-auto"
							>
								Company Profile
							</Button>
						</div>
					</div>
					<div className="card rounded shadow onboarding-sucess-card p-3">
						<div className="card-body d-flex flex-column">
							<h5 className="card-title">Create a Request</h5>
							<p className="card-text">
								Create a request and search for vendors based on your criteria
							</p>
							<Button
								onClick={handleRefreshUserRequest}
								className="btn-primary me-auto mt-auto"
							>
								Create a Request
							</Button>
						</div>
					</div>
				</div>
			</OnboardingContainer>
		</Page>
	);

	async function handleRefreshUserCompanyProfile() {
		await authCxt.refreshUser();
		navigate('/company-profile');
	}

	async function handleRefreshUserRequest() {
		await authCxt.refreshUser();
		navigate('/requests');
	}
};

export default WelcomePage;
