import React from 'react';
import { ReactComponent as IconCompanyType } from 'assets/icons/icon-company-type.svg';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconWebsite } from 'assets/icons/icon-website.svg';

interface OrgInfoCardProps {
	orgDetails: OrganizationDTO,
	orgHeadOffice: string;
}

function OrgInfoCard({orgDetails, orgHeadOffice}: OrgInfoCardProps) {
	return (
		<div className="mb-4">
			<h3 className="">{orgDetails?.organizationName}</h3>
			<div className="d-flex flex-wrap">
				<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
					<IconCompanyType className="me-2" />{orgDetails?.organizationType}</p>
				<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 text-capitalize align-items-center me-2 rounded">
					<IconLocation className="me-2" />{orgHeadOffice}</p>
				{orgDetails?.companyUrl &&
					<p className="d-flex bg-light py-2 px-3 mb-0 mt-2 align-items-center rounded">
						<IconWebsite className="me-2" />
						<a
							href={orgDetails.companyUrl.includes('https://') || orgDetails.companyUrl.includes('http://')
								? orgDetails.companyUrl
								: `https://${orgDetails.companyUrl}`}
							target="_blank"
							rel="noreferrer"
						>
							{orgDetails.companyUrl.replaceAll('https://', '').replaceAll('http://', '')}
						</a>
					</p>
				}
			</div>
		</div>
	);
}

export default OrgInfoCard;
