import { LoadingPage, Page } from 'components/core';
import { usePTD } from 'context/PTD/PTDContext';
import React from 'react';
import { useParams } from 'react-router-dom';
import TitleBar from 'screens/Components/TitleBar';
import ListView from 'screens/PremiumTradeDirectory/components/views/ListView';

const PremiumTradeDirectoryDetails = () => {
	const params = useParams();
	const id = params.id;

	const {
		getSinglePTD,
		getCompanyProfile,
		getPTDMyLists,
		getPTDComanagedLists,
	} = usePTD();
	const PTD: PTD | undefined = id ? getSinglePTD(id) : undefined;
	const companyProfile: OrganizationDTO | null = getCompanyProfile();
	const PTDMyLists: PremiumTradeDirectory[] = getPTDMyLists();
	const PTDComanagedLists: PremiumTradeDirectory[] = getPTDComanagedLists();

	const pageTitle = PTD ? `${PTD.name}` : 'Single Trade Directory';
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	if (isLoading === true) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} />
				</div>
			</div>
			<div className="container-fluid mb-2 px-5 py-3">
				<div className="row">
					<div className="col-12">
						<ListView
							listName={`${companyProfile?.organizationName} Lists`}
							listKey={'myList'}
							list={PTDMyLists.filter(
								(l) => l.containsVendorType === PTD?.vendorType
							)}
							idPTD={id}
							vendorType={PTD?.vendorType as any}
							onIsLoading={(isLoading) => setIsLoading(isLoading)}
						/>
					</div>
				</div>

				<div className="row my-5">
					<div className="col-12">
						<ListView
							listName="Comanaged Lists"
							listKey={'comanagedList'}
							list={PTDComanagedLists.filter(
								(l) => l.containsVendorType === PTD?.vendorType
							)}
							idPTD={id}
							vendorType={PTD?.vendorType as any}
							onIsLoading={(isLoading) => setIsLoading(isLoading)}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default PremiumTradeDirectoryDetails;
