import React from 'react';
import { LoadingPage, Page } from 'components/core';
import { FormProvider, useForm } from 'react-hook-form';
import TitleBar from 'screens/Components/TitleBar';
import { Text, Buttons } from 'screens/ProductCatalog/components/columns';
import { toast } from 'react-toastify';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ColumnInput } from 'screens/ProductCatalog/components/columns/ColumnInput';
import SpecificationTable from 'components/scopematch/SpecificationTable';
import { useNavigate, useParams } from 'react-router-dom';
import ProductTypeAddModal from 'screens/ProductCatalog/components/Forms/ProductTypeAddModal';
import classNames from 'classnames';

const PickListProductTypes = () => {

	const productFormMethods = useForm<any>({ reValidateMode: 'onChange' });
	const [editTable, setEditTable] = React.useState<{ id: number, productType: string, rowId: number, newLine?: boolean, specCodeId?: number, duplicateRow?: number } | null>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [productTypeTitle, setProductTypeTitle] = React.useState<string | undefined>('');
	const [showProductTypeModal, setShowProductTypeModal] = React.useState<boolean>(false);
	const [productSpecsList, setProductSpecsList] = React.useState<SpecificationGroupDTO>({
		id: 0,
		productTypeCode: '',
		specificationList: []
	});
	const methods = useForm<any>({ reValidateMode: 'onChange' });
	const params = useParams();
	const productTypeId = Number(params.productTypeID);
	const navigate = useNavigate();
	const newRow: { [key: string]: string | JSX.Element } = {
		id: 'add-id',
		productTypeName: 'add-productTypeName',
	};

	React.useEffect(() => {
		async function fetchSpecs() {
			try {
				const productTypeListResult = await scopeMatchAPI.pickListItems.GetProductSpecificationList(productTypeId) as SpecificationGroupDTO;
				if (productTypeListResult !== null) {
					setProductSpecsList(productTypeListResult);
					setIsLoading(false);

				}
			} catch (error) {
				toast.error('Could not load the product specifications');
			}
		}
		fetchSpecs();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pageTitle = `${productSpecsList.productTypeCode} Specifications`;

	function handleNavigate() {
		navigate('/admin/picklistproducttypes');
	}
	if (isLoading === true) {
		return <LoadingPage />;
	}
	return (
		<FormProvider {...methods}>
			<Page title={pageTitle}>
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
						backButton={true}
						onClick={handleNavigate}
					/>
				</div>
				<div className="container-fluid mb-5 px-5">
					<div className="row">

						<div className="col-12">
							<h3 className="mt-5 mb-4"></h3>
							<div className="mt-4">
								{productSpecsList.specificationList && productSpecsList.specificationList.length > 0 ? (

									productSpecsList.specificationList?.map((item, index) => {
										const columns = [
											{
												accessor: 'id',
												Header: 'id',
												Cell: (row: any) => {
													return row.value;
												},
												show: false,
											},
											{
												Header: 'Spec Value',
												accessor: 'code',
												Cell: (row: any) => {
													if (editTable?.productType === item.code && editTable?.rowId === row.index) {
														return <ColumnInput code={{ productSpecCode: row.value }} value={row.value || ''} className="mb-0" />;
													}
													return <Text>{row.value}</Text>;
												},
												show: true
											},
											{
												Header: '',
												accessor: 'delete',
												Cell: (row: any) => {
													const isCurrentTable = !!editTable && editTable.productType === item.code;
													const isEditingCurrentRow = isCurrentTable && !!editTable && editTable.rowId === row.index || isCurrentTable && !!editTable &&
														editTable?.productType === item.code &&
														!!editTable?.duplicateRow &&
														row.index === 0;
													const rowIsDisabled = !!editTable && ((isCurrentTable && editTable.rowId !== row.index) || !isCurrentTable);
													return (
														<Buttons
															isEditing={isEditingCurrentRow}
															disabled={rowIsDisabled}
															onClickSave={methods.handleSubmit(submitProductForm)}
															onClickCancel={closeProductAddRow}
															onClickEdit={() => onClickEditOrDuplicate(row, item.code ?? '', 'edit')}
															isSaving={isSaving}
														/>
													);
												},
												show: true
											}
										];

										const specs = item?.specificationValueList?.filter(Boolean) ?? [];
										const data = specs?.map((v) => {
											if (v.code) {
												const lineItem: any = {};
												lineItem['id'] = v.id;
												lineItem['code'] = v.code;
												return lineItem;
											}
											return undefined;
										}).filter(Boolean);
										data?.reverse();
										const tableData = data || [];
										if (editTable?.productType === item.code && editTable?.newLine === true) {
											tableData?.unshift(newRow);
										}

										return (
											<SpecificationTable
												key={`table-${index}`}
												productTypeName={item.description}
												columns={columns}
												data={tableData}
												disabled={!!editTable}
												emptyTableTitle={'Please add values you would like associated with this Specification.'}
												addSpecTitle={'Add Spec value'}
												onClickAddSpecCategory={() => {
													productFormMethods.reset({}, { keepValues: false, keepDirty: false });
													setProductTypeTitle(item.code);
													setEditTable({
														id: 0,
														productType: item.code ?? '',
														rowId: 0,
														newLine: true,
													});
												}}
											/>
										);
									})

								) : (
									<p>Specification Categories must first be added on the previous page.</p>
								)}
							</div>
						</div>

					</div>
				</div>

				<ProductTypeAddModal
					isOpen={showProductTypeModal}
					onRequestClose={closeProductTypeModal}
					addProductType={addProductType}
				/>
			</Page>
		</FormProvider>
	);

	function onClickEditOrDuplicate(row: any, productTypeName: string, type: 'edit' | 'duplicate') {
		const keys = Object.keys(row.original);
		const nonProductVals = ['id', 'name', 'productTypeName', 'delete'];
		nonProductVals.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			productFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			setProductTypeTitle(productTypeName);
			setEditTable({
				id: row.original.id,
				productType: productTypeName,
				rowId: row.index,
				specCodeId: row.original.id
			});
		}
	}

	function closeProductAddRow() {
		setEditTable(null);
	}


	function closeProductTypeModal() {
		setShowProductTypeModal(false);
	}
	function addProductType(productType: string) {
		const newProductType: ProductTypeDTO = {
			productTypeCode: productType,
			productTypeDescription: productType,
			productSpecCodes: []
		};

		setProductSpecsList(prevState => ({
			...prevState, // Spread the existing properties of the state object
			specificationList: [newProductType, ...(prevState?.specificationList || [])]
		}));

		if (productType) {
			setProductTypeTitle(productType);
		}
		const newProduct: SpecificationUpsertDTO = {
			pickListTypeCode: productType,
		};
		scopeMatchAPI.pickListItems.AddProductTable(newProduct);
	}


	async function submitProductForm(fields: { specs: { [x: string]: string } }) {
		setIsSaving(true);
		const keys = Object.keys(fields.specs);
		const isAny = keys.map(v => fields.specs[v].toLowerCase() === 'any');

		if (!isAny.includes(false)) {
			toast.error('A specific Spec Category is required');
			return;
		}

		if (productTypeTitle === undefined) {
			toast.error('Product category title is missing');
			return;
		}
		const result = await addSpecificationToTable(editTable?.id, productTypeTitle, fields);

		if (result === 409) {
			toast.error('This Specification already exists');
			setIsSaving(false);
			return;
		}
		if (result === true) {
			setIsSaving(false);
			setEditTable(null);
			methods.reset({}, { keepDirty: false });
			return;
		}
		if (result === false) {
			toast.error('Something went wrong!');
		}
	}


	async function addSpecificationToTable(id: number | undefined, specTypeCode: string, fields: { specs: { [x: string]: string } }): Promise<boolean | number> {
		const prod: SpecificationDTO = productSpecsList.specificationList?.find(v => v.code === specTypeCode) as SpecificationDTO;
		for (const key in fields.specs) {
			const specCode = fields.specs[key];

			if (prod) {
				const spec: SpecificationUpsertDTO = {
					id: id,
					pickListTypeCode: specTypeCode,
					previousCode: key,
					newCode: specCode,
				};
				const results = await scopeMatchAPI.pickListItems.AddValueToSpecification(spec) as SpecificationUpsertDTO;

				const updatedProductSpecCodes = prod.specificationValueList?.map((productSpec) =>
					productSpec.code === key
						? { ...productSpec, ...{ code: specCode } }
						: productSpec
				);

				if (key === 'undefined') {
					updatedProductSpecCodes?.push({ id: results.id, code: specCode, description: specCode });
				}
				const updatedProductTypes = productSpecsList.specificationList?.map((productType) => {
					if (productType.code === specTypeCode) {
						return { ...productType, ...{ specificationValueList: updatedProductSpecCodes } };
					}
					return productType;
				});
				if (updatedProductTypes) {
					setProductSpecsList(prevState => ({
						...prevState,
						specificationList: updatedProductTypes
					}));
				}
			}

		}

		return true;
	}

};

export default PickListProductTypes;