import ScopeMatchAPI from 'api/ScopeMatchAPI';
import { useToast } from 'components/customHooks/useToast';
import PickListContext from 'context/PickList/PickListContext';
import React from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';

interface IRequestContext {
	requestsList: SearchRequestSummaryDTO[];
	postRequest: (
		fields: RequestType,
		matchRequestProducts: ProductTypeDTO[] | undefined,
		submit: boolean,
		requestId: number
	) => Promise<boolean>;
	isLoading: boolean;
	fetchUsersRequests: () => Promise<boolean>;
	request: MatchRequestDTO | null;
	requestPanel: MatchRequestDetailDTO | null;
	resultPanel: MatchRequestDetailDTO | null;
	searchResults: MatchResultDTO[];
	//orgData: null | OrganizationDTO;
	statData: undefined | MatchResultDTO;
	toggleSaveMatch: (requestId: number, orgId: number) => Promise<boolean>;
	isRequestDirty: boolean;
	setIsRequestDirty: React.Dispatch<React.SetStateAction<boolean>>;
}
type RequestType = {
	countryDropdown?: string;
	recievingLocationsCountries?: string;
} & MatchRequestDTO;

const RequestProvider = (props: React.PropsWithChildren<{}>) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [requestsList, setRequestsList] = React.useState<
		SearchRequestSummaryDTO[]
	>([]);
	const [request, setRequest] = React.useState<MatchRequestDTO | null>(null);
	const [requestPanel, setRequestPanel] =
		React.useState<MatchRequestDetailDTO | null>(null);
	const [resultPanel, setResultPanel] =
		React.useState<MatchRequestDetailDTO | null>(null);
	//const [orgData, setOrgData] = React.useState<OrganizationDTO | null>(null);
	const [statData, setStatData] = React.useState<MatchResultDTO>();
	const [searchResults, setSearchResults] = React.useState<MatchResultDTO[]>(
		[]
	);
	const [isRequestDirty, setIsRequestDirty] = React.useState<boolean>(false);

	const picklistCxt = React.useContext(PickListContext);
	const navigate = useNavigate();

	const toast = useToast();
	const location = useLocation();
	const matchReqId = matchPath(
		{ path: 'requests/searchresults/:requestID' },
		location.pathname
	);
	const matchOrgId = matchPath(
		{ path: 'requests/searchresults/:requestID/matchdetails/:orgID' },
		location.pathname
	);
	const requestId = matchReqId
		? Number(matchReqId.params.requestID)
		: matchOrgId
			? Number(matchOrgId.params.requestID)
			: undefined;
	const orgId = matchOrgId ? Number(matchOrgId.params.orgID) : undefined;

	React.useEffect(() => {
		if (location.pathname === '/requests') {
			fetchUsersRequests();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	React.useEffect(() => {
		if (requestId !== undefined && request?.id !== requestId) {
			fetchRequestData(requestId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestId]);

	React.useEffect(() => {
		if (orgId !== undefined && requestId !== undefined) {
			fetchResultData(requestId, orgId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, requestId]);

	const { children } = props;
	return (
		<RequestContext.Provider
			value={{
				requestsList,
				request,
				postRequest,
				requestPanel,
				resultPanel,
				fetchUsersRequests,
				isLoading,
				searchResults,
				statData,
				toggleSaveMatch,
				isRequestDirty,
				setIsRequestDirty,
			}}
		>
			{children}
		</RequestContext.Provider>
	);
	async function fetchRequestData(requestID: number) {
		setIsLoading(true);
		const searchParams = await ScopeMatchAPI.search.GetSearchParameters(
			requestID
		);
		if (orgId === undefined) {
			const searchParamPanel =
				await ScopeMatchAPI.search.GetSearchParameterPanel(requestID, orgId);
			if (searchParamPanel) {
				setRequestPanel(searchParamPanel);
			} else {
				navigate('/404');
			}
		}
		const searchResults = await ScopeMatchAPI.search.GetSearchResults(
			requestID
		);
		setIsLoading(false);
		if (searchResults !== null) {
			setSearchResults(searchResults);
		}
		if (searchParams !== null) {
			setRequest(searchParams);
		}
	}
	async function fetchResultData(requestID: number, orgID: number) {
		setIsLoading(true);
		const searchResultStat = await ScopeMatchAPI.search.GetMatchResultStats(
			requestID,
			orgID
		);
		const searchParamPanel = await ScopeMatchAPI.search.GetSearchParameterPanel(
			requestID,
			orgId
		);
		setIsLoading(false);
		if (searchResultStat !== null) {
			setStatData(searchResultStat);
		}
		if (searchParamPanel !== null) {
			setResultPanel(searchParamPanel);
		}
	}

	async function fetchUsersRequests(): Promise<boolean> {
		setIsLoading(true);
		const getRequests = await ScopeMatchAPI.search.GetUsersRequests();
		if (getRequests !== null) {
			setRequestsList(getRequests);
			setIsLoading(false);
			return true;
		}
		toast.error('Could not fetch your requests.');
		setIsLoading(false);
		setRequestsList([]);
		return false;
	}

	async function postRequest(
		fields: RequestType,
		matchRequestProducts: ProductTypeDTO[] | undefined,
		submit: boolean,
		requestId: number
	) {
		setIsLoading(true);
		fields.id = requestId;
		fields.matchRequestProducts = matchRequestProducts?.map((v) => {
			return { ...v, id: undefined };
		}); //unset id
		const countryPL = picklistCxt.pickListItems.filter(
			(v) => v.pickListType?.code === 'Country'
		);
		const countries: string[] = countryPL.map((v) => {
			return v.code;
		}) as string[];
		const { countryDropdown, recievingLocationsCountries, ...remainingFields } =
			fields;
		if (countryDropdown === 'except') {
			remainingFields.manufacturingLocation = countries.filter(
				(v) => !fields.manufacturingLocation?.includes(v)
			);
		}
		if (countryDropdown === 'all') {
			remainingFields.manufacturingLocation = countries;
		}
		if (recievingLocationsCountries === 'except') {
			remainingFields.receivingLocation = countries.filter(
				(v) => !fields.receivingLocation?.includes(v)
			);
		}
		if (recievingLocationsCountries === 'all') {
			remainingFields.receivingLocation = countries;
		}
		const result = await ScopeMatchAPI.search.PostRequest(remainingFields);
		if (result !== null) {
			if (submit === true && result.id) {
				setRequest(null);
				navigate(`/requests/searchresults/${result?.id}`);
			} else if (submit === false) {
				navigate(`/requests/${result?.id}`);
				toast.success('Match request was saved');
			}
			setIsLoading(false);
			return true;
		}
		setIsLoading(false);
		return false;
	}

	async function toggleSaveMatch(requestId: number, orgId: number) {
		const searchResultData = searchResults.find(
			(y) => y.organizationID === orgId
		);
		const searchResultDataIndex = searchResults.findIndex(
			(y) => y.organizationID === orgId
		);
		const approvedMatch = searchResultData?.isApprovedMatch ?? false;
		if (approvedMatch === true) {
			await ScopeMatchAPI.search.DeleteSavedMatch(requestId, orgId);
		} else {
			await ScopeMatchAPI.search.SaveMatch(requestId, orgId);
		}
		setSearchResults((v) => {
			const newArray = [...v];
			newArray[searchResultDataIndex].isApprovedMatch = !approvedMatch;
			return newArray;
		});
		await fetchResultData(requestId, orgId);
		return true;
	}
};
export default RequestProvider;
export const RequestContext = React.createContext<IRequestContext>(
	{} as IRequestContext
);
