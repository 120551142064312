import React from 'react';
import { ReactComponent as IconContactDetails } from 'assets/icons/icon-contact-details.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconEmail } from 'assets/icons/icon-email.svg';
import { ReactComponent as IconContact } from 'assets/icons/icon-contact.svg';

interface IProps {
	orgContact?: OrganizationContactDTO[];
}

const ContactHeader = () => (
	<div className="d-flex align-items-center">
		<div className="rounded-circle bg-secondary-100 p-2 me-3">
			<IconContactDetails />
		</div>
		<h5 className="mb-0">Contacts</h5>
	</div>
);

const ContactHeaderFields = () => (
	<div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 mb-2 mb-lg-0 p-1">
		<span><IconContact className="svg-20 me-2" />Name, Role</span>
		<span><IconEmail className="svg-20 me-2" />Email</span>
		<span><IconPhone className="svg-20 me-2" />Phone</span>
	</div>
);

const ContactDetails = ({ contact, index }: { contact: OrganizationContactDTO; index: number }) => (
	<div
		className={`row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 mb-4 mb-lg-2 p-1 ${
			index % 2 === 0 ? 'bg-white' : 'bg-light'
		}`}
	>
		<span>{contact.contactName}, {contact.contactRole}</span>
		<span>{contact.contactEmail}</span>
		<span>{contact.contactPhone}</span>
	</div>
);

const ContactCard: React.FC<IProps> = ({ orgContact = [] }) => {
	return (
		<div className="card shadow p-3 mb-2 rounded">
			<ContactHeader />
			<hr />
			<div className="container-fluid">
				<ContactHeaderFields />
				<hr className="mt-0" />
				{orgContact.map((contact, index) =>
					contact.contactName ? (
						<ContactDetails key={`contact-${contact.id}`} contact={contact} index={index} />
					) : null
				)}
			</div>
		</div>
	);
};

export default ContactCard;