import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import SquarePill from '../pills/SquarePill';
import PickListContext from 'context/PickList/PickListContext';

interface IProps {
	id: number | undefined;
	title: string;
	resultDetails: string[] | undefined;
	requestDetails: string[] | undefined;
	resultsPage: boolean | undefined;
}

const BusinessScopeValues: React.FC<PropsWithChildren<IProps>> = (
	props: IProps
) => {
	const picklistCxt = React.useContext(PickListContext);
	const picklistItems = picklistCxt.pickListItems;
	const titleWithoutSpaces = props.title.replace(/\s/g, '');
	const checkMatchValue = (value: any) => {
		return props.resultDetails?.includes(value);
	};

	const hasMatches =
		props.requestDetails && props.requestDetails.some(checkMatchValue);

	return (
		<div>
			<p
				className={classNames(
					'mt-2 pt-3 mb-1 fw-bold',
					!hasMatches && props.resultsPage === false && 'text-gray-600'
				)}
			>
				{props.title}
			</p>
			{props.requestDetails &&
				props.requestDetails.map((values) => {
					if (checkMatchValue(values)) {
						return (
							<SquarePill key={`${titleWithoutSpaces}-${props.id}`}>
								{picklistItems.find((x) => x.code === values)?.description}
							</SquarePill>
						);
					}
					return (
						<p
							key={`${titleWithoutSpaces}-${props.id}`}
							className={classNames(
								'mb-1',
								props.resultsPage === false && 'text-gray-600'
							)}
						>
							{values}
						</p>
					);
				})}
			<hr />
		</div>
	);
};

export default BusinessScopeValues;
