// import { forwardRef } from 'react';
// import { CustomSelectProps } from '../../types/interfaces';
// import React from 'react';

// const CustomSelect = forwardRef<HTMLSelectElement, CustomSelectProps>(
// 	({ value, onChange, label, error, attributes, options }, ref) => {
// 		return (
// 			<div>
// 				<label
// 					htmlFor={label}
// 					className="block text-sm font-medium leading-6 text-gray-900"
// 				>
// 					{label}
// 				</label>
// 				<div className="mt-2">
// 					<select
// 						ref={ref}
// 						value={value}
// 						onChange={onChange}
// 						id={label}
// 						name={label}
// 						{...attributes}
// 						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
//             rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
//             dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
//             dark:focus:ring-blue-500 dark:focus:border-blue-500"
// 					>
// 						{options.map((option) => (
// 							<option key={option.value} value={option.value}>
// 								{option.label}
// 							</option>
// 						))}
// 					</select>
// 				</div>

// 				{error && (
// 					<p id={label + '-error'} className="mt-2 text-sm text-red-600">
// 						{error}
// 					</p>
// 				)}
// 			</div>
// 		);
// 	}
// );

// export default CustomSelect;

import { forwardRef } from 'react';
import { CustomSelectProps } from '../../types/interfaces';
import React from 'react';

const CustomSelect = forwardRef<HTMLSelectElement, CustomSelectProps>(
	({ value, onChange, label, error, attributes, options, className }, ref) => {
		return (
			<div className="mb-3">
				<label
					htmlFor={label}
					className="form-label fs-6 text-dark"
				>
					{label}
				</label>
				<div className="mb-2">
					<select
						ref={ref}
						value={value}
						onChange={onChange}
						id={label}
						name={label}
						{...attributes}
						className={`form-select ${error ? 'is-invalid' : ''} ${className} cursor-pointer`}
					>
						{options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>

				{error && (
					<p id={label + '-error'} className="text-danger mt-2">
						{error}
					</p>
				)}
			</div>
		);
	}
);

export default CustomSelect;