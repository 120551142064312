import { LoadingPage, ModalOverlay } from 'components/core';
import { oppositeOrganizationName } from 'components/helperfunctions';
import React, { useCallback } from 'react';
import ContactCard from 'screens/Requests/components/ContactCard';
import LinkedOrganizationCard from 'screens/Requests/components/LinkedOrganizationCard';
import OrgCertificationsCard from 'screens/Requests/components/OrgCertificationsCard';
import OrgCompanyValuesCard from 'screens/Requests/components/OrgCompanyValuesCard';
import OrgFacilitiesCard from 'screens/Requests/components/OrgFacilitiesCard';
import OrgInsuranceRange from 'screens/Requests/components/OrgInsuranceRange';
import OrgLocationCard from 'screens/Requests/components/OrgLocationCard';
import OrgInfoCard from 'components/profile/OrgInfoCard';
import OrgProductCatalogCard from 'screens/Requests/components/OrgProductCatalogCard';
import OrgIndustriesCard from 'components/profile/OrgIndustriesCard';
import OrgSectorsCard from 'components/profile/OrgSectorsCard';
import { groupBy } from 'lodash';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';

interface IProps {
	isOpen: boolean;
	id: number | null;
	organizationList: OrganizationDTO[] | undefined;
	onRequestClose: () => void;
}

function ViewVendorProfile(props: IProps) {
	const { isOpen, id, organizationList, onRequestClose } = props;
	const [orgDetails, setOrgDetails] = React.useState<OrganizationDTO | null>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	// Get PTD List
	const { getPTDVendorsSpecs, fetchVendorsSpecs, isInitializingPTDVendorsSpecs } =
		usePTDVendors();

	const vendorSpec = getPTDVendorsSpecs();

	const orgHeadOffice = [
		orgDetails?.headOfficeLocation,
		orgDetails?.headOfficeLocationCountry,
	]
		.filter((part) => part !== undefined && part !== null && part !== '')
		.join(', ');

	// Memoize fetchData using useCallback
	const fetchVendorsSpecsTabCallback = useCallback(
		(signal) => {
			if (id) {
				fetchVendorsSpecs(id, signal);
			}
		},
		[id, fetchVendorsSpecs]
	);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchVendorsSpecsTabCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchVendorsSpecsTabCallback]);

	React.useEffect(() => {
		let isMounted = true; // track whether the component is mounted
		setIsLoading(true);

		const fetchOrgDetails = async () => {
			if (id) {
				try {
					const orgDetailsResults = organizationList?.find(org => org.id === id);
					if (isMounted) {
						setOrgDetails(orgDetailsResults);
					}
				} catch (error) {
					console.error('Error fetching organization details:', error);
				} finally {
					if (isMounted) {
						setIsLoading(false);
					}
				}
			}
		};

		fetchOrgDetails();

		return () => {
			isMounted = false; // set to false when the component unmounts
		};
	}, [id, organizationList]);

	const catalogGrouped = groupBy(vendorSpec?.catalog, ({ productName }) => {
		return productName;
	});

	if (isInitializingPTDVendorsSpecs) {
		return <LoadingPage />;
	}

	return (
		<div>
			<ModalOverlay
				headerChildren="Vendor Profile"
				isOpen={isOpen}
				modalSize="modal-dialog-centered"
				onRequestClose={onRequestClose}
				isLoading={isLoading}
				hideCloseButton={true}
				className="vendor-profile-modal"
			>
				{orgDetails && (
					<OrgInfoCard orgDetails={vendorSpec} orgHeadOffice={orgHeadOffice} />
				)}
				<ContactCard orgContact={vendorSpec?.contacts} />
				<OrgFacilitiesCard orgFacilities={orgDetails?.organizationFacilities} />
				<OrgLocationCard
					orgHeadOffice={orgHeadOffice}
					orgShippingLocation={orgDetails?.shippingLocation}
					orgManufacturingLocation={orgDetails?.fullManufacturingLocations}
					orgType={orgDetails?.organizationType}
					companyProfile={true}
				/>
				{vendorSpec && (
					<React.Fragment>
						<div className="d-flex align-items-stretch gap-3">
							<OrgIndustriesCard vendor={vendorSpec} />
							<OrgSectorsCard vendor={vendorSpec} />
						</div>
						<OrgProductCatalogCard
							orgProducts={Object.entries(catalogGrouped).map(
								([key, catalog]) => {
									return {
										productTypeCode: key,
										numberOfProductsInType: catalog.length,
										productSpec: catalog,
									};
								}
							)}
							showSeeDetails={false}
							showUpdateCatalog={false}
						/>
						<OrgCertificationsCard
							orgCertifications={vendorSpec?.standardsAndCertification}
						/>
						<OrgCompanyValuesCard
							orgCompanyValues={vendorSpec?.companyValues}
						/>
					</React.Fragment>
				)}
				<OrgInsuranceRange orgInsuranceRange={orgDetails?.insuranceRange} />
				<LinkedOrganizationCard
					organizationList={orgDetails?.manufacturersWorkWith}
					orgType={orgDetails?.organizationType}
					title={
						oppositeOrganizationName(orgDetails?.organizationType) +
						's Currently Working With'
					}
					orgName={orgDetails?.organizationName}
				/>
				{/* {orgDetails?.organizationType !== 'manufacturer' &&
					<LinkedOrganizationCard
						organizationList={orgDetails?.ownersWorkWith}
						orgType={orgDetails?.organizationType}
						title={orgDetails?.organizationType === 'owner' ? 'Distributors Currently Working With' : 'Owners Currently Working With'}
						orgName={orgDetails?.organizationName}
					/>
				} */}
				{/* <LinkedOrganizationCard
					organizationList={orgDetails?.approvedManufacturerList}
					orgType={orgDetails?.organizationType}
					title={`Approved Manufacturer${orgDetails?.organizationType === 'owner' ? 's' : ' Listings (AML)'}`}
					showExplanation={false}
					isAML={true}
					orgName={orgDetails?.organizationName}
				/> */}
			</ModalOverlay>
		</div>
	);
}

export default ViewVendorProfile;
