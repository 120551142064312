import React from 'react';
import { IconParentCompany } from 'assets/icons';

interface OrgParentCompanyProps {
	filteredParentCompanies: CorporateStructure[];
	organizationList: OrganizationDTO[];
}

function OrgParentCompany({ filteredParentCompanies, organizationList }: OrgParentCompanyProps) {

	// Render individual parent company
	const renderCompany = (companyId: number, index: number) => {
		const company = organizationList.find((org) => org.id === companyId);
		return (
			<div
				className="border-bottom border-gray-200 p-2 d-flex align-items-center"
				key={companyId + '-' + index}
			>
				<IconParentCompany className="me-2 smallIcon" />
				<span
					className="d-inline-block text-truncate"
					title={company?.organizationName}
				>
					{company?.organizationName || 'Unknown Company'}
				</span>
			</div>
		);
	};

	return (
		<div className="card p-3 shadow mb-3 rounded flex-1">
			<div className="card-body">
				<div className="d-flex align-items-center mb-3">
					<div className="rounded-circle bg-secondary-100 p-2 me-3">
						<IconParentCompany />
					</div>
					<h5 className="mb-0">{
						filteredParentCompanies.length > 1 ? 'Parent Organizations' : 'Parent Organization'
					}</h5>
				</div>
				<div className="container-fluid px-0">
					<div className="row">
						<div className="col-12">
							{filteredParentCompanies.length > 0 ? (
								filteredParentCompanies.map((c, index) =>
									renderCompany(c.parentCompanyId, index)
								)
							) : (
								<small className="text-muted fst-italic ps-5">
									Not found
								</small>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrgParentCompany;