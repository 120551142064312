import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

const GetUserOrganizations = async (signal?: AbortSignal) => {
	const response = await axios.get(`${apiUrl}/user-organizations`, {
		signal: signal
	})
		.then((data: { data: UserOrganization[] }) => {
			return data.data;
		})
		.catch((err: any) => {
			toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while fetching the user organizations');
			return null;
		});
	return response;
};

const UpdateUserOrganization = async (dataToSend: any, OrganizationId: number) => {
	const response = await axios.put(`${apiUrl}/user-organizations/`, dataToSend, {
		params: {OrganizationId}
	})
		.then((data: { data: any; }) => {
			return data.data;
		})
		.catch((err: any) => {
			toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while updating an user organization');
			return null;
		});
	return response;
};

const DeleteUserOrganization = async (OrganizationId: number | null) => {
	const response = await axios.delete(`${apiUrl}/user-organizations/${OrganizationId}`)
		.then((data: AxiosResponse<{ message: string; }>) => {
			return data.data.message;
		})
		.catch((err: any) => {
			toast.error(err.response?.data?.responseStatus?.message || 'An error occurred while deleting an user organization');
			return null;
		});
	return response;
};

const apiUrl = process.env.REACT_APP_API_URL;
const MultiOrgAPIs = {
	GetUserOrganizations,
	UpdateUserOrganization,
	DeleteUserOrganization
};

export default MultiOrgAPIs;