import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';
import { ReactComponent as IconCircleTick } from 'assets/icons/icon-circle-tick.svg';
import { IconCircleX } from 'assets/icons';

interface TableColumnVendorNameProps {
	vendorFormMethods: UseFormReturn<any, object>,
	row: any,
	editTable: VendorTable | ProductTable | null
}

function TableColumnValidedRowStatus({row, editTable}: TableColumnVendorNameProps) {
	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return <Text></Text>;
		}
	}

	if (row.value === undefined || row.value.length === 0) {
		return <Text></Text>;
	}
	return (
		<Text>
			{row.value === 100 ? <IconCircleTick className="mx-2" /> : <IconCircleX className="mx-2" />}
		</Text>
	);
}

export default TableColumnValidedRowStatus;
