import { isEqual } from '@react-sigma/core';
import { State, ActionTypes, READ_FILE, DELETE_FILE, READ_GRAPH, READ_PTD, DELETE_PTD } from './types';
import Graph from 'graphology';

export const initialState: State = {
	ptd: null,
	fileData: null,
	graph: new Graph(),
};

const reducer = (state: State, action: ActionTypes): State => {
	switch (action.type) {
	case READ_FILE:
		if (JSON.stringify(state.fileData) !== JSON.stringify(action.fileData)) {
			return { ...state, fileData: action.fileData, ptd: null };
		}
		return state;

	case DELETE_FILE:
		if (state.fileData && state.fileData.values.length > 0) {
			return { ...state, fileData: null };
		}
		return state;

	case READ_GRAPH:
		if (!isEqual(state.graph, action.graph)) {
			return { ...state, graph: action.graph };
		}
		return state;

	case READ_PTD:
		if (!isEqual(state.ptd, action.ptd)) {
			return { ...state, ptd: action.ptd, fileData: null };
		}
		return state;

	case DELETE_PTD:
		if (state.ptd && state.ptd.values.length > 0) {
			return { ...state, ptd: null };
		}
		return state;

	default:
		return state;
	}
};

export default reducer;
