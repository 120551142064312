import React from 'react';
import { TooltipItem } from 'chart.js';
import BarChartMUI from 'vna/components/charts/BarChartMUI';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { AccordionVendorsPerCountryProps, DynamicTableCell } from 'vna/types/interfaces';
import { isNumber } from 'chart.js/helpers';
import Accordion from 'vna/components/accordion/Accordion';

function AccordionVendorsPerCountry({ vendorsPerCountry, vendorsPerCountryArray }: AccordionVendorsPerCountryProps) {
	const vendorsPerCountryColumns: readonly DynamicTableCell[] = [
		{
			id: 'country',
			numeric: false,
			disablePadding: false,
			label: 'Country',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: true,
			label: 'Vendor',
		},
	];

	const vendorsPerCountryFormatter = (context: TooltipItem<'bar'>) => {
		const country = vendorsPerCountryArray[context.dataIndex]?.country;
		const vendors = Array.from(vendorsPerCountry[country] || []).map((vendor) => vendor);

		return (
			vendors.map((value: string | number) =>
				isNumber(value) ? value.toString() : value
			) ||
			context.label ||
			''
		);
	};

	return (
		<Accordion label="No. of vendors per country">
			<div className="row gx-4 justify-content-center">
				<div className="col-12 col-md-6">
					<BarChartMUI
						dataset={vendorsPerCountryArray.map((item) => ({
							x: item.country,
							y: item.vendor as number,
						}))}
						xLabel="Countries"
						yLabel="Vendors"
						yFullNumbers={true}
						valueFormatter={vendorsPerCountryFormatter}
					/>
				</div>

				<div className="col-12 col-md-6 mt-2">
					<EnhancedTable
						rows={vendorsPerCountryArray}
						columns={vendorsPerCountryColumns}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionVendorsPerCountry;
