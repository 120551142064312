import React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import SettingsStore from 'settings/SettingsStore';
// Import Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.scss';

// Import Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

ReactDOM.render(
	<SettingsStore>
		<App />
	</SettingsStore>,
	document.getElementById('root') as HTMLElement
);

// service workers need more work before using in an app. (ie notification of a new version)
// registerServiceWorker();
unregister();
