import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { EnhancedTableToolbar } from './TableMUI';
import TableFilterModal from '../modals/TableFilterModal';
import { CollapsibleTableProps } from '../../types/interfaces';

function Row(props: {
	rows: {
		header: string;
		body?: string;
	}[];
	header: string;
	isOpen: boolean;
}) {
	const { rows, header, isOpen } = props;
	const [open, setOpen] = React.useState(isOpen ?? false);

	return (
		<React.Fragment>
			<TableRow>
				<TableCell
					sx={{ width: '10%', tableLayout: 'auto', padding: '0 0 0 10px' }}
				>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell sx={{ paddingInline: 0 }}>
					{header ?? 'Not found'}
				</TableCell>
			</TableRow>
			{rows.map((row, index) => (
				<TableRow key={index}>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={2}>
						<Collapse in={open} timeout="auto" unmountOnExit={true}>
							<Box margin={1}>
								<Table size="small" aria-label={`${header} Details`}>
									<TableBody>
										<TableRow>
											<TableCell>{row.body ?? 'Not found'}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			))}
		</React.Fragment>
	);
}

export default function CollapsibleTable({
	dataRows,
	title,
	isOpen,
}: CollapsibleTableProps) {
	const [openFilterModal, setOpenFilterModal] = useState(false);

	const handleModalClose = (e: boolean) => {
		setOpenFilterModal(e);
	};

	return (
		<React.Fragment>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						setOpenFilterModal={setOpenFilterModal}
						title={title}
					/>
					<TableContainer component={Box}>
						<Table aria-label="collapsible table" size="small">
							<TableBody>
								{Object.entries(dataRows).map(([key, rows]) =>
									rows && <Row key={key} header={key} rows={rows} isOpen={isOpen} />
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			<TableFilterModal
				openModal={openFilterModal}
				onCloseModal={(e) => handleModalClose(e)}
			/>
		</React.Fragment>
	);
}
