import React from 'react';
import AsyncSelect from 'react-select/async';
import {
	Button,
	FormGroup,
	Input,
	LoadingPage,
	Page,
	Select,
} from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { Controller, useForm } from 'react-hook-form';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import { OnboardingErrorMsg } from 'screens/OnBoarding/utils';
import PickListContext from 'context/PickList/PickListContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
	oppositeOrganizationName,
	oppositeOrganizationType,
} from 'components/helperfunctions';
import { OrganizationSelect } from 'components/scopematch/OrganizationSelect';
import { urlRegex } from 'utils/regexes';
import debounce from 'lodash/debounce';
import UpdateContacts from 'screens/CompanyProfile/UpdateContacts';
import AuthContext from 'context/Auth/AuthContext';

type LocationsType = {
	countryDropdown?: string;
	countryDropdownShipping?: string;
	parentCompanyIds?: number[];
	subsidiaryCompanyIds?: number[];
} & OrganizationRequest;

const UpdateProfile = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const { companyProfile, isInitializing, getCompanyProfile } = React.useContext(AuthContext);
	const picklistCxt = React.useContext(PickListContext);
	const picklistItems = picklistCxt.pickListItems;
	const { control, reset, getValues, watch, setValue, handleSubmit } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const [currentCompanyId, setCurrentCompanyId] = React.useState<number>();
	const organizationType = getValues('organizationType');
	const countryOptions = [
		{ value: 'all', label: 'All' },
		{ value: 'except', label: 'All countries except for...' },
		{ value: 'specific', label: 'Specific countries' },
	];
	const manufacturingLocation = watch('manufacturingLocation');
	const organizationFacilities = watch('organizationFacilities');
	const countryDropdownShipping = watch('countryDropdownShipping');
	const parentCompanyIds = watch('parentCompanyIds');
	const subsidiaryCompanyIds = watch('subsidiaryCompanyIds');
	const organizationContacts: OrganizationContactDTO[] = watch(
		'organizationContacts'
	);
	const navigate = useNavigate();
	const pageTitle = 'Update Profile';

	const fetchLocationSuggestions = debounce((inputValue, callback) => {
		if (inputValue.length <= 2) {
			callback([]);
			return;
		}

		scopeMatchAPI.locationSuggestions
			.GetLocationSuggestions(inputValue)
			.then((response) => {
				const suggestions = response.map(
					(suggestion: { location: string }) => ({
						label: suggestion.location,
						value: suggestion.location,
					})
				);
				callback(suggestions);
			})
			.catch((error) => {
				console.error(error);
				callback([]);
			});
	}, 1000);

	React.useEffect(() => {
		async function fetchCompanyProfile() {
			const values = companyProfile ?? {};
			const { shippingLocation } = values;
			reset(values);
			setCurrentCompanyId(values.id);
			const shippingLocationValues = getCountryValues(shippingLocation);
			if (shippingLocationValues !== null) {
				setValue('countryDropdownShipping', shippingLocationValues?.countries);
				setValue('shippingLocation', shippingLocationValues?.value);
			}
			setValue(
				'manufacturingLocation',
				values.fullManufacturingLocations?.map((x) => {
					let parts = [x.locationCity, x.locationCountry];
					if (x.locationRegion) {
						parts.splice(1, 0, x.locationRegion);
					}
					return parts.join(', ');
				})
			);
			setValue(
				'manufacturersWorkWith',
				values.manufacturersWorkWith?.map((x) => x.id)
			);
			setValue(
				'ownersWorkWith',
				values.ownersWorkWith?.map((x) => x.id)
			);
			setValue(
				'approvedManufacturerList',
				values.approvedManufacturerList?.map((x) => x.id)
			);
			setValue(
				'headOfficeLocation',
				values.headOfficeLocation +
					(values.headOfficeLocationCountry !== values.headOfficeLocationRegion
						? ', ' + values.headOfficeLocationRegion
						: '') +
					', ' +
					values.headOfficeLocationCountry
			);
			setValue(
				'organizationFacilities',
				values.organizationFacilities?.map((x) => {
					let parts = [x.facilityLocationCity, x.facilityLocationCountry];
					if (x.facilityLocationRegion) {
						parts.splice(1, 0, x.facilityLocationRegion);
					}
					return parts.join(', ');
				})
			);

			const filteredParentCompanies = Array.from(
				new Map(
					values.corporateStructures
						?.filter(
							(c) =>
								c.parentCompanyId !== values?.id &&
								c.subsidiaryCompanyId === values?.id
						)
						.map((c) => [c.parentCompanyId, c]) // Map each parentCompanyId to its corresponding record
				).values() // Take only unique records based on parentCompanyId
			);
			const filteredSubsidiaryCompanies = Array.from(
				new Map(
					values.corporateStructures
						?.filter(
							(c) =>
								c.subsidiaryCompanyId !== values?.id &&
								c.parentCompanyId === values?.id
						)
						.map((c) => [c.subsidiaryCompanyId, c]) // Map each subsidiaryCompanyId to its corresponding record
				).values() // Take only unique records based on subsidiaryCompanyId
			);

			setValue(
				'parentCompanyIds',
				filteredParentCompanies?.map((c) => c.parentCompanyId)
			);
			setValue(
				'subsidiaryCompanyIds',
				filteredSubsidiaryCompanies?.map((c) => c.subsidiaryCompanyId)
			);

			setIsLoading(false);
		}
		fetchCompanyProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyProfile]);

	if (isLoading === true || isInitializing) {
		return <LoadingPage />;
	}

	return (
		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar pageTitle={pageTitle} backButton={true} />
				</div>
			</div>
			<div className="container-fluid mb-5 px-5 py-3">
				{organizationType?.toString() !== 'owner' && (
					<React.Fragment>
						<div className="d-flex mb-4 align-items-center">
							<div className="">
								<h3 className="mb-0">Product Catalog</h3>
							</div>
							<div className="ms-auto">
								<Button
									to="/company-profile/product-catalog"
									className="btn-primary"
								>
									Update Catalog <IconEdit className="ms-2" />
								</Button>
							</div>
						</div>
						<hr className="my-5" />
					</React.Fragment>
				)}
				<h3>
					Select all sectors and specific industries your company works with
				</h3>
				<Controller
					control={control}
					name="sectors"
					rules={{ required: OnboardingErrorMsg.Sector }}
					render={({ field, fieldState }) => (
						<FormGroup
							label="Sector"
							required={true}
							field={field}
							fieldState={fieldState}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)
							</span>
							<PickListSelect
								id="sector-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								onChange={field.onChange}
								placeholder=""
								pickListTypeName="Sector"
								error={!!fieldState.error?.message}
							/>
						</FormGroup>
					)}
				/>
				<Controller
					control={control}
					name="industries"
					rules={{ required: OnboardingErrorMsg.Industries }}
					render={({ field, fieldState }) => (
						<FormGroup
							label="Industry"
							field={field}
							fieldState={fieldState}
							required={true}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)
							</span>
							<PickListSelect
								id="industries-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								pickListTypeName="Industry"
								onChange={field.onChange}
								error={!!fieldState.error?.message}
								placeholder=""
							/>
						</FormGroup>
					)}
				/>
				<hr className="my-5" />
				<h3>Company Information</h3>
				<Controller
					control={control}
					name="companyUrl"
					rules={{
						pattern: {
							value: urlRegex,
							message: OnboardingErrorMsg.CompanyUrlSyntax,
						},
					}}
					render={({ field, fieldState }) => (
						<FormGroup
							id="urlInput"
							label="Link to company website"
							required={false}
							field={field}
							fieldState={fieldState}
						>
							<Input {...field} error={!!fieldState.error?.message} />
						</FormGroup>
					)}
				/>
				<hr className="my-5" />
				<h3>Corporate Structure</h3>
				<Controller
					control={control}
					name="parentCompanyIds"
					render={({ field, fieldState }) => (
						<FormGroup
							id="parentCompanyIds"
							label="Parent Organization(s)"
							required={false}
							field={field}
							fieldState={fieldState}
						>
							<OrganizationSelect
								id="parent-select"
								isMulti={true}
								showAddInstitution={true}
								name={field.name}
								setValue={setValue}
								value={field.value}
								onChange={field.onChange}
								placeholder=""
								lightButtonText={'Add Company'}
								error={!!fieldState.error?.message}
								exceptIds={[currentCompanyId, ...subsidiaryCompanyIds]}
							/>
						</FormGroup>
					)}
				/>
				<Controller
					control={control}
					name="subsidiaryCompanyIds"
					render={({ field, fieldState }) => (
						<FormGroup
							id="subsidiaryCompanyIds"
							label="Subsidiary Organization(s)"
							required={false}
							field={field}
							fieldState={fieldState}
						>
							<OrganizationSelect
								id="subsidiary-select"
								isMulti={true}
								showAddInstitution={true}
								name={field.name}
								setValue={setValue}
								value={field.value}
								onChange={field.onChange}
								placeholder=""
								lightButtonText={'Add Company'}
								error={!!fieldState.error?.message}
								exceptIds={[currentCompanyId, ...parentCompanyIds]}
							/>
						</FormGroup>
					)}
				/>
				<hr className="my-5" />
				<h3>Contacts</h3>
				<div>
					{currentCompanyId && (
						<UpdateContacts
							setValue={setValue}
							organizationContacts={organizationContacts}
						/>
					)}
				</div>

				<hr className="my-5" />
				<h3>Location Of Head Office</h3>
				<Controller
					control={control}
					name="headOfficeLocation"
					rules={{ required: 'Head office city is required' }}
					render={({ field, fieldState }) => (
						<FormGroup
							label="Head office location"
							field={field}
							fieldState={fieldState}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(Enter city or closest city)
							</span>
							<AsyncSelect
								id="office-select"
								name={field.name}
								value={{ label: field.value, value: field.value }}
								loadOptions={(inputValue, callback) =>
									fetchLocationSuggestions(inputValue, callback)
								}
								onChange={(selectedOption) => {
									field.onChange(selectedOption ? selectedOption.value : '');
								}}
								placeholder="Please start typing your location..."
								isClearable={true}
							/>
						</FormGroup>
					)}
				/>
				{organizationType?.toString() === 'owner' && (
					<React.Fragment>
						<hr className="my-5" />
						<h3>Facilities/Plants</h3>
						<Controller
							control={control}
							name="organizationFacilities"
							render={({ field, fieldState }) => (
								<FormGroup label="" field={field} fieldState={fieldState}>
									<span className="fw-normal fst-italic text-gray-700">
										(Enter cities or closest cities, add all locations)
									</span>
									<AsyncSelect
										id="facilities-select"
										isMulti={true}
										name={field.name}
										value={
											Array.isArray(field.value)
												? field.value.map((val) => ({ label: val, value: val }))
												: [{ label: field.value, value: field.value }]
										}
										loadOptions={fetchLocationSuggestions}
										onChange={(selectedOptions) => {
											const selectedValues = selectedOptions
												? selectedOptions.map((option) => option.value)
												: [];
											field.onChange(selectedValues);
										}}
										placeholder="Please start typing your locations..."
										isClearable={true}
										noOptionsMessage={() => 'Type a valid city name'}
									/>
								</FormGroup>
							)}
						/>
					</React.Fragment>
				)}
				{organizationType?.toString() !== 'owner' && (
					<React.Fragment>
						<hr className="my-5" />
						<h3>
							{organizationType?.toString() === 'distributor'
								? 'Distributing Locations'
								: 'Manufacturing Locations'}
						</h3>
						<Controller
							control={control}
							name="manufacturingLocation"
							rules={{
								required: OnboardingErrorMsg.Countries,
							}}
							render={({ field, fieldState }) => (
								<FormGroup
									label={
										organizationType?.toString() === 'distributor'
											? 'Where do you have distribution warehouses? '
											: 'Where are your manufacturing facilites located? '
									}
									field={field}
									fieldState={fieldState}
								>
									<span className="fw-normal fst-italic text-gray-700">
										(Enter cities or closest cities, add all locations)
									</span>
									<AsyncSelect
										id="manufacturing-location-select"
										isMulti={true}
										name={field.name}
										value={
											Array.isArray(field.value)
												? field.value.map((val) => ({ label: val, value: val }))
												: [{ label: field.value, value: field.value }]
										}
										loadOptions={fetchLocationSuggestions}
										onChange={(selectedOptions) => {
											const selectedValues = selectedOptions
												? selectedOptions.map((option) => option.value)
												: [];
											field.onChange(selectedValues);
										}}
										placeholder="Please start typing your locations..."
										isClearable={true}
									/>
								</FormGroup>
							)}
						/>
						{organizationType === 'manufacturer' && (
							<React.Fragment>
								<hr className="my-5" />
								<h3>Export Locations</h3>
								<Controller
									control={control}
									name="countryDropdownShipping"
									render={({ field, fieldState }) => (
										<FormGroup
											label="What countries have you exported to in the last 5 years?"
											field={field}
											fieldState={fieldState}
										>
											<Select
												id="country-select"
												name={field.name}
												value={field.value}
												options={countryOptions}
												onChange={field.onChange}
												error={!!fieldState.error?.message}
											/>
										</FormGroup>
									)}
								/>
							</React.Fragment>
						)}
						{countryDropdownShipping !== undefined &&
							countryDropdownShipping !== 'all' &&
							organizationType === 'manufacturer' && (
							<Controller
								control={control}
								name="shippingLocation"
								rules={{
									required: OnboardingErrorMsg.Countries,
								}}
								render={({ field, fieldState }) => (
									<FormGroup
										label={
											countryDropdownShipping === 'except'
												? 'All countries except for...'
												: 'Specific countries'
										}
										field={field}
										fieldState={fieldState}
									>
										<span className="fw-normal fst-italic text-gray-700">
												(select all that apply)
										</span>
										<PickListSelect
											id="allcountries-select"
											isMulti={true}
											name={field.name}
											value={field.value}
											pickListTypeName="Country"
											onChange={field.onChange}
											error={!!fieldState.error?.message}
										/>
									</FormGroup>
								)}
							/>
						)}
						<hr className="my-5" />
						<h3>Additional Details</h3>
						<Controller
							control={control}
							name="insuranceRange"
							render={({ field, fieldState }) => (
								<FormGroup
									label="Insurance range"
									field={field}
									fieldState={fieldState}
								>
									<PickListSelect
										id="insurance-select"
										name={field.name}
										value={field.value}
										pickListTypeName="Insurance Ranges"
										onChange={field.onChange}
										placeholder=""
										orderBy={{
											order: 'id',
											orderBy: 'asc',
										}}
									/>
								</FormGroup>
							)}
						/>
						<Controller
							control={control}
							name="standardsAndCertification"
							render={({ field, fieldState }) => (
								<FormGroup
									label="Business Standards and Certifications"
									field={field}
									fieldState={fieldState}
								>
									<span className="fw-normal fst-italic text-gray-700">
										(select all that apply)
									</span>
									<PickListSelect
										id="standards-select"
										isMulti={true}
										name={field.name}
										value={field.value}
										pickListTypeName="Standards and Certifications"
										onChange={field.onChange}
										placeholder=""
									/>
								</FormGroup>
							)}
						/>
					</React.Fragment>
				)}
				<hr className="my-5" />
				<h3>Corporate Values</h3>
				<Controller
					control={control}
					name="companyValues"
					render={({ field, fieldState }) => (
						<FormGroup
							field={field}
							fieldState={fieldState}
						>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)
							</span>
							<PickListSelect
								id="companyvalues-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								pickListTypeName="Company Values"
								onChange={field.onChange}
								placeholder=""
							/>
						</FormGroup>
					)}
				/>
				{organizationType?.toString() !== 'owner' && (
					<React.Fragment>
						{organizationType?.toString() === 'manufacturer' && (
							<React.Fragment>
								<hr className="my-5" />
								<h3>Approved Manufacturers List</h3>
								<Controller
									control={control}
									name="approvedManufacturerList"
									render={({ field, fieldState }) => (
										<FormGroup field={field} fieldState={fieldState}>
											<p>
												Indicate which companies have listed you as an approved
												manufacturer{' '}
												<span className="fw-normal fst-italic text-gray-700">
													(select all that apply)
												</span>
											</p>

											<OrganizationSelect
												id="standards-select"
												isMulti={true}
												organizationType={organizationType}
												showAddInstitution={true}
												name={field.name}
												setValue={setValue}
												value={field.value}
												onChange={field.onChange}
												placeholder=""
												buttonText={'Add Company'}
												isAML={true}
											/>
										</FormGroup>
									)}
								/>
							</React.Fragment>
						)}
						<hr className="my-5" />
						<h3>
							{oppositeOrganizationName(organizationType)}s You Currently Work
							With
						</h3>
						<Controller
							control={control}
							name="manufacturersWorkWith"
							render={({ field, fieldState }) => (
								<FormGroup field={field} fieldState={fieldState}>
									<p>
										Indicate which {oppositeOrganizationName(organizationType)}s
										you currently work with{' '}
										<span className="fw-normal fst-italic text-gray-700">
											(select all that apply or add them if you cannot find them
											in the list)
										</span>
									</p>
									<OrganizationSelect
										id="standards-select"
										isMulti={true}
										organizationType={organizationType}
										showAddInstitution={true}
										name={field.name}
										setValue={setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText={`Add ${oppositeOrganizationType(
											organizationType
										)}`}
									/>
								</FormGroup>
							)}
						/>
					</React.Fragment>
				)}
				{organizationType?.toString() !== 'manufacturer' && (
					<React.Fragment>
						<hr className="my-5" />
						<h3>
							{organizationType === 'distributor' ? 'Owner' : 'Distributor'}s
							You Currently Work With
						</h3>
						<Controller
							control={control}
							name="ownersWorkWith"
							render={({ field, fieldState }) => (
								<FormGroup field={field} fieldState={fieldState}>
									<p>
										Indicate which{' '}
										{organizationType === 'distributor'
											? 'Owner'
											: 'Distributor'}
										s you currently work with
										<span className="fw-normal fst-italic text-gray-700">
											(select all that apply or add them if you cannot find them
											in the list)
										</span>
									</p>
									<OrganizationSelect
										id="ownersworkwith-select"
										isMulti={true}
										organizationType={organizationType}
										showAddInstitution={true}
										name={field.name}
										setValue={setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										dist2owner={true}
										dist2ownerAdd={
											organizationType === 'distributor'
												? 'owner'
												: 'distributor'
										}
										buttonText={`Add ${
											organizationType === 'distributor'
												? 'Owner'
												: 'Distributor'
										}`}
									/>
								</FormGroup>
							)}
						/>
					</React.Fragment>
				)}
				{organizationType?.toString() === 'owner' && (
					<React.Fragment>
						<hr className="my-5" />
						<h3>Approved Manufacturers List</h3>
						<Controller
							control={control}
							name="approvedManufacturerList"
							render={({ field, fieldState }) => (
								<FormGroup field={field} fieldState={fieldState}>
									<p>
										Indicate which companies are your approved manufacturers{' '}
										<span className="fw-normal fst-italic text-gray-700">
											((select all that apply or add them if you cannot find
											them in the list))
										</span>
									</p>

									<OrganizationSelect
										id="standards-select"
										isMulti={true}
										organizationType={organizationType}
										showAddInstitution={true}
										name={field.name}
										setValue={setValue}
										value={field.value}
										onChange={field.onChange}
										placeholder=""
										buttonText={'Add Manufacturer'}
									/>
								</FormGroup>
							)}
						/>
					</React.Fragment>
				)}
				<hr className="my-5" />
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12 shadow ps-5 py-3 bg-white save-profile">
						<Button className="btn-primary" onClick={handleSubmit(submitForm)}>
							Save Changes
						</Button>
					</div>
				</div>
			</div>
		</Page>
	);

	function getCountryValues(countryField: string[] | undefined) {
		const countries = picklistItems.filter(
			(v) => v.pickListType?.code === 'Country'
		);
		if (countryField === undefined || countryField.length === 0) {
			return null;
		}
		const hasAllCountries = countries.every(
			(v) => v.code && countryField?.includes(v.code)
		);
		if (hasAllCountries) {
			return { countries: 'all', value: countryField };
		}
		const specificCountries = countryField.length < countries.length * 0.5;

		if (specificCountries) {
			const filteredCountries = countries
				.filter((x) => x.code && countryField.includes(x.code))
				.map((x) => x.code ?? '');
			return { countries: 'specific', value: filteredCountries };
		}
		const filteredCountries = countries
			.filter((x) => x.code && !countryField.includes(x.code))
			.map((x) => x.code ?? '');
		return { countries: 'except', value: filteredCountries };
	}

	async function submitForm(fields: LocationsType) {
		setIsLoading(true);

		const countries = getCountries();
		const countriesInfo = getCountriesInfo();

		const {
			countryDropdownShipping,
			parentCompanyIds,
			subsidiaryCompanyIds,
			shippingLocation,
			remainingFields,
		} = processFields(fields);
		remainingFields.manufacturingLocation =
			getManufacturingLocations(countriesInfo);
		remainingFields.fullManufacturingLocations = getManufacturingLocationDTOs();
		remainingFields.organizationFacilities = getOrganizationFacilities();

		setCorporateStructures(
			remainingFields,
			parentCompanyIds,
			subsidiaryCompanyIds
		);

		processWorkWithFields(remainingFields, fields);

		try {
			processShippingLocation(
				remainingFields,
				countryDropdownShipping,
				shippingLocation,
				countries
			);
			await scopeMatchAPI.organization.PostOrganization(remainingFields);
			setIsLoading(false);
			getCompanyProfile();
			navigate(-1);
			return true;
		} catch (error) {
			toast.error('Could not save your user data');
			setIsLoading(false);
			return false;
		}
	}

	// Helper functions

	function getCountries() {
		return picklistCxt.pickListItems
			.filter((v) => v.pickListType?.code === 'Country')
			.map((v) => v.code) as string[];
	}

	function getCountriesInfo() {
		return picklistCxt.pickListItems
			.filter((v) => v.pickListType?.code === 'Country')
			.map((v) => ({ code: v.code, description: v.description }));
	}

	function processFields(fields: LocationsType) {
		const {
			countryDropdownShipping,
			parentCompanyIds,
			subsidiaryCompanyIds,
			shippingLocation,
			...remainingFields
		} = fields;

		if (fields.headOfficeLocation) {
			const locationParts = fields.headOfficeLocation
				.split(',')
				.map((part) => part.trim());
			remainingFields.headOfficeLocation = locationParts[0] || '';
			remainingFields.headOfficeLocationRegion =
				locationParts[1] || locationParts[0] || '';
			remainingFields.headOfficeLocationCountry =
				locationParts[2] || locationParts[1] || locationParts[0] || '';
		}
		return {
			countryDropdownShipping,
			parentCompanyIds,
			subsidiaryCompanyIds,
			shippingLocation,
			remainingFields,
		};
	}

	function getManufacturingLocationDTOs() {
		return manufacturingLocation.map((loc: string) => {
			const parts = loc.split(',').map((part) => part.trim());
			return {
				LocationCity: parts[0],
				LocationRegion: parts.length >= 3 ? parts[parts.length - 2] : null,
				LocationCountry: parts[parts.length - 1],
			};
		});
	}

	function getManufacturingLocations(
		countriesInfo: {
			code: string | undefined;
			description: string | undefined;
		}[]
	) {
		const locationCountries = manufacturingLocation
			.map((loc: string) => loc.split(',').pop()?.trim())
			.filter(
				(country: string | undefined): country is string =>
					country !== undefined && country !== ''
			);

		return Array.from(
			new Set(
				countriesInfo
					.filter(
						(country) =>
							locationCountries.includes(country.code) ||
							locationCountries.includes(country.description)
					)
					.map((country) => country.code)
					.filter((code): code is string => !!code)
			)
		);
	}

	function getOrganizationFacilities() {
		return organizationFacilities.map((loc: string) => {
			const parts = loc.split(',').map((part) => part.trim());
			return {
				facilityLocationCity: parts[0],
				facilityLocationRegion:
					parts.length >= 3 ? parts[parts.length - 2] : null,
				facilityLocationCountry: parts[parts.length - 1],
			};
		});
	}

	function setCorporateStructures(
		remainingFields: any,
		parentCompanyIds: number[] | undefined,
		subsidiaryCompanyIds: number[] | undefined
	) {
		remainingFields.corporateStructures = [];

		if (parentCompanyIds && currentCompanyId) {
			parentCompanyIds.forEach((parentId) =>
				remainingFields.corporateStructures?.push({
					parentCompanyId: parentId,
					subsidiaryCompanyId: currentCompanyId,
					ownershipPct: 0,
				})
			);
		}

		if (subsidiaryCompanyIds && currentCompanyId) {
			subsidiaryCompanyIds.forEach((subsidiaryId) =>
				remainingFields.corporateStructures?.push({
					parentCompanyId: currentCompanyId,
					subsidiaryCompanyId: subsidiaryId,
					ownershipPct: 0,
				})
			);
		}
	}

	function processWorkWithFields(remainingFields: any, fields: LocationsType) {
		remainingFields.manufacturersWorkWith = fields.manufacturersWorkWith?.map(
			(id) => ({ id })
		);
		remainingFields.ownersWorkWith = fields.ownersWorkWith?.map((id) => ({
			id,
		}));
		remainingFields.approvedManufacturerList =
			fields.approvedManufacturerList?.map((id) => ({ id }));
	}

	function processShippingLocation(
		remainingFields: any,
		countryDropdownShipping: string | undefined,
		shippingLocation: string[] | undefined,
		countries: string[]
	) {
		if (countryDropdownShipping === 'except') {
			remainingFields.shippingLocation = countries.filter(
				(v) => !shippingLocation?.includes(v)
			);
		}
		if (countryDropdownShipping === 'all') {
			remainingFields.shippingLocation = countries;
		}
	}
};

export default UpdateProfile;
