export function oppositeOrganizationType(organizationType: OrganizationTypeEnum | undefined): OrganizationTypeEnum | null {
	if (organizationType?.toString() === 'distributor') {
		return 'manufacturer';
	} else if (organizationType?.toString() === 'manufacturer') {
		return 'distributor';
	} else if (organizationType?.toString() === 'owner') {
		return 'manufacturer';
	} else {
		return null;
	}

}
export function oppositeOrganizationName(organizationType: OrganizationTypeEnum | undefined): string | null {
	if (organizationType && organizationType?.toString() === 'distributor') {
		return 'Manufacturer';
	} else if (organizationType?.toString() === 'manufacturer') {
		return 'Distributor';
	} else {
		return null;
	}

}
export function capitalizeFirstLetter(str: string | null | undefined) {
	if (!str) {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
}