import React from 'react';
import Sidebar from 'vna/components/sidebar/Sidebar';
import HomePage from 'vna/pages/HomePage';
import { StoreProvider } from 'vna/store/context';
import './vna.scss'; // Import the CSS file

function AppVNA() {
	return (
		<StoreProvider>
			<div className="container-fluid px-0">
				<div className="d-flex flex-wrap flex-column flex-lg-row">
					<div className="p-0 sidebar">
						<Sidebar />
					</div>
					<div className="main-content">
						<HomePage />
					</div>
				</div>
			</div>

		</StoreProvider>
	);
}

export default AppVNA;