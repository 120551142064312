import React from 'react';
import { useEffect, useState } from 'react';
import { GroupedByStatsProps } from '../../../types/interfaces';
import { sortByField } from 'utils/sort';
import './GroupedByStats.scss'; // Import the CSS file

function GroupedByStats({
	vendorsPerCountry,
	productsPerCountry,
	vendorsPerProduct,
	countriesPerProduct,
}: GroupedByStatsProps) {
	const [stats, setStats] = useState<
		{
			id: number;
			name: string;
			average: string;
			skewness: number;
			topThree: string[];
			bottomThree: string[];
		}[]
	>([]);

	const average = (arr: number[]) =>
		arr.reduce((p, c) => p + c, 0) / arr.length;
	const precision: number = 3;
	const topNumber: number = 3;
	const bottomNumber: number = 3;

	useEffect(() => {
		setStats([
			{
				id: 1,
				name: 'Vendors per country',
				average: average(
					vendorsPerCountry.map((item) => item.vendor as number)
				).toPrecision(precision),
				skewness: 0,
				topThree: sortByField([...vendorsPerCountry], 'vendor', true)
					.slice(0, topNumber)
					.map((item) => item.country),
				bottomThree: sortByField([...vendorsPerCountry], 'vendor', false)
					.slice(0, bottomNumber)
					.map((item) => item.country),
			},
			{
				id: 2,
				name: 'Products per country',
				average: average(
					productsPerCountry.map((item) => item.product as number)
				).toPrecision(precision),
				skewness: 0,
				topThree: sortByField([...productsPerCountry], 'product', true)
					.slice(0, topNumber)
					.map((item) => item.country),
				bottomThree: sortByField([...productsPerCountry], 'product', false)
					.slice(0, bottomNumber)
					.map((item) => item.country),
			},
			{
				id: 3,
				name: 'Vendors per product',
				average: average(
					vendorsPerProduct.map((item) => item.vendor as number)
				).toPrecision(precision),
				skewness: 0,
				topThree: sortByField([...vendorsPerProduct], 'vendor', true)
					.slice(0, topNumber)
					.map((item) => item.product),
				bottomThree: sortByField([...vendorsPerProduct], 'vendor', false)
					.slice(0, bottomNumber)
					.map((item) => item.product),
			},
			{
				id: 4,
				name: 'Countries per product',
				average: average(
					countriesPerProduct.map((item) => item.country as number)
				).toPrecision(precision),
				skewness: 0,
				topThree: sortByField([...countriesPerProduct], 'country', true)
					.slice(0, topNumber)
					.map((item) => item.product),
				bottomThree: sortByField([...countriesPerProduct], 'country', false)
					.slice(0, bottomNumber)
					.map((item) => item.product),
			},
		]);
	}, [
		vendorsPerCountry,
		productsPerCountry,
		vendorsPerProduct,
		countriesPerProduct,
	]);

	return (
		<div className="p-4 p-sm-2">
			<ul role="list" className="stats row g-2 row-cols-1 row-cols-lg-2 ps-0">
				{stats.map((stat) => (
					<li key={stat.id} className="col">
						<div className="stat border rounded fs-12">
							<div
								className="stat-header d-flex align-items-center border-bottom"
							>
								<div className="fw-bold lh-base">{stat.name}</div>
							</div>
							<dl className="lh-base stat-body">
								<div className="d-flex justify-content-between py-1">
									<dt className="text-muted">Average</dt>
									<dd>{stat.average}</dd>
								</div>
								<div className="d-flex justify-content-between gap-3 py-1">
									<dt className="text-muted w-50">Top 3</dt>
									<dd className="d-flex flex-column gap-2 w-100 text-end">
										<div className="fw-medium">{stat.topThree.join('; ')}</div>
									</dd>
								</div>
								<div className="d-flex justify-content-between gap-3 py-1">
									<dt className="text-muted flex-grow-1 w-50">Bottom 3</dt>
									<dd className="d-flex flex-column gap-2 flex-shrink-1 w-100 text-end">
										<div className="fw-medium">
											{stat.bottomThree.join('; ')}
										</div>
									</dd>
								</div>
							</dl>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}

export default GroupedByStats;
