import React from 'react';
import { IconIndustries } from 'assets/icons';
import Pill from 'screens/Requests/components/pills/Pill';

interface OrgIndustriesCardProps {
	vendor: PTDVendorSpecs
}
function OrgIndustriesCard({vendor}: OrgIndustriesCardProps) {
	return (
		<div className="card shadow p-3 mb-2 rounded flex-1">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconIndustries />
				</div>
				<h5 className="mb-0">Industries</h5>
			</div>
			<hr />
			<React.Fragment>
				<div className="d-flex flex-wrap align-items-start">
					{vendor && vendor.industries?.length > 0 ?
						vendor.industries?.map((i, key) => (
							<Pill className="text-primary fw-bold" key={key}>{i}</Pill>
						)) : <p className="text-gray-600 fst-italic">No industries added</p>}
				</div>
			</React.Fragment>
		</div>
	);
}

export default OrgIndustriesCard;
