/* eslint-disable indent */
import scopeMatchAPI from 'api/ScopeMatchAPI';
import React from 'react';
import { ActionMeta, default as ReactSelect, GroupBase, OptionsOrGroups, Props as ReactSelectProps, } from 'react-select';
import { useToast } from 'components/customHooks/useToast';
import { Button, FormGroup, Input, ModalOverlay } from 'components/core';
import { Controller, useForm } from 'react-hook-form';
import ScopeMatchAPI from 'api/ScopeMatchAPI';
import { oppositeOrganizationType } from 'components/helperfunctions';

interface IProps extends Omit<ReactSelectProps, 'options'> {
	error?: boolean;
	filter?: string[];
	showAddInstitution?: boolean;
	organizationType?: OrganizationTypeEnum;
	vendorOrganizationType?: VendorType;
	additionalOptions?: { value: string; label: string }[];
	setValue: any;
	value: OrganizationDTO[] | undefined;
	buttonText?: string;
	lightButtonText?: string;
	isAML?: boolean;
	dist2owner?: boolean;
	dist2ownerAdd?: string;
	onAddInstitution?: (result: any) => void,
	exceptIds?: (number | undefined)[];
}

export const OrganizationSelect = (props: IProps) => {
	const {
		organizationType,
		value,
		isMulti,
		error,
		onChange,
		setValue,
		name,
		onAddInstitution,
		exceptIds,
		...otherProps
	} = props;
	const [organizationList, setOrganizationList] = React.useState<
		{ value: number | undefined; label: string | undefined }[]
	>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const toast = useToast();
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const manufacturerMethods = useForm<OrganizationDTO>();
	const getOppositeOrgType = (
		orgType?: OrganizationTypeEnum,
		dist2owner?: boolean
	): string | boolean => {
		switch (orgType) {
			case 'manufacturer':
				return 'distributor';
			case 'distributor':
				return dist2owner ? 'owner' : 'manufacturer';
			case 'owner':
				return dist2owner ? 'distributor' : 'manufacturer';
			default:
				return true;
		}
	};

	React.useEffect(() => {
		let isMounted = true;

		async function getOrganizationList() {
			const results = await scopeMatchAPI.organization.GetOrganizationList();

			if (isMounted) {
				if (results) {
					const amlOptions = results!.filter(
						(item) => item.organizationType === 'owner'
					);

					const oppositeOrgType = props.vendorOrganizationType || getOppositeOrgType(
						props.organizationType,
						props.dist2owner
					);

					const oppositeOrgOptions = results!.filter((item) => {
						if (oppositeOrgType === true) {
							return true;
						}
						return item.organizationType === oppositeOrgType;
					});
					const options = props.isAML
						? amlOptions.map((item) => {
							return { value: item.id, label: item.organizationName };
						})
						: oppositeOrgOptions.map((item) => {
							return { value: item.id, label: item.organizationName };
						});

					options.sort((a, b) => {
						const labelA = a.label || '';
						const labelB = b.label || '';
						return labelA.localeCompare(labelB);
					});

					setOrganizationList(options);
				} else {
					toast.error('Unable to retrieve Organization list');
				}
				setIsLoading(false);
			}

		}

		getOrganizationList();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setOrganizationList((options) => options.filter(o => o.value && !exceptIds?.includes(o.value)));
	}, [exceptIds]);

	function handleAddButton() {
		manufacturerMethods.reset();
		setShowModal(true);
		return null;
	}

	return (
		<React.Fragment>
			<ReactSelect
				isMulti={isMulti || false}
				options={organizationList}
				value={getValue(value, organizationList) || ''}
				onChange={onChangeVal}
				menuPosition="fixed"
				styles={{
					control: (provided, state) => ({
						...provided,
						backgroundColor: error ? '#FFE6DC' : '#FFFFFF',
						borderRadius: '4px',
						boxShadow: state.isFocused ? 'inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 99, 97, 0.25);' : 'none',
						borderColor: state.isFocused ? '#006361' : error ? '#F15D1E' : '#BDB3AD',
						'&:hover': {
						  borderColor: state.isFocused ? '#006361' : error ? '#F15D1E' : '#BDB3AD',
						},
					}),
					placeholder: (provided) => ({
						...provided,
						color: error ? '#F15D1E' : '#BDB3AD',
					}),
				}}
				{...otherProps}
			/>
			{props.showAddInstitution && (
				<React.Fragment>
					{
					props.buttonText &&
					<div className="align-items-center py-4">
						<p className="fw-bold mb-2">
							Didn't find what you were looking for?
						</p>
						<Button
							onClick={handleAddButton}
							className={'btn-outline-primary text-capitalize'}
						>
							{props.buttonText}
						</Button>
					</div>
					}

					{props.lightButtonText &&
						<Button
							onClick={handleAddButton}
							className="btn btn-link fs-12 p-1">
							{props.lightButtonText}
						</Button>}

					<ModalOverlay
						isOpen={showModal}
						modalSize="modal-lg modal-dialog-centered"
						isLoading={isLoading}
						onRequestClose={closeModal}
						headerChildren={
							props.vendorOrganizationType !== undefined ? 'Add Vendor' :
							props.dist2ownerAdd !== undefined
								? props.dist2ownerAdd
								: (props.isAML
									? 'Add Company'
									: organizationType ? `Add ${oppositeOrganizationType(organizationType)}`
									: 'Add Company'
								)
						}
						confirmButtonChildren="Submit"
						cancelButtonChildren="Cancel"
						confirmButtonAction={manufacturerMethods.handleSubmit(
							addInstitution
						)}
						cancelButtonAction={() => {
							closeModal();
						}}
					>
						<Controller
							control={manufacturerMethods.control}
							name="organizationName"
							rules={{ required: 'Name is required' }}
							render={({ field, fieldState }) => (
								<FormGroup
									className="text-capitalize"
									label={
										props.vendorOrganizationType !== undefined ? 'Vendor Name' :
										props.dist2ownerAdd !== undefined
											? `${props.dist2ownerAdd} Name`
											: (props.isAML
												? 'Company Name'
												: organizationType ? `${oppositeOrganizationType(organizationType)} Name`
												: 'Company Name'
											)}
									field={field}
									fieldState={fieldState}
								>
									<Input {...field} value={field.value || ''} error={!!fieldState.error?.message} />
								</FormGroup>
							)}
						/>
					</ModalOverlay>
				</React.Fragment>
			)}
		</React.Fragment>
	);

	function getValue(
		value: unknown,
		options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
	) {
		if (Array.isArray(value)) {
			const val = options?.filter(
				(v: any) => value.findIndex((j: any) => j === v.value) !== -1
			);
			return val;
		}
		if (typeof value === 'string' || typeof value === 'number') {
			const val = options?.filter((v: any) => v.value === value);
			return val;
		}
		return undefined;
	}
	function closeModal() {
		setShowModal(false);
	}
	async function addInstitution(newValues: OrganizationDTO) {
		setIsLoading(true);

		const oppositeOrgType = oppositeOrganizationType(organizationType);
		if (props.vendorOrganizationType === undefined) {
			newValues.organizationType =
			props.dist2ownerAdd !== undefined
				? props.dist2ownerAdd as OrganizationTypeEnum
				: (props.isAML
					? 'owner'
					: oppositeOrgType || undefined
				);
		} else {
			newValues.organizationType = props.vendorOrganizationType as OrganizationTypeEnum;
		}

		const result = await ScopeMatchAPI.organization.AddOrganization(newValues);

		if (result === null) {
			toast.error(
				props.dist2ownerAdd !== undefined
					? `This ${props.dist2ownerAdd} has already been added`
					: (props.isAML
						? 'This Company has already been added'
						: organizationType ? `This ${oppositeOrganizationType(
							organizationType
						)} has already been added`
					: 'This Company has already been added')
			);
		} else {
			const newOption = { value: result?.id, label: result?.organizationName };
			setOrganizationList([...organizationList, newOption]);
			if (value !== undefined) {
				if (Array.isArray(value)) {
					setValue(name, [...value, result?.id]);
				} else {
					setValue(name, result?.id);
				}
			} else {
				setValue(name, [result?.id]);
			}
			setShowModal(false);
		}
		if (onAddInstitution) {
			onAddInstitution(result);
		}
		setIsLoading(false);
	}
	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map((v) => v.value);
			onChange(value, actionMeta);
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any };
			const value = thewNewValue.value!;
			onChange(value, actionMeta);
			return;
		}
		onChange(undefined, actionMeta);
		return undefined;
	}
};
