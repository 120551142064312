import React from 'react';
import { FormGroup, Page } from 'components/core';
import OnBoardingProgressBar from './components/ProgressBar';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PickListSelect } from 'components/scopematch/PickListSelect';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingContainer from './components/OnboardingContainer';
import { FormContext } from './FormContext';
import BackForwardButtons from './components/BackForwardButtons';

const CompanyValues = () => {

	const { control, getValues } = useFormContext<OrganizationRequest>();
	const location = useLocation();
	const FormCxt = React.useContext(FormContext);
	const organizationType = getValues('organizationType');

	return (
		<Page
			title="Company Values"
			className="onboarding-wrapper"
		>
			<OnboardingContainer>
				<OnBoardingProgressBar indexInfo={FormCxt.CurrentIndex(organizationType, location.pathname)} />
				<h2 className="pt-4 mb-2">Corporate Values</h2>
				<p className="">Setting company values will help match your company with requests looking for value requirements</p>
				<p className="text-gray-700 fst-italic pb-4 mb-0">You can skip this step and complete this later in your dashboard</p>
				<Controller
					control={control}
					name="companyValues"
					render={({ field, fieldState }) =>
						<FormGroup field={field} fieldState={fieldState}>
							<span className="fw-normal fst-italic text-gray-700">
								(select all that apply)</span>
							<PickListSelect
								id="companyvalues-select"
								isMulti={true}
								name={field.name}
								value={field.value}
								pickListTypeName="Company Values"
								onChange={field.onChange}
								placeholder=""
							/>
						</FormGroup>
					}
				/>
			</OnboardingContainer>
			<OnboardingFooter>
				<BackForwardButtons />
			</OnboardingFooter>

		</Page>
	);
};

export default CompanyValues;
