import React from 'react';
import classNames from 'classnames';
import { FormGroup, Input } from 'components/core';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { Text } from 'screens/ProductCatalog/components/columns';

interface TableColumnInputProps {
	name: string,
	control: Control<FieldValues, object>;
	row: any;
	editTable: ContactTable | null
	rules?: RegisterOptions;
}

function TableColumnInput({control, row, editTable, name, rules}: TableColumnInputProps) {
	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return (
				<Controller
					key={name}
					control={control}
					defaultValue={row.value || ''}
					name={`specs.${name}`}
					rules={rules}
					render={({ field, fieldState }) => (
						<FormGroup
							field={field}
							fieldState={fieldState}
							className="mt-1 mb-0 pb-0"
							required={true}
						>
							<Input
								id={name}
								name={field.name}
								value={field.value}
								onChange={field.onChange}
								placeholder="Write..."
								error={!!fieldState.error?.message}
								className={classNames('fs-14', !!fieldState.error?.message ? 'custom-input-error' : 'custom-input')}
								style={{
									paddingBlock: 0,
									height: '38px',
								}}
							/>
						</FormGroup>
					)}
				/>
			);
		}
	}

	return <Text>{row.value}</Text>;
}

export default TableColumnInput;
