import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TooltipItem,
	ChartOptions,
	ChartData,
} from 'chart.js';
import { Box, Container, styled, useMediaQuery, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { ConnectingPathsChartProps } from '../../types/interfaces';
import { useEffect, useState } from 'react';
import { isNumber } from 'chart.js/helpers';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

function ConnectingPathsChart(props: ConnectingPathsChartProps) {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [options, setOptions] = useState<ChartOptions<'bar'>>();
	const [data, setData] = useState<ChartData<'bar'>>({
		labels: [],
		datasets: []
	});

	useEffect(() => {
		// Create a continuous range of x values (e.g., from 0 to the maximum x value in your dataset)
		const xMin = 0;
		const xMax = Math.max(...props.dataset.map((data) => data.x));

		// Create an array of x labels from 0 to xMax
		const xLabels = Array.from({ length: xMax - xMin + 1 }, (_, i) => i + xMin);

		setOptions({
			responsive: true,
			plugins: {
				legend: {
					display: false, // Hides the legend
				},
				tooltip: {
					enabled: true,
					callbacks: {
						label: function (context: TooltipItem<'bar'>) {
							const xValue = context.label; // x-axis value (Number of connecting paths)

							// Find the corresponding data from props.dataset.nodes
							const matchingNodes =
		  						props.dataset.find((data) => data.x === Number(xValue))?.nodes ||
		  						[];

							// Customize the tooltip content to display each node on a separate line
							// Create a numeric list for the tooltip
							return matchingNodes.map((node, index) => `${index + 1}. ${node}`);
						},
					},
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: 'Number of connecting paths',
					},
					beginAtZero: true,
					ticks: {
						stepSize: 1, // Ensure all numbers appear, no skipping
						callback: function (value: string | number) {
							return value; // Display the value directly
						},
					},
				},
				y: {
					title: {
						display: true,
						text: 'No. of products',
					},
					beginAtZero: true,
					ticks: {
						// Skip decimals on the y-axis
						callback: function (value: string | number) {
							return isNumber(value) ? Math.floor(value) : value; // Skip decimals on the y-axis
						},
						stepSize: 1, // Control the interval between ticks to avoid overlap
					},
				},
			},
		});

		setData({
			labels: xLabels,
			datasets: [
				{
					label: 'No. of products',
					data: xLabels.map((label) => {
						const matchingData = props.dataset.find((data) => data.x === label);
						return matchingData && matchingData.y ? matchingData.y : 0;
					}),
					backgroundColor: 'rgb(0, 99, 97)',
					borderColor: 'rgba(0, 99, 97, 1)',
					borderWidth: 1,
				},
			],
		});
	}, [props.dataset]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [ChartContainer, setChartContainer] = useState<any | null>(null);

	useEffect(() => {
		setChartContainer(
			styled(Box)(() => ({
				height: 'auto',
				width: matches ? '300px' : '600px',
			}))
		);
	}, [matches]);

	return (
		<Container
			style={{ margin: 'auto', padding: 0, width: '100%', height: '100%' }}
		>
			{ChartContainer && (
				<ChartContainer>
					<Bar data={data} options={options} />
				</ChartContainer>
			)}
		</Container>
	);
}

export default ConnectingPathsChart;
