import React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import H4 from '../headings/h4';
import H5 from '../headings/h5';
import CustomForm from '../forms/CustomForm';
import { FieldConfig, FileInputField, SelectField, PremiumTradeDirectory } from '../../types/interfaces';
import { READ_FILE, READ_PTD } from '../../store/types';
import { StoreContext } from '../../store/context';
import { ReactComponent as Logo } from 'assets/icons/scopematch-collapsed-logo.svg';
import LogoutButton from 'components/scopematch/LogoutButton';
import ptdAPI from '../../api/ptdAPI';
import './Sidebar.scss'; // Import the CSS file
import Header from 'vna/components/header/Header';

function Sidebar() {
	const title = 'VNA Dashboard';
	const [showMobileSidebar, setShowMobileSidebar] = useState(false);
	const store = useContext(StoreContext);
	const [isLoading, setIsLoading] = useState(false);
	const [ptds, setPTDs] = useState<PremiumTradeDirectory[]>([]);

	useEffect(() => {
		const getData = async () => {
			const ptdsResponse = await ptdAPI.GetPremiumTradeDirectories();
			setPTDs(ptdsResponse ?? []);
			setIsLoading(false);
		};

		getData();
	}, []);

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { dispatch } = store;

	const formFields: FieldConfig[] = [
		{
			name: 'select',
			label: 'Select a vendor list',
			validateAtLeastOneAndOnlyOne: true,
			fieldType: {
				type: 'select',
				attributes: {},
				options: [
					{ value: '', label: 'Choose...' },
					...ptds.map((ptd) => ({
						value: ptd.id,
						label: ptd.premiumTradeDirectoryName,
					})),
				],
				className: 'form-select-sm',
			} as SelectField,
		},
		{
			name: 'file_input',
			label: 'Upload file',
			validateAtLeastOneAndOnlyOne: true,
			fieldType: {
				type: 'file',
				attributes: {
					type: 'file',
					accept: '.xlsx, .xls, .csv',
				},
				className: 'form-control-sm',
				templateLink: '/AML-template.xlsx',
			} as FileInputField,
		},
	];

	const handleFormSubmit = useCallback(
		async (data: { select?: number; file_input?: { name: string; values: any[] } }) => {
			setIsLoading(true);

			if (data.select) {
				const ptdResponse = await ptdAPI.getVNAbyPTDs(data.select);

				dispatch({
					type: READ_PTD,
					ptd: {
						name: ptds.find((ptd) => ptd.id === Number(data.select))?.premiumTradeDirectoryName ?? '',
						values: ptdResponse,
					},
				});
			}

			if (data.file_input) {
				dispatch({ type: READ_FILE, fileData: data.file_input });
			}

			setIsLoading(false);
		},
		[dispatch, ptds]
	);

	return (
		<React.Fragment>
			{/* Mobile Sidebar as Modal */}
			<Offcanvas show={showMobileSidebar} onHide={() => setShowMobileSidebar(false)} backdrop="static" className="d-lg-none border-0">
				<Offcanvas.Header closeButton={true}>
					<Offcanvas.Title>
						<H5>{title}</H5>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<CustomForm onSubmit={handleFormSubmit} fields={formFields} isLoadingForm={isLoading} />
				</Offcanvas.Body>

				{/* Footer */}
				<div className="offcanvas-footer d-flex flex-column align-items-center p-3">
					<LogoutButton />
				</div>
			</Offcanvas>

			{/* Static Sidebar for Desktop */}
			<Offcanvas show={true} backdrop={false} placement="start" className="d-none d-lg-flex flex-column border-0">
				<Offcanvas.Header>
					<Offcanvas.Title>
						<div className="d-flex align-items-center">
							<Logo />
							<H4 className="ms-3">{title}</H4>
						</div>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="flex-grow-1">
					<CustomForm onSubmit={handleFormSubmit} fields={formFields} isLoadingForm={isLoading} />
				</Offcanvas.Body>

				{/* Footer */}
				<div className="offcanvas-footer d-flex flex-column align-items-center p-3">
					<LogoutButton />
				</div>
			</Offcanvas>


			<Header setShowMobileSidebar={setShowMobileSidebar} />
		</React.Fragment>
	);
}

export default Sidebar;
