import React, { useState } from 'react';
import { useLoadGraph, useRegisterEvents, useSigma } from '@react-sigma/core';
import Graph from 'graphology';
import { useContext, useEffect, useMemo } from 'react';
import { EventParams, GraphProps } from '../../types/interfaces';
import { READ_GRAPH } from '../../store/types';
import { StoreContext } from '../../store/context';
import { NodeDisplayData } from 'sigma/types';
import '@react-sigma/core/lib/react-sigma.min.css';
import { GRAPH_COLORS } from 'vna/types/enums';
import { calculateNodePositions } from 'utils/calculateNodePositions';

interface SubsetKey {
	Product: string[];
	Manufacturer: string[];
	Location: string[];
	Parent: string[];
}

const bringEdgeToFront = (edge: string, graph: Graph) => {
	let attribs = graph.getEdgeAttributes(edge);
	let source = graph.source(edge);
	let target = graph.target(edge);
	graph.dropEdge(edge);
	graph.addEdgeWithKey(edge, source, target, attribs);
};

// Component that loads the graph
export const LoadGraph = ({ data }: GraphProps) => {
	const store = useContext(StoreContext);
	const loadGraph = useLoadGraph();
	const sigma = useSigma();
	const registerEvents = useRegisterEvents();
	const [highlightedByClick, setHighlightedByClick] = useState<{[x: string]: boolean}>({});

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { dispatch, state } = store;

	// Get the dimensions of the Sigma container
	const containerElement = sigma.getContainer();
	const canvasWidth = containerElement?.offsetWidth || window.innerWidth;
	const canvasHeight = containerElement?.offsetHeight || window.innerHeight;

	// Define thresholds for small, medium, and large data
	const smallDataThreshold = 30;
	const mediumDataThreshold = 100;
	const largeDataThreshold = 500;

	const subsetKey: SubsetKey = useMemo(
		() => ({
			Product: [],
			Manufacturer: [],
			Location: [],
			Parent: [],
		}),
		[]
	);

	// Function to calculate edge size
	const getEdgeSize = (nodeCount: number) => {
		if (nodeCount <= smallDataThreshold) {
			return 0.5;
		}
		if (nodeCount <= mediumDataThreshold) {
			return 0.4;
		}
		if (nodeCount <= largeDataThreshold) {
			return 0.02;
		}
		return 0.01;
	};

	// Memoize the graph generation to avoid re-creating it on each render
	const newGraph = useMemo(() => {
		const graphInstance = new Graph();

		data?.nodes.forEach((group) => {
			const parentNode = `parent-${group.parent}`;
			subsetKey.Parent.push(parentNode);

			// Process child nodes to calculate their positions first
			group.children.forEach((child) => {
				let locationNode, vendorNode, productNode;

				if (child.location) {
					locationNode = `location-${child.location}`;
					subsetKey.Location.push(locationNode);

					if (!graphInstance.hasNode(locationNode)) {
						graphInstance.addNode(locationNode, {
							label: child.location,
							size: 10,
							color: GRAPH_COLORS.location,
							x: 0,
							y: 0,
						});
					}
				}

				if (child.vendor !== '') {
					vendorNode = `vendor-${child.vendor}`;
					subsetKey.Manufacturer.push(vendorNode);

					if (!graphInstance.hasNode(vendorNode)) {
						graphInstance.addNode(vendorNode, {
							label: child.vendor,
							size: 10,
							color: GRAPH_COLORS.vendor,
							x: 0,
							y: 0,
						});
					}
				}

				if (child.product) {
					productNode = `product-${child.product}`;
					subsetKey.Product.push(productNode);

					if (!graphInstance.hasNode(productNode)) {
						graphInstance.addNode(productNode, {
							label: child.product,
							size: 8,
							color: GRAPH_COLORS.product,
							x: 0,
							y: 0,
						});
					}
				}

				// Add edges between nodes
				if (
					graphInstance.hasNode(locationNode) &&
					graphInstance.hasNode(vendorNode)
				) {
					if (!graphInstance.hasEdge(locationNode, vendorNode)) {
						graphInstance.addEdge(locationNode, vendorNode, {
							size: getEdgeSize(group.children.length),
						});
					}
				}

				if (
					graphInstance.hasNode(vendorNode) &&
					graphInstance.hasNode(productNode)
				) {
					if (!graphInstance.hasEdge(vendorNode, productNode)) {
						graphInstance.addEdge(vendorNode, productNode, {
							size: getEdgeSize(group.children.length),
						});
					}
				}
			});

			// Add parent node if it doesn't already exist
			if (!graphInstance.hasNode(parentNode)) {
				graphInstance.addNode(parentNode, {
					label: group.parent,
					size: 15,
					color: GRAPH_COLORS.parent,
					x: 0,
					y: 0,
				});
			}

			// Add edges between parent and unique locations
			const uniqueLocations = new Set(
				group.children
					.filter((child) => child.location && child.location !== '')
					.map((child) => `location-${child.location}`)
			);
			uniqueLocations.forEach((locationNodeId) => {
				if (!graphInstance.hasEdge(parentNode, locationNodeId)) {
					graphInstance.addEdge(parentNode, locationNodeId, {
						size: getEdgeSize(group.children.length),
					});
				}
			});
		});

		// Traverse through nodes and update edge attributes where necessary
		const nodes = graphInstance.nodes();
		nodes.forEach((node) => {
			const neighbors = graphInstance.neighbors(node);

			if (neighbors.length === 1 && node.includes('product-')) {
				const neighbor = neighbors.join();

				// Check if the edge exists before updating
				if (graphInstance.hasEdge(neighbor, node)) {
					graphInstance.updateEdgeAttributes(neighbor, node, (att) => ({
						...att,
						color: GRAPH_COLORS.bridge,
						size: getEdgeSize(nodes.length),
					}));
					const currentEdge = graphInstance.edge(neighbor, node);
					if (currentEdge) {
						bringEdgeToFront(currentEdge, graphInstance);
					}
				}
			}
		});

		return graphInstance;
	}, [
		data?.nodes,
		subsetKey.Parent,
		subsetKey.Location,
		subsetKey.Manufacturer,
		subsetKey.Product,
	]); // Only re-generate the graph if `data`, `canvasWidth`, or `canvasHeight` changes

	useEffect(() => {
		if (newGraph && graphIsDifferent(state.graph, newGraph)) {
			// Only dispatch if the graph content is different
			dispatch({ type: READ_GRAPH, graph: newGraph });
		}
	}, [dispatch, newGraph, state.graph]);

	// Helper function to check if the graph is different
	const graphIsDifferent = (prevGraph: Graph, newGraph: Graph) => {
		// Implement a lightweight comparison between the two graph instances.
		return (
			prevGraph.order !== newGraph.order || prevGraph.size !== newGraph.size
		);
	};

	useEffect(() => {
		// Function to place disconnected nodes
		const placeDisconnectedNodes = (nodes: string[]) => {
			nodes.forEach((node) => {
				subsetKey.Product.push(node);
			});
		};

		loadGraph(newGraph);

		// Identify disconnected nodes
		const disconnectedNodes = sigma
			.getGraph()
			.nodes()
			.filter((nodeId) => {
				return sigma.getGraph().degree(nodeId) === 0; // Degree 0 means disconnected
			});

		// Call the placeDisconnectedNodes function if there are disconnected nodes
		if (disconnectedNodes.length > 0) {
			placeDisconnectedNodes(disconnectedNodes);
		}
	}, [loadGraph, newGraph, sigma, subsetKey.Product]);

	useEffect(() => {
		if (!newGraph) {
			return;
		} // Ensure newGraph is defined before proceeding
		const { positions, nodeSize } = calculateNodePositions(
			newGraph,
			subsetKey,
			{
				canvasWidth,
				canvasHeight,
			}
		);

		// Update the Sigma graph with new positions
		for (const [nodeId, pos] of Object.entries(positions)) {
			// Use the appropriate method to set node positions
			sigma.getGraph().updateNode(nodeId, (attr) => ({
				...attr, // Spread the existing attributes
				x: pos.x, // Update the x position
				y: pos.y, // Update the y position
				size: nodeSize,
			}));
		}

		sigma.refresh();
	}, [canvasHeight, canvasWidth, newGraph, sigma, subsetKey]);

	useEffect(() => {
		sigma.setSetting(
			'nodeReducer',
			(node: string, data): Partial<NodeDisplayData> => {
				const hoveredNode = sigma
					.getGraph()
					.getNodeAttribute(node, 'highlighted');
				return {
					...data,
					label: hoveredNode ? data.label : '',
				};
			},
		);

		sigma.setSetting('edgeReducer',
			(edge, data) => {
				if (data.highlighted) {
				  return {
						...data,
						color: 'black',
						size: data.size + 2, // Increase size for highlighted edges
				  };
				}
				return data;
			});

		registerEvents({
			enterNode: ({ node }: EventParams) => {
				sigma.getGraph().setNodeAttribute(node, 'highlighted', true);
			},
			leaveNode: ({ node }: EventParams) => {
				highlightedByClick[node] || sigma.getGraph().setNodeAttribute(node, 'highlighted', false);
			},
			clickNode: ({ node }: EventParams) => {
				setHighlightedByClick(highlights => {
					highlights[node] = true;
					return highlights;
				});
				// Reset previous highlights
				sigma.getGraph().forEachNode((e) => {
					sigma.getGraph().setNodeAttribute(e, 'highlighted', false);
				});
				sigma.getGraph().forEachEdge((e) => {
					sigma.getGraph().setEdgeAttribute(e, 'highlighted', false);
				});

				// Highlight the clicked node
				sigma.getGraph().setNodeAttribute(node, 'highlighted', true);

				// Get all connected edges and nodes at both ends
				const edges = sigma.getGraph().edges(node);
				edges.forEach((edge) => {
				  // Highlight the edge itself
				  sigma.getGraph().setEdgeAttribute(edge, 'highlighted', true);
				  bringEdgeToFront(edge, sigma.getGraph());
				});
			},
			// Handle click on the stage (background click)
			clickStage: () => {
				// Clear all highlights when the user clicks on the background
				sigma.getGraph().forEachNode((n) => {
					sigma.getGraph().setNodeAttribute(n, 'highlighted', false);
				});
				sigma.getGraph().forEachEdge((e) => {
					sigma.getGraph().setEdgeAttribute(e, 'highlighted', false);
				});

				// Reset highlightedByClick state
				setHighlightedByClick({});
			}
		});
	}, [highlightedByClick, registerEvents, sigma]);

	return null;
};
