import React, { createContext, useContext, ReactNode } from 'react';
import {
	useUserOrganizationsStore,
	UserOrganization,
} from './UserOrganizationsStore';

interface UserOrganizationsContextType {
	organizations: UserOrganization[] | null;
	loading: boolean;
	error: string | null;
	updateOrganization: (data: any, organizationId: number) => Promise<void>;
	deleteOrganization: (organizationId: number) => Promise<void>;
}

const UserOrganizationsContext = createContext<
	UserOrganizationsContextType | undefined
>(undefined);

export const UserOrganizationsProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const {
		organizations,
		loading,
		error,
		updateOrganization,
		deleteOrganization,
	} = useUserOrganizationsStore();

	return (
		<UserOrganizationsContext.Provider
			value={{
				organizations,
				loading,
				error,
				updateOrganization,
				deleteOrganization,
			}}
		>
			{children}
		</UserOrganizationsContext.Provider>
	);
};

export const useUserOrganizations = () => {
	const context = useContext(UserOrganizationsContext);
	if (!context) {
		throw new Error(
			'useUserOrganizations must be used within a UserOrganizationsProvider'
		);
	}
	return context;
};
