import React from 'react';
import { TooltipItem } from 'chart.js';
import Accordion from 'vna/components/accordion/Accordion';
import BarChartMUI from 'vna/components/charts/BarChartMUI';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { AccordionProductsPerCountryProps, DynamicTableCell } from 'vna/types/interfaces';

function AccordionProductsPerCountry({productsPerCountry, productsPerCountryArray}: AccordionProductsPerCountryProps) {
	const productsPerCountryColumns: readonly DynamicTableCell[] = [
		{
			id: 'country',
			numeric: false,
			disablePadding: true,
			label: 'Country',
		},
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
	];

	const productsPerCountryFormatter = (context: TooltipItem<'bar'>) => {
		const country = productsPerCountryArray[context.dataIndex]?.country;
		const products = Array.from(productsPerCountry[country] || []).map((product) => product);

		return (
			products.map((value) => value) ||
			context.label ||
			''
		);
	};

	return (
		<Accordion label="No. of products per country">
			<div className="row gx-4">
				<div className="col-12 col-md-6">
					<BarChartMUI
						dataset={productsPerCountryArray.map((item) => ({
							x: item.country,
							y: item.product as number,
						}))}
						xLabel="Countries"
						yLabel="Products"
						yFullNumbers={true}
						valueFormatter={productsPerCountryFormatter}
					/>
				</div>

				<div className="col-12 col-md-6 mt-2">
					<EnhancedTable
						rows={productsPerCountryArray}
						columns={productsPerCountryColumns}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionProductsPerCountry;
