import Graph from 'graphology';

interface SubsetKey {
    Product: string[];
    Manufacturer: string[];
    Location: string[];
    Parent: string[];
}

interface NodePosition {
    x: number;
    y: number;
}

interface Positions {
    [nodeId: string]: NodePosition;
}

// Define a return type for the calculateNodePositions function
interface NodePositionResult {
    positions: Positions; // Positions of nodes
    nodeSize: number;     // Size of the nodes
}

interface CanvasSize {
	canvasWidth: number,
	canvasHeight: number
}

export const calculateNodePositions = (graph: Graph, subsetKey: SubsetKey, canvasSize: CanvasSize): NodePositionResult => {
	const positions: Positions = {};
	const nodes = graph.nodes();
	const nodeCount = nodes.length;

	// Define thresholds for small, medium, and large data
	const smallDataThreshold = 30;
	const mediumDataThreshold = 100;
	const largeDataThreshold = 500;

	// Define layer maximum nodes and layer spacing
	const layerMaxNodes = nodeCount <= smallDataThreshold ? 30 : nodeCount <= mediumDataThreshold ? 20 : nodeCount <= largeDataThreshold ? 50 : 60;
	// Calculate spacing based on canvas size with increased horizontal separation
	const layerYSpacing = canvasSize.canvasHeight / (nodeCount <= smallDataThreshold ? 5 : nodeCount <= mediumDataThreshold ? 2 : nodeCount <= largeDataThreshold ? 13 : 16);


	// Function to calculate dynamic horizontal spacing based on the number of nodes in the layer
	const calculateLayerXSpacing = (layerNodeCount: number, canvasWidth: number) => {
		const baseSpacing = canvasWidth / layerMaxNodes;
		const nodeFactor = layerNodeCount < 10 ? 3 : layerNodeCount < 20 ? 2 : 1.5; // Adjust this as needed
		return baseSpacing * nodeFactor; // Increase spacing for layers with fewer nodes
	};
	// Function to calculate node size
	const getNodeSize = (nodeCount: number) => {
		if (nodeCount <= smallDataThreshold) {return 10;}
		if (nodeCount <= mediumDataThreshold) {return 7;}
		if (nodeCount <= largeDataThreshold) {return 4;}
		return 2;
	};


	// Layer arrays for different types
	const productLayers: string[][] = [];
	const manufacturerLayers: string[][] = [];
	const locationLayers: string[][] = [];
	const parentLayers: string[][] = [];

	const bridgeProductLayer: string[] = [];
	const nonBridgeProductLayer: string[] = [];

	// Counters for each type
	let manufacturerCount = 0;
	let locationCount = 0;
	let parentCount = 0;

	// Categorize nodes and create layers
	for (const node of nodes) {
		const nodeDegree = graph.degree(node); // Get the degree of the node (number of connections)

		// Handle product nodes
		if (subsetKey.Product.includes(node)) {
			if (nodeDegree === 1) {
				bridgeProductLayer.push(node);
			} else {
				nonBridgeProductLayer.push(node);
			}
		} else if (subsetKey.Manufacturer.includes(node)) {
			if (manufacturerCount % layerMaxNodes === 0) {manufacturerLayers.push([]);}
			manufacturerLayers[manufacturerLayers.length - 1].push(node);
			manufacturerCount++;
		} else if (subsetKey.Location.includes(node)) {
			if (locationCount % layerMaxNodes === 0) {locationLayers.push([]);}
			locationLayers[locationLayers.length - 1].push(node);
			locationCount++;
		} else if (subsetKey.Parent.includes(node)) {
			if (parentCount % layerMaxNodes === 0) {parentLayers.push([]);}
			parentLayers[parentLayers.length - 1].push(node);
			parentCount++;
		}
	}

	 // Handle product nodes: fill layers with bridge nodes first, then non-bridge nodes
	 const totalProductNodes = [...bridgeProductLayer, ...nonBridgeProductLayer];
	 while (totalProductNodes.length > 0) {
		 const layer: string[] = totalProductNodes.splice(0, layerMaxNodes); // Take up to layerMaxNodes for each layer
		 productLayers.push(layer); // Add the layer to the product layers
	 }

	const typeSpacingMultiplier = 2; // Multiplier to increase the space between different types

	let layerYIndex = 1;

	// Function to set positions for layers with increased spacing
	const setPositionForLayer = (layer: string[], additionalSpacing: number) => {
		const layerNodeCount = layer.length;
		const layerXSpacing = calculateLayerXSpacing(layerNodeCount, canvasSize.canvasWidth); // Calculate spacing for this layer

		layer.forEach((node, index) => {
			const pos: NodePosition = {
				x: (index + 1) * layerXSpacing, // Use the increased horizontal spacing here
				y: layerYIndex * (layerYSpacing + additionalSpacing) // Apply vertical spacing
			};
			positions[node] = pos;
		});
		layerYIndex++;
	};

	// Position layers based on type with added spacing
	[locationLayers, manufacturerLayers, productLayers].forEach(layers => {
		const additionalSpacing = nodeCount > mediumDataThreshold ? layerYSpacing * typeSpacingMultiplier : 0; // Add extra spacing between different types on big graphs
		layers.forEach(layer => setPositionForLayer(layer, additionalSpacing));
	});


	// Parent nodes are positioned in the center
	parentLayers.forEach(layer => {
		layer.forEach(node => {
			const minX = Math.min(...Object.values(positions).map(pos => pos.x));
			const maxX = Math.max(...Object.values(positions).map(pos => pos.x));
			const parentX = (minX + maxX) / 2;
			const pos: NodePosition = {
				x: parentX,
				y: 0
			};
			positions[node] = pos;
		});
	});

	 // Find the largest layer by its width (distance between the first and last node in the x-axis)
	 const layers = [locationLayers, manufacturerLayers, productLayers];
	 let largestLayerWidth = 0;
	 let largestLayerCenterX = 0;

	 layers.forEach(layersGroup => {
		 layersGroup.forEach(layer => {
			 const layerMinX = Math.min(...layer.map(node => positions[node].x));
			 const layerMaxX = Math.max(...layer.map(node => positions[node].x));
			 const layerWidth = layerMaxX - layerMinX;
			 if (layerWidth > largestLayerWidth) {
				 largestLayerWidth = layerWidth;
				 largestLayerCenterX = layerMinX + layerWidth / 2;
			 }
		 });
	 });

	 // Now center all layers based on the largest layer's center
	 layers.forEach(layersGroup => {
		 layersGroup.forEach(layer => {
			 const layerMinX = Math.min(...layer.map(node => positions[node].x));
			 const layerMaxX = Math.max(...layer.map(node => positions[node].x));
			 const layerCenterX = layerMinX + (layerMaxX - layerMinX) / 2;
			 const shiftX = largestLayerCenterX - layerCenterX;

			 layer.forEach(node => {
				 positions[node].x += shiftX; // Shift each node by the difference
			 });
		 });
	 });

	// Return the calculated positions and the sizes for nodes and edges
	return {
		positions,
		nodeSize: getNodeSize(nodeCount),
	};
};


