import { ModalOverlay } from 'components/core';
import React from 'react';

interface IProps {
	item: { id: number, name: string } | null;
	onRequestClose: () => void;
	deleteRow: (val: { id: number, name: string }) => Promise<boolean>;
	labels?: {
		title?: string,
		description?: string,
	}
}

const DeleteModal = (props: IProps) => {
	const { item, onRequestClose, deleteRow } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	return (
		<ModalOverlay
			isOpen={item !== null}
			modalSize="modal-dialog-centered"
			onRequestClose={onRequestClose}
			confirmButtonChildren="Delete"
			cancelButtonChildren="Cancel"
			confirmButtonAction={deleteAndClose}
			cancelButtonAction={onRequestClose}
			isLoading={isLoading}
			hideCloseButton={true}
		>
			<h3>{props.labels?.title || 'Are you sure you want to delete this?'}</h3>
			<p>{props.labels?.description || 'This action cannot be undone.'}</p>
		</ModalOverlay>

	);
	async function deleteAndClose() {
		if (item !== null) {
			setIsLoading(true);
			await deleteRow(item);
			setIsLoading(false);
			onRequestClose();
		}
	}

};

export default DeleteModal;