import React, { useEffect, useState } from 'react';
import { Box, Container, styled } from '@mui/material';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	Legend,
	LinearScale,
	Title,
	Tooltip,
	TooltipItem,
} from 'chart.js';
import { isNumber } from 'chart.js/helpers';
import { Bar } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

function BarChartMUI({
	dataset,
	xLabel,
	xFullNumbers,
	yLabel,
	yFullNumbers,
	valueFormatter,
}: {
	dataset: { x: string | number; y: number }[];
	xLabel: string;
	xFullNumbers?: boolean;
	yLabel: string;
	yFullNumbers?: boolean;
	valueFormatter: (context: TooltipItem<'bar'>) => string | string[];
}) {
	// const theme = useTheme();
	// State to manage animation trigger
	const [animate, setAnimate] = useState(false);
	const [chartKey, setChartKey] = useState(0); // Key prop to force re-render

	// Media queries for different screen sizes
	// const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	// const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	// const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

	// Adjust the chart container dimensions based on screen size
	let chartHeight = '100%';
	let chartWidth = '100%';

	// Use useEffect to set the animation trigger when component mounts
	// useEffect(() => {
	// 	setAnimate(true);
	// 	setChartKey((prevKey) => prevKey + 1); // Change key to force re-render
	// }, []);

	// Use useEffect to set the animation trigger when the dataset changes
	useEffect(() => {
		setAnimate(true);
		setChartKey((prevKey) => prevKey + 1); // Change key to force re-render
		const timer = setTimeout(() => setAnimate(false), 1000); // Reset animation after duration
		return () => clearTimeout(timer); // Cleanup on unmount
	}, [dataset]); // Trigger when dataset changes

	const options: ChartOptions<'bar'> = {
		responsive: true,
		maintainAspectRatio: false, // Ensures the chart adapts to the container's dimensions
		plugins: {
			legend: {
				display: false, // Hides the legend
			},
			tooltip: {
				enabled: true,
				callbacks: {
					label: valueFormatter,
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: xLabel,
					font: {
						size: 14, // Set font size for the x-axis title
						weight: 700, // Make the x-axis title bold
					},
				},
				beginAtZero: true,
				min: 0,
				ticks: xFullNumbers
					? {
						stepSize: 1, // Ensure all numbers appear, no skipping
						callback: function (value: string | number) {
							return value; // Display the value directly
						},
					}
					: {
						callback: function(value) {
							const label = this.getLabelForValue(value as number);
							// Truncate the label if it's too long (e.g., more than 10 characters)
							return label.length > 10 ? label.substring(0, 10) + '...' : label;
						}
					}
			},
			y: {
				title: {
					display: true,
					text: yLabel,
					font: {
						size: 14, // Set font size for the x-axis title
						weight: 700, // Make the x-axis title bold
					},
				},
				beginAtZero: true,
				ticks: yFullNumbers
					? {
						// Skip decimals on the y-axis
						callback: function (value: string | number) {
							return isNumber(value) ? Math.floor(value) : value; // Skip decimals on the y-axis
						},
						stepSize: 1, // Control the interval between ticks to avoid overlap
					  }
					: {},
			},
		},
		animation: {
			duration: animate ? 1000 : 0, // Animate only when animate is true
			easing: 'easeOutBounce',
		},
		// Animation settings for bars to come from the bottom
		// animation: {
		// 	easing: 'easeOutBounce', // Correct easing type directly
		// 	duration: 1000, // Customize animation duration
		// },
		// animations: {
		// 	tension: {
		// 	  duration: 1000, // Animation duration in milliseconds
		// 	  easing: 'linear', // Easing function (default is 'easeOutQuart')
		// 	  from: 1,
		// 	  to: 0,
		// 	  loop: true
		// 	}
		// }
	};

	const data = {
		datasets: [
			{
				label: xLabel,
				data: dataset,
				backgroundColor: 'rgb(0, 99, 97)',
				borderColor: 'rgba(0, 99, 97, 1)',
				borderWidth: 1,
			},
		],
	};

	const ChartContainer = styled(Box)(() => ({
		height: chartHeight, // Set height dynamically
		width: chartWidth, // Set width dynamically
		margin: '0 auto', // Center the chart horizontally
		padding: 0
	}));

	return (
		<Container
			style={{
				margin: 'auto',
				width: '100%',
				height: '100%',
				padding: 0
			}}
		>
			{ChartContainer && (
				<ChartContainer>
					<Bar key={chartKey} data={data} options={options} />
				</ChartContainer>
			)}
		</Container>
	);
}

export default BarChartMUI;
