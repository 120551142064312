import MultiOrgAPIs from 'api/MultiOrgAPI';
import { useReducer, useEffect } from 'react';

export interface UserOrganization {
	organizationId: number;
	organizationName: string;
}

interface State {
	organizations: UserOrganization[] | null;
	loading: boolean;
	error: string | null;
}

type Action =
	| { type: 'FETCH_START' }
	| { type: 'FETCH_SUCCESS'; payload: UserOrganization[] }
	| { type: 'FETCH_ERROR'; payload: string }
	| { type: 'UPDATE_ORGANIZATION'; payload: UserOrganization }
	| { type: 'DELETE_ORGANIZATION'; payload: number };

const initialState: State = {
	organizations: null,
	loading: false,
	error: null,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
	case 'FETCH_START':
		return { ...state, loading: true, error: null };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false, organizations: action.payload };
	case 'FETCH_ERROR':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_ORGANIZATION':
		return {
			...state,
			organizations: state.organizations?.map((org) =>
				org.organizationId === action.payload.organizationId ? action.payload : org
			) || null,
		};
	case 'DELETE_ORGANIZATION':
		return {
			...state,
			organizations: state.organizations?.filter((org) => org.organizationId !== action.payload) || null,
		};
	default:
		return state;
	}
}

export const useUserOrganizationsStore = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const fetchOrganizations = async () => {
		dispatch({ type: 'FETCH_START' });
		const data = await MultiOrgAPIs.GetUserOrganizations();
		if (data) {
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
		} else {
			dispatch({ type: 'FETCH_ERROR', payload: 'Failed to fetch organizations' });
		}
	};

	const updateOrganization = async (data: any, organizationId: number) => {
		const updatedOrg = await MultiOrgAPIs.UpdateUserOrganization(data, organizationId);
		if (updatedOrg) {
			dispatch({ type: 'UPDATE_ORGANIZATION', payload: updatedOrg });
		}
	};

	const deleteOrganization = async (organizationId: number) => {
		const message = await MultiOrgAPIs.DeleteUserOrganization(organizationId);
		if (message) {
			dispatch({ type: 'DELETE_ORGANIZATION', payload: organizationId });
		}
	};

	useEffect(() => {
		fetchOrganizations();
	}, []);

	return { ...state, updateOrganization, deleteOrganization };
};
