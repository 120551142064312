import React, { useContext } from 'react';
import { IconAdd, IconEdit, IconDelete } from 'assets/icons';
import { DataTable, Button, LoadingPage } from 'components/core';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { usePTD } from 'context/PTD/PTDContext';
import PTDAPIs from 'api/PTDAPI';
import { toast } from 'react-toastify';
import TableColumnVendorName from 'components/tableColumns/TableColumnVendorName';
import TableColumnOperatingLocation from 'components/tableColumns/TableColumnOperatingLocation';
import TableColumnNotes from 'components/tableColumns/TableColumnNotes';
import TableColumnUserDefinedStatus from 'components/tableColumns/TableColumnUserDefinedStatus';
import TableColumnValidedRowStatus from 'components/tableColumns/TableColumnValidedRowStatus';
import ActionCell from 'screens/PremiumTradeDirectory/components/views/ActionCell';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';
import { getEnumFromVendorStatus } from 'utils/utils';
import PickListContext from 'context/PickList/PickListContext';

interface PremiumTradeDirectoryListByProductProps {
	id: string | undefined;
	vendorFormMethods: UseFormReturn<any, object>;
	idPTDList: number;
	canEdit: boolean | null | undefined;
	setAddProductModal: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			product?: PTDProduct;
		}>
	>;
	setDeleteModal: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			idPTDProduct: number | undefined;
			idPTDVendor: number | undefined;
			label: string;
			isDeleting: 'product' | 'vendor' | 'both' | undefined;
		}>
	>;
	productSpecsList: ProductTypeDTO[] | undefined;
}

function PremiumTradeDirectoryListByProduct({
	id,
	vendorFormMethods,
	idPTDList,
	canEdit,
	setAddProductModal,
	setDeleteModal,
	productSpecsList,
}: PremiumTradeDirectoryListByProductProps) {
	// Get PTD
	const {
		getOrganizationList,
		getPTDProducts,
		isInitializingPTDProducts,
	} = usePTD();
	const {
		getPTDVendors,
		addPTDVendor,
		updatePTDVendor,
		isInitializingPTDVendors,
	} = usePTDVendors();
	const PickListCxt = useContext(PickListContext);

	const PTDProducts = getPTDProducts();
	const PTDVendors = getPTDVendors();
	const organizationList = getOrganizationList();

	const [editTable, setEditTable] = React.useState<AssociativeTable | null>(
		null
	);
	const [isSavingRow, setIsSavingRow] = React.useState<boolean>(false);
	const isMounted = React.useRef(true);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false; // cleanup on unmount
		};
	}, []);

	/// Add Vendor
	function onClickEditOrDuplicate(
		row: any,
		type: 'edit' | 'duplicate',
		productID: number
	) {
		const keys = Object.keys(row.original);
		const nonEditable = ['validated'];
		nonEditable.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			vendorFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			const newTable: AssociativeTable = {};
			newTable[productID] = {
				rowId: row.index,
				manufecId: row.original.id,
			};
			setEditTable(newTable);
		} else {
			const newTable: AssociativeTable = {};
			newTable[productID] = {
				rowId: 0,
				manufecId: 0,
				duplicateRow: row.original.id,
			};
			setEditTable(newTable);
		}
	}

	function closeVendorAddRow() {
		setEditTable(null);
		vendorFormMethods.reset(
			{
				specs: {
					name: '',
					vendorName: '',
					operatingLocation: '',
					notes: '',
					userDefinedStatus: 0,
					validated: '',
				},
			},
			{ keepDirty: false, keepValues: false }
		);
	}

	async function submitVendorForm(
		fields: FieldValues,
		idPTDProduct: number,
		idPTDVendor: number
	) {
		const vendorData: PTDVendor = { ...fields.specs };

		if (!!editTable) {
			const productID = idPTDProduct as keyof typeof editTable;
			const currentTable = editTable[productID] as VendorTable;

			if (currentTable) {
				setIsSavingRow(true);

				// set up request data
				const parts = vendorData.operatingLocation
					.split(',')
					.map((part) => part.trim());
				const city = parts[0];
				const region = parts.length >= 3 ? parts[parts.length - 2] : null;
				const country = parts[parts.length - 1];
				const vendor = {
					...vendorData,
					productID,
					vendorName: organizationList?.find(
						(org) => org.id === vendorData.vendorId
					)?.organizationName,
					city: city,
					region: region,
					country: country,
				};

				try {
					const updateVendor: PTDVendorPutRequest = {
						...vendor,
						userDefinedStatus: getEnumFromVendorStatus(vendor.userDefinedStatus),
					};

					if (currentTable.newLine || currentTable.duplicateRow) {
						updateVendor.id = undefined;
					} else {
						updateVendor.id = idPTDVendor;
					}

					const response: PTDVendor | null =
							await PTDAPIs.premiumTradeDirectories.UpdateVendor(updateVendor);

					if (response && isMounted.current) {
						if (currentTable.newLine || currentTable.duplicateRow) {
							addPTDVendor(response, idPTDProduct);
							toast.success('Vendor was added!');
						} else {
							updatePTDVendor(response, idPTDProduct);
							toast.success('Vendor was updated!');
						}
					}
				} catch (error) {
					if (isMounted.current) {
						toast.error('Failed to save vendor');
					}
				} finally {
					if (isMounted.current) {
						setIsSavingRow(false);
						setEditTable(null);
						vendorFormMethods.reset(
							{},
							{ keepDirty: false, keepValues: false }
						);
					}
				}
			}
		}
	}

	if (isInitializingPTDProducts === true) {
		return <LoadingPage />;
	}

	return (
		<React.Fragment>
			{PTDProducts?.map((product, key) => {
				const currentProdSpec: ProductSpecList[] = product.productSpecList.filter(
					(s) => s.pickListItemName
				);

				const prodSpecs = productSpecsList?.find((p) => p.productTypePickListItemId === product.productsTypePickListItemId)?.productSpecCodes || [];

				if (currentProdSpec.length < prodSpecs.length) {
					prodSpecs.forEach((spec, index) => {
						const pickListType = PickListCxt.pickListTypes.find(
							(x) => x.code === spec.productSpecCode
					 	);

						if (!currentProdSpec.find((s) => s.speckListItemId === pickListType?.id)) {
							currentProdSpec.splice(index, 0, {
								pickListItemName: `${spec.productSpecDescription}: Any`,
								id: 0,
								speckListItemId: spec.productSpecPickListItemId || 0,
								speckListItemName: spec.productSpecValue || '',
								pickListItemId: 0
							});
						}
					});
				}

				const listVendors = PTDVendors[product.id]
					? [...PTDVendors[product.id]]
					: [];
				const productID = product.id as keyof typeof editTable;
				const currentTable = !!editTable
					? (editTable[productID] as VendorTable)
					: null;

				// new row start
				const newRow: PTDVendor = {
					id: 0,
					vendorName: '',
					operatingLocation: '',
					notes: '',
					productId: 0,
					validatedRowStatus: 0,
					userDefinedStatus: ''
				};

				if (currentTable?.newLine === true) {
					listVendors.unshift(newRow);
				}
				// new row end

				/// duplicated row start
				if (!!currentTable?.duplicateRow) {
					const row = listVendors.find((x) => {
						return x.id === currentTable.duplicateRow;
					});
					const lineItem: any = { ...row };

					lineItem['id'] = 'duplicate';

					listVendors.unshift(lineItem);
				}
				/// duplicated row end

				const columns = [
					{
						Header: 'Vendor',
						accessor: 'vendorName',
						filterable: true,
						Cell: (row: any) => {
							return (
								<TableColumnVendorName
									id={id}
									row={row}
									vendorFormMethods={vendorFormMethods}
									editTable={editTable && (editTable[productID] as VendorTable)}
								/>
							);
						},
					},
					{
						Header: 'Location',
						accessor: 'operatingLocation',
						filterable: true,
						Cell: (row: any) => {
							return (
								<TableColumnOperatingLocation
									row={row}
									vendorFormMethods={vendorFormMethods}
									editTable={editTable && (editTable[productID] as VendorTable)}
								/>
							);
						},
					},
					{
						Header: 'Status',
						accessor: 'userDefinedStatus',
						filterable: true,
						Cell: (row: any) => {
							return (
								<TableColumnUserDefinedStatus
									row={row}
									vendorFormMethods={vendorFormMethods}
									editTable={editTable && (editTable[productID] as VendorTable)}
								/>
							);
						},
					},
					{
						Header: 'Notes',
						accessor: 'notes',
						filterable: true,
						Cell: (row: any) => {
							return (
								<TableColumnNotes
									row={row}
									vendorFormMethods={vendorFormMethods}
									editTable={editTable && (editTable[productID] as VendorTable)}
								/>
							);
						},
					},
					{
						Header: 'Validated',
						accessor: 'validatedRowStatus',
						filterable: false,
						className: 'text-center validated-column-padding',
						headerClassName: 'text-center',
						Cell: (row: any) => {
							return (
								<TableColumnValidedRowStatus
									row={row}
									vendorFormMethods={vendorFormMethods}
									editTable={editTable && (editTable[productID] as VendorTable)}
								/>
							);
						},
					},
					{
						accessor: 'id',
						show: false,
					},
					{
						Header: '',
						accessor: 'actions',
						filterable: false,
						sortable: false,
						className: 'text-center pt-1',
						Cell: (row: any) => {
							const currentTable = !!editTable
								? (editTable[productID] as VendorTable)
								: null;

							const rowIsDisabled =
								!!currentTable && currentTable.rowId !== row.index;
							const isEditingCurrentRow =
								(!!currentTable && currentTable.rowId === row.index) ||
								(!!currentTable?.duplicateRow && row.index === 0);

							return (
								<ActionCell
									formMethods={vendorFormMethods}
									idPTDList={idPTDList}
									isEditingCurrentRow={isEditingCurrentRow}
									rowIsDisabled={rowIsDisabled}
									submitForm={(fields) =>
										submitVendorForm(fields, productID, row.original.id)
									}
									closeAddRow={closeVendorAddRow}
									onClickEditOrDuplicate={(action: 'edit' | 'duplicate') =>
										onClickEditOrDuplicate(row, action, productID)
									}
									openDeleteModal={() =>
										setDeleteModal({
											isOpen: true,
											idPTDProduct: productID,
											idPTDVendor: row.original.id,
											label: 'Are you sure you want to delete this vendor?',
											isDeleting: 'vendor',
										})
									}
									isSavingRow={isSavingRow}
								/>
							);
						},
					},
				];

				return (
					<div className={'row mt-4'} key={`product-${product.id}-${key}`}>
						<div className="col-12">
							<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
								<h4 className="text-dark mb-0">
									{currentProdSpec.length > 0
										? product.name?.concat(
											' | ',
											currentProdSpec
												.map((r) => r.pickListItemName)
												.join(' | ')
										  )
										: product.name}
								</h4>

								{canEdit ? (
									<React.Fragment>
										<Button
											onClick={() => {
												vendorFormMethods.reset(
													{},
													{ keepValues: false, keepDirty: false }
												);
												const newTable: AssociativeTable = {};
												newTable[productID] = {
													rowId: 0,
													newLine: true,
												};
												setEditTable(newTable);
											}}
											disabled={!!editTable && !!editTable[productID]}
											className="btn-hover ms-auto text-primary py-0 fs-14 fw-normal"
										>
											<IconAdd className="me-2 svg-20" />
											Add Vendor
										</Button>
										<Button
											onClick={() => {
												setAddProductModal({
													isOpen: true,
													product: { ...product },
												});
											}}
											className="btn-hover text-primary py-0 fs-14 fw-normal"
										>
											<IconEdit className="me-2 svg-20" /> Edit Product
										</Button>
										<Button
											onClick={() => {
												setDeleteModal({
													isOpen: true,
													idPTDProduct: productID,
													idPTDVendor: undefined,
													isDeleting: 'product',
													label:
														'Are you sure you want to delete this product?',
												});
											}}
											className="btn-hover text-primary py-0 fs-14 fw-normal"
										>
											<IconDelete className="me-2 svg-20" /> Delete Product
										</Button>
									</React.Fragment>
								) : null}
							</div>

							<DataTable
								data={listVendors}
								columns={columns}
								filterable={true}
								sortable={true}
								resizable={true}
								noDataText="No data found."
								loading={isInitializingPTDVendors}
							/>
						</div>
					</div>
				);
			})}
		</React.Fragment>
	);
}

export default PremiumTradeDirectoryListByProduct;
