import React, { PropsWithChildren } from 'react';
import { AccordionProps } from '../../types/interfaces';
import AccordionContent from './AccordionContent';
import AccordionHeader from './AccordionHeader';

function Accordion({defaultOpen, label, children, onClick}: PropsWithChildren<AccordionProps>) {
	const accordionIndex = label.replaceAll(' ', '-');

	return (
		<div className="accordion my-5" id={`accordion-${accordionIndex}`}>
			<div className="accordion-item">
				<AccordionHeader openAccordion={defaultOpen} accordionIndex={accordionIndex} onClick={onClick}>
					{ label }
				</AccordionHeader>
				<AccordionContent accordionIndex={accordionIndex}>
					{ children }
				</AccordionContent>
			</div>
		</div>
	);
}

export default Accordion;
