import { useForm, Controller } from 'react-hook-form';
import CustomInput from './CustomInput';
import { CustomFormData, CustomFormProps } from '../../types/interfaces';
import CustomSelect from './CustomSelect';
import CustomFileInput from './CustomFileInput';
import { useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Button } from 'components/core';
import { DELETE_FILE, DELETE_PTD } from 'vna/store/types';
import { StoreContext } from 'vna/store/context';

const ERROR_MESSAGES = {
	ONLY_ONE_REQUIRED: 'Only one field can be filled.',
	ONE_FIELD_REQUIRED: 'At least one field must be filled.',
};

const CustomForm = ({
	onSubmit,
	fields,
	isLoadingForm,
	submitLabel,
}: CustomFormProps) => {
	const store = useContext(StoreContext);
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		trigger, // Add trigger function to trigger validation on change
	} = useForm<CustomFormData>({
		mode: 'onChange', // This will trigger validation on every change
	});
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingReset, setIsLoadingReset] = useState(false);
	// Ref for file input reset
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const selectRef = useRef<HTMLSelectElement | null>(null);

	if (!store) {
		throw new Error('StoreContext is not available');
	}

	const { dispatch } = store;

	useEffect(() => {
		setIsLoading(isLoadingForm);
	}, [isLoadingForm]);

	// Custom validation: ensure at least one field is filled and only one
	const validateAtLeastOneAndOnlyOne = (value: unknown) => {
		// Watch all fields to check if only one has been filled
		const fieldValues = watch(fields.map((field) => field.name));

		const filledFields = fieldValues.filter((val) => val); // Count fields that have values
		const isCurrentFieldFilled = !!value;

		// Check if only one field is filled
		if (filledFields.length === 0) {
			return ERROR_MESSAGES.ONE_FIELD_REQUIRED;
		} else if (filledFields.length > 1 && isCurrentFieldFilled) {
			return ERROR_MESSAGES.ONLY_ONE_REQUIRED;
		}

		return true;
	};

	const handleFieldChange = async (fieldName: string) => {
		await trigger(fieldName); // Validate the field that was just changed
	};

	const onFormSubmit = (data: CustomFormData) => {
		onSubmit(data);
	};

	const onResetForm = () => {
		setIsLoadingReset(true);
		dispatch({
			type: DELETE_PTD,
		});
		dispatch({
			type: DELETE_FILE,
		});
		// Reset the form
		reset();
		// Manually reset the file input
		if (fileInputRef.current) {
			fileInputRef.current.value = ''; // This clears the file input
		}
		// Manually reset the select input
		if (selectRef.current) {
			selectRef.current.value = ''; // This clears the select input
		}
		setIsLoadingReset(false);
	};

	return (
		<form
			onSubmit={handleSubmit(onFormSubmit)}
			className="container mx-auto mb-3"
		>
			{fields.map((field, index) => (
				<React.Fragment key={field.name}>
					<div className="py-2">
						<Controller
							name={field.name}
							control={control}
							rules={{
								...field.rules,
								validate: (value) => field.validateAtLeastOneAndOnlyOne && validateAtLeastOneAndOnlyOne(value),
							}}
							render={({ field: { value, onChange } }) =>
								field.fieldType.type === 'input' ? (
									<CustomInput
										value={value as string | number}
										attributes={field.fieldType.attributes}
										className={field.fieldType.className}
										onChange={(e) => {
											onChange(e);
											handleFieldChange(field.name);
										}}
										label={field.label}
										error={errors[field.name as keyof CustomFormData]?.message}
									/>
								) : field.fieldType.type === 'select' ? (
									<CustomSelect
										ref={selectRef}
										value={value as string | number}
										attributes={field.fieldType.attributes}
										className={field.fieldType.className}
										options={field.fieldType.options}
										onChange={(e) => {
											onChange(e);
											handleFieldChange(field.name);
										}}
										label={field.label}
										error={errors[field.name as keyof CustomFormData]?.message}
									/>
								) : field.fieldType.type === 'file' ? (
									<CustomFileInput
										ref={fileInputRef}
										value={value as string | number}
										attributes={field.fieldType.attributes}
										className={field.fieldType.className}
										templateLink={field.fieldType.templateLink}
										onFileRead={(sheetName, data) => {
											onChange({ name: sheetName, values: data });
											handleFieldChange(field.name);
										}}
										onLoading={(isLoading) => setSubmitDisabled(isLoading)}
										label={field.label}
										error={errors[field.name as keyof CustomFormData]?.message}
									/>
								) : (
									<div></div>
								)
							}
						/>
					</div>
					{index < fields.length - 1 ? <hr className="my-3" /> : ''}
				</React.Fragment>
			))}

			<div className="d-flex gap-2 py-3">
				<Button
					type="submit"
					disabled={submitDisabled || isLoading}
					loading={isLoading}
					className="btn-primary"
				>
					{submitLabel ?? 'Submit'}
				</Button>
				<Button
					onClick={onResetForm}
					disabled={isLoadingReset || submitDisabled || (store.state.ptd === null && store.state.fileData === null)}
					loading={isLoadingReset}
					className="btn-outline-primary"
				>
					Reset
				</Button>
			</div>
		</form>
	);
};

export default CustomForm;
