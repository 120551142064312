import React, { useContext } from 'react';
import { DataTable, LoadingPage } from 'components/core';
import ActionCell from 'screens/PremiumTradeDirectory/components/views/ActionCell';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { usePTD } from 'context/PTD/PTDContext';
import TableColumnVendorName from 'components/tableColumns/TableColumnVendorName';
import TableColumnOperatingLocation from 'components/tableColumns/TableColumnOperatingLocation';
import TableColumnNotes from 'components/tableColumns/TableColumnNotes';
import TableColumnUserDefinedStatus from 'components/tableColumns/TableColumnUserDefinedStatus';
import TableColumnValidedRowStatus from 'components/tableColumns/TableColumnValidedRowStatus';
import TableColumnProductName from 'components/tableColumns/TableColumnProductName';
import PTDAPIs from 'api/PTDAPI';
import { toast } from 'react-toastify';
import { usePTDVendors } from 'context/PTDVendors/PTDVendorsContext';
import PickListContext from 'context/PickList/PickListContext';

interface PremiumTradeDirectoryListFlatProps {
	id: string | undefined;
	vendorFormMethods: UseFormReturn<any, object>;
	idPTDList: number;
	addUpdateProduct(
		fields: Partial<PTDProductRequest>,
		id: number
	): Promise<boolean>;
	setAddProductModal: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			product?: PTDProduct;
		}>
	>;
	setDeleteModal: React.Dispatch<
		React.SetStateAction<{
			isOpen: boolean;
			idPTDProduct: number | undefined;
			idPTDVendor: number | undefined;
			label: string;
			isDeleting: 'product' | 'vendor' | 'both' | undefined;
		}>
	>;
	productSpecsList: ProductTypeDTO[] | undefined;
}

// Define a new type that extends PTDVendor and adds productId and productName
type VendorWithProductInfo = Partial<PTDVendor> & PTDProduct;

function PremiumTradeDirectoryListFlat({
	id,
	vendorFormMethods,
	idPTDList,
	setAddProductModal,
	setDeleteModal,
	addUpdateProduct,
	productSpecsList,
}: PremiumTradeDirectoryListFlatProps) {
	// Get PTD
	const {
		getPTDProducts,
		getOrganizationList,
		isInitializingPTDProducts,
	} = usePTD();
	const {
		getPTDVendors,
		addPTDVendor,
		updatePTDVendor,
		isInitializingPTDVendors,
	} = usePTDVendors();
	const PickListCxt = useContext(PickListContext);

	const PTDProducts = getPTDProducts();
	const PTDVendors = getPTDVendors();
	const organizationList = getOrganizationList();

	const [editTable, setEditTable] = React.useState<ProductTable | null>(null);
	const [isSavingRow, setIsSavingRow] = React.useState<boolean>(false);
	const isMounted = React.useRef(true);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false; // cleanup on unmount
		};
	}, []);

	const flatArray: VendorWithProductInfo[] = PTDProducts.flatMap((product) => {
		const vendors = PTDVendors[product.id] || []; // Fetch vendors for the product
		const { id, ...restOfProduct } = product;
		const currentProdSpec = product.productSpecList.filter((s) => s.pickListItemName);
		const prodSpecs = productSpecsList?.find((p) => p.productTypePickListItemId === product.productsTypePickListItemId)?.productSpecCodes || [];

		if (currentProdSpec.length < prodSpecs.length) {
			prodSpecs.forEach((spec, index) => {
				const pickListType = PickListCxt.pickListTypes.find(
					(x) => x.code === spec.productSpecCode
				);

				if (!currentProdSpec.find((s) => s.speckListItemId === pickListType?.id)) {
					currentProdSpec.splice(index, 0, {
						pickListItemName: `${spec.productSpecDescription}: Any`,
						id: 0,
						speckListItemId: spec.productSpecPickListItemId || 0,
						speckListItemName: spec.productSpecValue || '',
						pickListItemId: 0
					});
				}
			});
		}
		const productName =
			currentProdSpec.length > 0
				? product.name?.concat(
					' | ',
					currentProdSpec.map((r) => r.pickListItemName).join(' | ')
				  )
				: product.name;

		return vendors.length > 0
			? vendors.map((vendor) => ({
				...vendor,
				...restOfProduct,
				name: productName,
			  }))
			: ({
				...restOfProduct,
				id,
				productId: id,
				name: productName,
			  } as VendorWithProductInfo);
	});

	function onClickEditOrDuplicate(
		row: any,
		type: 'edit' | 'duplicate',
		productID: number
	) {
		const keys = Object.keys(row.original);
		const nonEditable = ['validated'];
		nonEditable.forEach((val) => {
			const index = keys.indexOf(val);
			if (index > -1) {
				keys.splice(index, 1);
			}
		});
		keys.forEach((key) => {
			vendorFormMethods.setValue('specs.' + key, row.original[key]);
		});
		if (type === 'edit') {
			const newTable: ProductTable = {
				rowId: row.index,
				prodId: productID,
				addVendor: !PTDVendors[productID] || PTDVendors[productID].length === 0,
			};
			setEditTable(newTable);
		} else {
			const newTable: ProductTable = {
				rowId: 0,
				prodId: productID,
				duplicateRow: row.original.id,
			};
			setEditTable(newTable);
		}
	}

	function closeAddRow() {
		setEditTable(null);
		vendorFormMethods.reset(
			{
				specs: {
					name: '',
					vendorName: '',
					operatingLocation: '',
					notes: '',
					userDefinedStatus: 0,
					validated: '',
				},
			},
			{ keepDirty: false, keepValues: false }
		);
	}

	async function submitProductVendorForm(
		fields: FieldValues,
		idPTDProduct: number,
		idPTDVendor: number
	) {
		if (!!editTable) {
			setIsSavingRow(true);

			let vendorRequest, parts, city, region, country;
			// set up request data
			if (fields.specs.operatingLocation) {
				parts = fields.specs.operatingLocation
					.split(',')
					.map((part: string) => part.trim());
				city = parts[0];
				region = parts.length >= 3 ? parts[parts.length - 2] : null;
				country = parts[parts.length - 1];
			}

			if (fields.specs.vendorId) {
				vendorRequest = {
					...fields.specs,
					vendorName:
						organizationList?.find((org) => org.id === fields.specs.vendorId)
							?.organizationName || '',
					city: city || '',
					region: region || '',
					country: country || '',
				};
			}

			const productRequest = {
				...fields.specs,
				pickListItemIds: fields.specs.productSpecList.map(
					(s: any) => s.pickListItemId
				),
				speckListItemIds: fields.specs.productSpecList.map(
					(s: any) => s.speckListItemId
				),
				premiumTradeDirectoryId: idPTDList,
			};

			try {
				const product = PTDProducts.find(
					(product) => product.id === idPTDProduct
				);
				const productName =
					product &&
					product.productSpecList &&
					product.productSpecList.length > 0
						? product.name?.concat(
							' | ',
							product.productSpecList
								.map((r) => r.pickListItemName)
								.join(' | ')
						  )
						: product?.name;
				const isChanged = product && productName !== fields.specs.name;

				if (isChanged) {
					await addUpdateProduct(productRequest, idPTDProduct);
				}

				if (vendorRequest) {
					const updateVendor: PTDVendorPutRequest = {
						...vendorRequest,
						id: idPTDVendor,
					};

					if (editTable.newLine ||
						editTable.duplicateRow ||
						editTable.addVendor) {
						updateVendor.id = undefined;
					} else {
						updateVendor.id = idPTDVendor;
					}

					const response: PTDVendor | null =
							await PTDAPIs.premiumTradeDirectories.UpdateVendor(updateVendor);

					if (response && isMounted.current) {
						if (editTable.newLine ||
							editTable.duplicateRow ||
							editTable.addVendor) {
							addPTDVendor(response, idPTDProduct);
							toast.success('Vendor was added!');
						} else {
							updatePTDVendor(response, idPTDProduct);
							toast.success('Vendor was updated!');
						}
					}
				}
			} catch (error) {
				if (isMounted.current) {
					toast.error('Failed to save vendor');
				}
			} finally {
				if (isMounted.current) {
					setIsSavingRow(false);
					setEditTable(null);
					vendorFormMethods.reset(
						{
							specs: {
								name: '',
								vendorName: '',
								operatingLocation: '',
								notes: '',
								userDefinedStatus: 0,
								validated: '',
							},
						},
						{ keepDirty: false, keepValues: false }
					);
				}
			}
		}
	}

	// new row start
	const newRow: VendorWithProductInfo = {
		id: 0,
		vendorName: '',
		operatingLocation: '',
		notes: '',
		productId: 0,
		createdAt: '',
		validatedRowStatus: 0,
		creatorId: '',
		name: '',
		premiumTradeDirectoryId: 0,
		productsTypePickListItemId: 0,
		productSpecList: [],
	};

	if (editTable?.newLine === true) {
		flatArray.unshift(newRow);
	}
	// new row end

	/// duplicated row start
	if (!!editTable?.duplicateRow) {
		const row = flatArray.find((x) => {
			return x.id === editTable.duplicateRow;
		});
		const lineItem: any = { ...row };

		lineItem['id'] = 'duplicate';

		flatArray.unshift(lineItem);
	}
	/// duplicated row end

	const columns = [
		{
			Header: 'Product',
			accessor: 'name',
			filterable: true,
			Cell: (row: any) => {
				return (
					<TableColumnProductName
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
						setAddProductModal={setAddProductModal}
					/>
				);
			},
		},
		{
			Header: 'Vendor',
			accessor: 'vendorName',
			filterable: true,
			Cell: (row: any) => {
				return (
					<TableColumnVendorName
						id={id}
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
					/>
				);
			},
		},
		{
			Header: 'Location',
			accessor: 'operatingLocation',
			filterable: true,
			Cell: (row: any) => {
				return (
					<TableColumnOperatingLocation
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
					/>
				);
			},
		},
		{
			Header: 'Status',
			accessor: 'userDefinedStatus',
			filterable: true,
			Cell: (row: any) => {
				return (
					<TableColumnUserDefinedStatus
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
					/>
				);
			},
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			filterable: true,
			Cell: (row: any) => {
				return (
					<TableColumnNotes
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
					/>
				);
			},
		},
		{
			Header: 'Validated',
			accessor: 'validatedRowStatus',
			filterable: false,
			className: 'text-center validated-column-padding',
			headerClassName: 'text-center',
			Cell: (row: any) => {
				return (
					<TableColumnValidedRowStatus
						row={row}
						vendorFormMethods={vendorFormMethods}
						editTable={editTable}
					/>
				);
			},
		},
		{
			accessor: 'id',
			show: false,
		},
		{
			Header: '',
			accessor: 'actions',
			filterable: false,
			sortable: false,
			className: 'text-center pt-1',
			Cell: (row: any) => {
				const rowIsDisabled = !!editTable && editTable.rowId !== row.index;
				const isEditingCurrentRow =
					(!!editTable && editTable.rowId === row.index) ||
					(!!editTable?.duplicateRow && row.index === 0);

				return (
					<ActionCell
						formMethods={vendorFormMethods}
						idPTDList={idPTDList}
						closeAddRow={closeAddRow}
						onClickEditOrDuplicate={(action: 'edit' | 'duplicate') => {
							onClickEditOrDuplicate(row, action, row.original.productId);
						}}
						openDeleteModal={() =>
							setDeleteModal({
								isOpen: true,
								idPTDProduct: row.original.productId,
								idPTDVendor: row.original.id,
								label: 'Are you sure you want to delete this whole item?',
								isDeleting:
									PTDVendors[row.original.productId].length > 1
										? 'vendor'
										: 'both',
							})
						}
						isSavingRow={isSavingRow}
						isEditingCurrentRow={isEditingCurrentRow}
						rowIsDisabled={rowIsDisabled}
						submitForm={(fields) =>
							submitProductVendorForm(
								fields,
								row.original.productId,
								row.original.id
							)
						}
					/>
				);
			},
		},
	];

	if (isInitializingPTDProducts === true) {
		return <LoadingPage />;
	}

	return (
		<div className="row mt-4">
			<div className="col-12">
				<DataTable
					data={flatArray}
					columns={columns}
					filterable={true}
					sortable={true}
					resizable={true}
					noDataText="No data found."
					loading={isInitializingPTDVendors}
				/>
			</div>
		</div>
	);
}

export default PremiumTradeDirectoryListFlat;
