import React, { useCallback } from 'react';
import { usePTD } from 'context/PTD/PTDContext';
import { useParams } from 'react-router-dom';
import {
	Button,
	DataTable,
	FormGroup,
	Input,
	LoadingPage,
	Page,
	Select,
} from 'components/core';
import TitleBar from 'screens/Components/TitleBar';
import { Text } from 'screens/ProductCatalog/components/columns';
import {
	Controller,
	FieldValues,
	FormProvider,
	useForm,
} from 'react-hook-form';
import { IconAdd } from 'assets/icons';
import { capitalize } from 'lodash';
import { emailRegex } from 'utils';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import DeleteAccessUserModal from 'screens/PremiumTradeDirectory/components/modals/DeleteUserAccessModal';
import PTDAPIs from 'api/PTDAPI';
import { toast } from 'react-toastify';
import { getEnumFromAccessPermision } from 'utils/utils';
import ActionCell from 'screens/PremiumTradeDirectory/components/views/ActionCell';

function PTDAccessOverview() {
	const params = useParams();
	const idPTDList = Number(params.idPTDList);
	const listName = params.listName;

	// Get PTD List
	const {
		getPTDAccess,
		getSinglePTDList,
		fetchUserAccessForPTDList,
		addAccessToListForUser,
		updateAccessToListForUser,
		deleteAccessUser,
		isInitializingPTDAccess,
	} = usePTD();
	const PTDList: PremiumTradeDirectory | undefined = getSinglePTDList(
		idPTDList,
		listName
	);

	// General Page Info
	const pageTitle = PTDList
		? `Access Overview for ${PTDList.premiumTradeDirectoryName}`
		: 'Access Overview';
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [showDeleteAccessUserModal, setDeleteAccessUserModal] = React.useState<{
		show: boolean;
		idAccessUser: number | undefined;
	}>({ show: false, idAccessUser: undefined });
	const [organizationList, setOrganizationList] = React.useState<
		OrganizationDTO[]
	>([]);

	// Table
	const accessList: PTDAccess[] = getPTDAccess().filter(
		(a) => a.premiumTradeDirectoryId === idPTDList
	);
	const accessListFormMethods = useForm<any>({ reValidateMode: 'onChange' });
	const [editTable, setEditTable] = React.useState<{
		rowId: number;
		newLine?: boolean;
		listID?: string;
		duplicateRow?: number;
	} | null>(null);
	const [isSavingRow, setIsSavingRow] = React.useState<boolean>(false);

	// Permissons
	const permissions: AccessPermission[] = ['View', 'Edit', 'Suggest'];
	const permissionsOptions: {
		value: string;
		label: string;
	}[] = permissions.map((permission) => ({
		value: permission,
		label: capitalize(permission),
	}));

	// Memoize fetchData using useCallback
	const fetchUserAccessCallback = useCallback(
		(signal) => {
			fetchUserAccessForPTDList(idPTDList, signal);
		},
		[idPTDList, fetchUserAccessForPTDList]
	);

	React.useEffect(() => {
		let isMounted = true; // track whether the component is mounted
		const controller = new AbortController();
		const signal = controller.signal;

		async function fetchOrganizationList() {
			const results = await scopeMatchAPI.organization.GetOrganizationList(
				signal
			);
			if (isMounted) {
				setOrganizationList(results || []);
				setIsLoading(false);
			}
		}

		fetchOrganizationList();

		return () => {
			isMounted = false; // set to false when the component unmounts
			controller.abort(); // cancel the request before component unmounts
		};
	}, []);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetchUserAccessCallback(signal);

		return () => {
			// cancel the request before component unmounts
			controller.abort();
		};
	}, [fetchUserAccessCallback]);

	const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return (
						<Controller
							key={'name'}
							control={accessListFormMethods.control}
							defaultValue={row.value || ''}
							rules={{
								required: 'This is a required field.',
							}}
							name="specs.name"
							render={({ field, fieldState }) => (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="me-2"
								>
									<Input
										id="name"
										name={field.name}
										value={field.value}
										onChange={field.onChange}
										error={!!fieldState.error?.message}
										className="mt-1 mb-n3 pb-n2 p-1 fs-14"
									/>
								</FormGroup>
							)}
						/>
					);
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text></Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Email',
			accessor: 'email',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return (
						<Controller
							key={'email'}
							control={accessListFormMethods.control}
							defaultValue={row.value || ''}
							rules={{
								required: 'This is a required field.',
								pattern: {
									value: emailRegex,
									message: 'Not an email address',
								},
							}}
							name="specs.email"
							render={({ field, fieldState }) => (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="me-2"
								>
									<Input
										id="email"
										name={field.name}
										value={field.value}
										onChange={field.onChange}
										error={!!fieldState.error?.message}
										className="mt-1 mb-n3 pb-n2 p-1 fs-14"
									/>
								</FormGroup>
							)}
						/>
					);
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text></Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Company',
			accessor: 'company',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return (
						<Controller
							key={'company'}
							control={accessListFormMethods.control}
							defaultValue={row.value || ''}
							rules={{
								required: 'This is a required field.',
							}}
							name="specs.company"
							render={({ field, fieldState }) => (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="me-2"
								>
									<Select
										id="company"
										name={field.name}
										value={field.value}
										options={organizationList.map((org) => ({
											value: org.id,
											label: org.organizationName,
										}))}
										onChange={field.onChange}
										onMenuOpen={accessListFormMethods.clearErrors}
										className="mt-1 mb-n3 pb-n2 table-select p-1 fs-14"
									/>
								</FormGroup>
							)}
						/>
					);
				}
				if (row.value === undefined || row.value.length === 0) {
					return (
						<Text>
							<i className="text-muted">Can`t find name</i>
						</Text>
					);
				}
				return (
					<Text>
						{organizationList.find((org) => org.id === row.value)
							?.organizationName || (
							<i className="text-muted">Can`t find name</i>
						)}
					</Text>
				);
			},
		},
		{
			Header: 'Role',
			accessor: 'role',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return (
						<Controller
							key={'role'}
							control={accessListFormMethods.control}
							defaultValue={row.value || ''}
							rules={{
								required: 'This is a required field.',
							}}
							name="specs.role"
							render={({ field, fieldState }) => (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="me-2"
								>
									<Input
										id="role"
										name={field.name}
										value={field.value}
										onChange={field.onChange}
										error={!!fieldState.error?.message}
										className="mt-1 mb-n3 pb-n2 p-1 fs-14"
									/>
								</FormGroup>
							)}
						/>
					);
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text></Text>;
				}
				return <Text>{row.value}</Text>;
			},
		},
		{
			Header: 'Permission',
			accessor: 'permission',
			filterable: true,
			Cell: (row: any) => {
				if (editTable?.rowId === row.index) {
					return (
						<Controller
							key={'permission'}
							control={accessListFormMethods.control}
							defaultValue={row.value || ''}
							rules={{
								required: 'This is a required field.',
							}}
							name="specs.permission"
							render={({ field, fieldState }) => (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="me-2"
								>
									<Select
										id="permission"
										name={field.name}
										value={field.value}
										options={permissionsOptions}
										onChange={field.onChange}
										onMenuOpen={accessListFormMethods.clearErrors}
										className="mt-1 mb-n3 table-select p-1 fs-14"
									/>
								</FormGroup>
							)}
						/>
					);
				}
				if (row.value === undefined || row.value.length === 0) {
					return <Text></Text>;
				}
				return (
					<Text>
						{
							permissionsOptions.find(
								(permission) => permission.value === row.value
							)?.label
						}
					</Text>
				);
			},
		},
		{
			accessor: 'id',
			show: false,
		},
		{
			Header: '',
			accessor: 'actions',
			filterable: false,
			Cell: (row: any) => {
				const rowIsDisabled = !!editTable && editTable.rowId !== row.index;
				const isEditingCurrentRow =
					(!!editTable && editTable.rowId === row.index) ||
					(!!editTable?.duplicateRow && row.index === 0);

				return (
					<React.Fragment>
						<ActionCell
							formMethods={accessListFormMethods}
							idPTDList={idPTDList}
							isEditingCurrentRow={isEditingCurrentRow}
							rowIsDisabled={rowIsDisabled}
							submitForm={(fields) => submitAccessListForm(fields)}
							closeAddRow={closeAccessListAddRow}
							onClickEditOrDuplicate={(action: 'edit' | 'duplicate') =>
								onClickEditOrDuplicate(row, action)
							}
							openDeleteModal={() => openDeleteAccessUserModal(row.original.id)}
							isSavingRow={isSavingRow}
						/>
					</React.Fragment>
				);
			},
		},
	];

	/// new row start
	const newRow: PTDAccess = {
		id: 0,
		name: '',
		email: '',
		company: 0,
		role: '',
		permission: 'Edit',
	};

	if (editTable?.newLine === true) {
		accessList.unshift(newRow);
	}
	/// new row end

	/// duplicated row start
	if (!!editTable?.duplicateRow) {
		const row = accessList.find((x) => {
			return x.id === editTable.duplicateRow;
		});
		const lineItem: any = { ...row };

		lineItem['id'] = 'duplicate';

		accessList.unshift(lineItem);
	}
	/// duplicated row end

	/// Add Access User start
	function onClickEditOrDuplicate(row: any, type: 'edit' | 'duplicate') {
		const keys = Object.keys(row.original);

		keys.forEach((key) => {
			accessListFormMethods.setValue('specs.' + key, row.original[key]);
		});

		if (type === 'edit') {
			setEditTable({
				rowId: row.index,
				listID: row.original.id,
			});
		} else {
			setEditTable({
				rowId: 0,
				listID: '0',
				duplicateRow: row.original.id,
			});
		}
	}

	function closeAccessListAddRow() {
		accessListFormMethods.reset();
		setEditTable(null);
	}

	async function submitAccessListForm(fields: FieldValues) {
		setIsSavingRow(true);
		const newData: PTDAccess = { ...fields.specs };
		if (
			editTable &&
			newData.name &&
			newData.company &&
			newData.email &&
			newData.role
		) {
			if (editTable.newLine || editTable.duplicateRow) {
				const newAccess: PTDAccessRequest = {
					...newData,
					permission: getEnumFromAccessPermision(newData.permission),
					premiumTradeDirectoryId: idPTDList,
				};

				try {
					const response: PTDAccess | null =
						await PTDAPIs.premiumTradeDirectories.AddUserAccess(newAccess);
					if (response) {
						addAccessToListForUser(response);
						toast.success('Access was added!');
					}
				} finally {
					setIsSavingRow(false);
					setEditTable(null);
					accessListFormMethods.reset(
						{},
						{ keepDirty: false, keepValues: false }
					);
				}
			} else {
				const oldData = accessList.find((access) => access.id === newData.id);
				const updateAccess: Partial<PTDAccessPutRequest> = {
					id: newData.id,
					...(oldData?.name !== newData.name && { name: newData.name }),
					...(oldData?.email !== newData.email && { email: newData.email }),
					...(oldData?.company !== newData.company && {
						company: newData.company,
					}),
					...(oldData?.permission !== newData.permission && {
						permission: getEnumFromAccessPermision(newData.permission),
					}),
					...(oldData?.role !== newData.role && { role: newData.role }),
					premiumTradeDirectoryId: idPTDList,
				};

				try {
					const response: PTDAccess | null =
						await PTDAPIs.premiumTradeDirectories.UpdateUserAccess(
							updateAccess
						);
					if (response) {
						updateAccessToListForUser(response);
						toast.success('Access was updated!');
					}
				} finally {
					setIsSavingRow(false);
					setEditTable(null);
					accessListFormMethods.reset(
						{},
						{ keepDirty: false, keepValues: false }
					);
				}
			}
		}
	}
	/// Add Access User end

	// delete modal start
	function openDeleteAccessUserModal(idAccessUser: number) {
		setDeleteAccessUserModal({ show: true, idAccessUser: idAccessUser });
	}

	function closeDeleteAccessUserModal() {
		setDeleteAccessUserModal({ show: false, idAccessUser: undefined });
	}

	async function onDeleteAccessUser(idAccessUser: number) {
		setIsLoading(true);
		try {
			const response: string | null =
				await PTDAPIs.premiumTradeDirectories.DeleteUserAccess(idAccessUser);

			if (response) {
				deleteAccessUser(idAccessUser);
				toast.success(response);
				return true;
			} else {
				return false;
			}
		} finally {
			setIsLoading(false);
		}
	}
	// delete modal end

	if (isLoading === true || isInitializingPTDAccess) {
		return <LoadingPage />;
	}

	return (
		<FormProvider {...accessListFormMethods}>
			<Page title={pageTitle}>
				<div className="d-flex">
					<div className="d-flex flex-column w-100">
						<TitleBar pageTitle={pageTitle} />
					</div>
				</div>
				<div className="container-fluid mb-2 px-5 py-3">
					<div className="row">
						<div className="col-12">
							<div className="bg-light border-bottom border-primary-200 px-3 py-2 d-flex align-items-center rounded-top custom-shadow">
								<Button
									onClick={() => {
										accessListFormMethods.reset(
											{},
											{ keepValues: false, keepDirty: false }
										);
										setEditTable({
											rowId: 0,
											newLine: true,
										});
									}}
									disabled={!!editTable}
									className="ms-auto text-primary py-0 fs-14 fw-normal"
								>
									<IconAdd className="me-2 svg-20" />
									Add User
								</Button>
							</div>
							<DataTable
								data={accessList}
								columns={columns}
								filterable={true}
								sortable={true}
								resizable={true}
								noDataText="No data found."
							/>
						</div>
					</div>
				</div>

				<DeleteAccessUserModal
					isOpen={showDeleteAccessUserModal.show}
					idAccessUser={showDeleteAccessUserModal.idAccessUser}
					onRequestClose={closeDeleteAccessUserModal}
					deleteAccessUser={onDeleteAccessUser}
				/>
			</Page>
		</FormProvider>
	);
}

export default PTDAccessOverview;
