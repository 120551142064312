import Graph from 'graphology';
import { FileData, FlatPremiumTradeDirectory } from '../types/interfaces';

export interface State {
    ptd: {name: string, values: FlatPremiumTradeDirectory[]} | null,
    fileData: {name: string, values: FileData[]} | null;
    graph: Graph;
}

export const READ_FILE = 'READ_FILE';
export const DELETE_FILE = 'DELETE_FILE';

interface ReadFileAction {
    type: typeof READ_FILE;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileData: {name: string, values: FileData[]},
}

interface DeletetFileAction {
    type: typeof DELETE_FILE;
}

export const READ_GRAPH = 'READ_GRAPH';

interface ReadGraphAction {
    type: typeof READ_GRAPH;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graph: Graph
}

export const READ_PTD = 'READ_PTD';
export const DELETE_PTD = 'DELETE_PTD';

interface ReadPTDAction {
    type: typeof READ_PTD;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ptd: {name: string, values: FlatPremiumTradeDirectory[]}
}

interface DeletetPTDAction {
    type: typeof DELETE_PTD;
}

export type ActionTypes = ReadFileAction | DeletetFileAction | ReadGraphAction | ReadPTDAction | DeletetPTDAction;
