import React, { forwardRef, useState } from 'react';
import { CustomFileInputProps } from '../../types/interfaces';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { Spinner } from 'components/core';

const CustomFileInput = forwardRef<HTMLInputElement, CustomFileInputProps>(
	({ attributes, onFileRead, onLoading, label, error, className, templateLink }, ref) => {
		const [isLoading, setIsLoading] = useState(false);

		const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setIsLoading(true);
			onLoading(true);
			const file = e.target.files?.[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (event) => {
					const binaryStr = event.target?.result;

					if (file.name.endsWith('.csv')) {
						Papa.parse(binaryStr as string, {
							header: true,
							complete: (results) => {
								onFileRead(file.name.replace('.csv', ''), results.data);
							},
						});
					} else if (
						file.name.endsWith('.xlsx') ||
            			file.name.endsWith('.xls')
					) {
						const workbook = XLSX.read(binaryStr, { type: 'binary' });
						const sheetName = workbook.SheetNames[0];
						const sheet = workbook.Sheets[sheetName];
						const data = XLSX.utils.sheet_to_json(sheet);
						const fileName = file.name.replace('.xlsx', '').replace('.xls', '');
						onFileRead(fileName, data);
					}
					setIsLoading(false);
					onLoading(false);
				};

				if (file.name.endsWith('.csv')) {
					reader.readAsText(file);
				} else {
					reader.readAsBinaryString(file);
				}
			}
		};

		return (
			<div className="mb-3">
				<label
					htmlFor={label}
					className="form-label fs-6 text-dark"
				>
					{label}
				</label>
				<small>
					{templateLink && <a href={templateLink} download="AML-template.xlsx" className="small ms-1">Download Template</a> }
				</small>
				<div className="d-flex align-items-center mb-2">
					<input
						ref={ref}
						type="file"
						id={label}
						name={label}
						onChange={handleFileChange}
						{...attributes}
						className={`form-control ${error ? 'is-invalid' : ''} ${className}`}
					/>
					{isLoading === true && (
						<Spinner className="ms-2" small={true} />
					)}
				</div>

				{error && (
					<p id={label + '-error'} className="text-danger mt-2">
						{error}
					</p>
				)}
			</div>
		);
	}
);

export default CustomFileInput;
