/* eslint-disable indent */
import PickListContext from 'context/PickList/PickListContext';
import React, { useEffect, useRef } from 'react';
import {
	ActionMeta,
	default as ReactSelect,
	GroupBase,
	OptionsOrGroups,
	Props as ReactSelectProps,
} from 'react-select';

interface IProps extends Omit<ReactSelectProps, 'options'> {
	pickListTypeName: string;
	error?: boolean;
	filter?: string[];
	additionalOptions?: { value: string; label: string }[];
	useValue1?: boolean;
	orderBy?: {
		order: 'description' | 'id';
		orderBy: 'asc' | 'desc';
	};
}

export const PickListSelect = (props: IProps) => {
	const elementRef = useRef<HTMLDivElement>(null);
	const {
		pickListTypeName,
		value,
		isMulti,
		error,
		onChange,
		filter,
		additionalOptions,
		useValue1,
		...otherProps
	} = props;
	const PickListCxt = React.useContext(PickListContext);
	let options = getPicklistOptions(pickListTypeName);

	if (additionalOptions) {
		options = [...additionalOptions, ...options];
	}

	useEffect(() => {
		if (elementRef.current) {
			// Trigger a reflow
			elementRef.current.style.display = 'none';
			elementRef.current.offsetHeight; // Force a reflow, flushing the CSS changes
			elementRef.current.style.display = '';
		}
	}, []);

	return (
		<div ref={elementRef} className="select-height-wrapper">
			<ReactSelect
				isMulti={isMulti || false}
				options={options}
				value={getValue(value, options)}
				onChange={onChangeVal}
				className="select-height"
				menuPosition="fixed"
				styles={{
					control: (styles) => ({
						...styles,
						borderColor: error ? '#F15D1E' : '#BDB3AD',
						backgroundColor: error ? '#FFE6DC' : '#FFFFFF',
					}),
				}}
				{...otherProps}
			/>
		</div>
	);
	function getPicklistOptions(code: string) {
		const pickListType = PickListCxt.pickListTypes.find((x) => x.code === code);
		const picklistOptions = PickListCxt.pickListItems.filter(
			(x) => x.pickListType?.code === pickListType?.code
		);

		const options = picklistOptions.map((item) => {
			if (useValue1 === true) {
				return { value: item.code, label: item.value1 };
			} else {
				return { value: item.code, label: item.description };
			}
		});

		if (props.orderBy) {
			const { order, orderBy } = props.orderBy;
			options.sort((a, b) => {
				let valueA: string | number, valueB: string | number;
				switch (order) {
					case 'id':
						valueA =
							picklistOptions.find((item) => item.code === a.value)?.id || '';
						valueB =
							picklistOptions.find((item) => item.code === b.value)?.id || '';
						break;
					case 'description':
					default:
						valueA = a.label || '';
						valueB = b.label || '';
						break;
				}
				const comparison = valueA.toString().localeCompare(valueB.toString());
				return orderBy === 'asc' ? comparison : -comparison;
			});
		} else {
			options.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
		}

		if (filter !== undefined) {
			return options.filter((v) => v.value && !filter.includes(v.value));
		}
		return options;
	}

	function getValue(
		value: unknown,
		options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
	) {
		if (Array.isArray(value)) {
			const val = options?.filter(
				(v: any) => value.findIndex((j: any) => j === v.value) !== -1
			);

			return val;
		}
		if (typeof value === 'string') {
			const val = options?.filter((v: any) => v.value === value);

			return val;
		}
		return undefined;
	}

	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map((v) => v.value);

			onChange(value, actionMeta);
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any };
			const value = thewNewValue.value!;
			onChange(value, actionMeta);
			return;
		}
		onChange(undefined, actionMeta);
		return undefined;
	}
};