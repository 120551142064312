import { useEffect, useState } from 'react';
import { DynamicTableData, GeneralStatsProps } from '../../types/interfaces';

import React from 'react';
import { groupBy } from 'lodash';
import H6 from 'vna/components/headings/h6';

function GeneralStats({ aml }: GeneralStatsProps) {
	const [stats, setStats] = useState<{ name: string; stat: number }[]>([]);

	useEffect(() => {
		setStats([
			{
				name: 'Vendors',
				stat: Object.keys(
					groupBy(aml, ({ vendor }: DynamicTableData) => {
						return vendor;
					})
				).length,
			},
			{
				name: 'Countries',
				stat: Object.keys(
					groupBy(aml, ({ country }: DynamicTableData) => {
						return country;
					})
				).length,
			},
			{
				name: 'Products',
				stat: Object.keys(
					groupBy(aml, ({ product }: DynamicTableData) => {
						return product;
					})
				).length,
			},
		]);
	}, [aml]);

	return (
		<div>
			<div className="mt-4 row row-cols-1 row-cols-sm-3 g-4 text-center">
				{stats.map((item) => (
					<div key={item.name} className="col">
						<div className="card overflow-hidden shadow-sm bg-white rounded px-2 py-3">
							<div className="card-body">
								<H6 className="card-title text-truncate text-muted small">
									{item.name}
								</H6>
								<p className="card-text fs-2 fw-normal text-dark">
									{item.stat}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default GeneralStats;
