import { useState, useEffect } from 'react';

const useBreakpointPills = () => {
	const [truncateNumOfPills, setTruncateNumOfPills] = useState(3); // Default value

	useEffect(() => {
		const handleResize = () => {
			if (window.matchMedia('(min-width: 1200px)').matches) {
				setTruncateNumOfPills(9); // Extra Large screens
			} else if (window.matchMedia('(min-width: 992px)').matches) {
				setTruncateNumOfPills(6); // Large screens
			} else if (window.matchMedia('(min-width: 768px)').matches) {
				setTruncateNumOfPills(3); // Medium screens
			} else {
				setTruncateNumOfPills(2); // Small screens
			}
		};

		handleResize(); // Set initial truncate value
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return truncateNumOfPills;
};

export default useBreakpointPills;
