// import { CustomInputProps } from '../../types/interfaces';
// import React from 'react';

// const CustomInput = ({
// 	value,
// 	onChange,
// 	label,
// 	error,
// 	attributes,
// }: CustomInputProps) => (
// 	<div>
// 		<label
// 			htmlFor={label}
// 			className="block text-sm font-medium leading-6 text-gray-900"
// 		>
// 			{label}
// 		</label>
// 		<div className="mt-2">
// 			<input
// 				value={value}
// 				onChange={onChange}
// 				id={label}
// 				name={label}
// 				{...attributes}
// 				className="block w-full rounded-md border-0 py-1.5
//         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
//         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600
//         sm:text-sm sm:leading-6"
// 			/>
// 		</div>

// 		{error && (
// 			<p id={label + '-error'} className="mt-2 text-sm text-red-600">
// 				{error}
// 			</p>
// 		)}
// 	</div>
// );

// export default CustomInput;

import { CustomInputProps } from '../../types/interfaces';
import React from 'react';

const CustomInput = ({
	value,
	onChange,
	label,
	error,
	attributes,
	className
}: CustomInputProps) => (
	<div className="mb-3">
		<label
			htmlFor={label}
			className="form-label fs-6 text-dark"
		>
			{label}
		</label>
		<div className="mb-2">
			<input
				value={value}
				onChange={onChange}
				id={label}
				name={label}
				{...attributes}
				className={`form-control ${className}`}
				style={{ borderColor: error ? 'red' : undefined }}
			/>
		</div>

		{error && (
			<p id={label + '-error'} className="text-danger mt-2">
				{error}
			</p>
		)}
	</div>
);

export default CustomInput;
