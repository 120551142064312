export enum OnboardingErrorMsg {
	CompanyType = 'Please select a company type',
	Role = 'Please specify a role',
	Name = 'Please specify a name',
	Email = 'Please specify an email',
	EmailPattern = 'Please enter a valid email address in the format: example@domain.com.',
	PhoneNumber = 'Please specify a phone number',
	PhoneNumberPattern = 'Please enter a valid phone number, including country code if necessary. Accepted formats include +1 800-555-5555, (555) 123-4567, or similar.',
	Office = 'Please enter a city',
	Insurance = 'Please select an insurance range. If your company doesn’t have insurance, select “None”',
	StandardsCertification = 'Please select from the dropdown list. If the options don’t apply to your company, select “None” or “Other”',
	Sector = 'Please select a sector. If none of the options are applicable, select “Other”',
	Industries = 'Please select an industry. If none of the options are applicable, select “Other”',
	Country = 'Please select a value',
	Countries = 'Please select the countries that apply',
	CompanyUrl = 'Please enter a company URL',
	CompanyUrlSyntax = 'Please enter a valid URL',
}

export enum NewRequestErrorMsg {
	RequestName = 'Please enter a request name',
	RequestDescription = 'Please enter a request description'
}

export function preventWindowClose(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
};