import React from 'react';
import { SigmaContainer } from '@react-sigma/core';
import { LoadGraph } from '../LoadGraph';
import { DynamicTableCell, GraphProps } from '../../../types/interfaces';
import GraphLegend from '../GraphLegend/GraphLegend';
import ConnectingPathsChart from '../ConnectingPathsChart';
import { useEffect, useState } from 'react';
import { allSimplePaths } from 'graphology-simple-path';
import EnhancedTable from '../../tables/TableMUI';
import CollapsibleTable from '../../tables/TableMUINested';
import GaugeChart from '../GaugeChart';
import { groupBy } from 'lodash';
import './NetworkGraph.scss'; // Import the CSS file
import { GAUGE_COLORS } from 'vna/types/enums';

const SingleSourcedTooltipText = 'Percentage of products that are sourced from a single vendor, posing a potential supply chain risk.';

const BottleneckTooltipText = 'Percentage of products that have a critical vendor with limited supply capacity, which could cause delays.';

const MorePathsTooltipText = 'Percentage of products that are connected to multiple vendors, ensuring supply chain redundancy.';

const DisconnectedProductsTooltipText = 'Percentage of products that are not currently connected to any vendors, indicating potential supply issues.';

const sigmaStyle = {
	height: '420px',
	width: '100%',
	backgroundColor: '#f6f6f6',
	cursor: 'pointer',
};

type PathCounts = { [key: string]: number };
type ChartData = {
	x: number;
	y: number | undefined;
	nodes: string[] | undefined;
}[];

function NetworkGraph({ data, graph }: GraphProps) {
	const [pathCountChartData, setPathCountChartData] = useState<ChartData>([]);

	const productNodes =
		graph?.nodes().filter((node) => node.startsWith('product-')) ?? [];

	const vendorNodes = graph
		?.nodes()
		.filter((node) => node.startsWith('vendor-'));

	useEffect(() => {
		if (data && graph) {
			const pathCounts: PathCounts = {};

			graph.forEachNode((node) => {
				if (node.startsWith('product-')) {
					// Only calculate for product nodes
					const parentNodes = graph
						.nodes()
						.filter((n) => n.startsWith('parent-'));

					parentNodes.forEach((parentNode) => {
						const paths = allSimplePaths(graph, parentNode, node);

						if (paths.length > 0) {
							pathCounts[node] = (pathCounts[node] || 0) + paths.length;
						}
					});
					if (!pathCounts[node]) {
						pathCounts[node] = 0;
					}
				}
			});

			// Group nodes by the number of paths
			const grouped: Partial<Record<number, [string, number][]>> = groupBy(
				Object.entries(pathCounts),
				([_, count]: [string, number]) => count
			);

			// Convert to the desired format [{x: numOfPaths, y: numOfProducts}]
			const chartData: ChartData = Object.entries(grouped).map(
				([numOfPaths, nodes]) => ({
					x: Number(numOfPaths),
					y: nodes?.length,
					nodes: nodes?.map(([name]) => name.replace('product-', '')),
				})
			);

			setPathCountChartData(chartData);
		}
	}, [data, graph]);

	////////// Single Source Product per Vendor ///////////////
	// Traverse through nodes and update edge attributes where necessary
	const singleSourceProductsRows = productNodes
		.filter((node) => {
			const neighbors = graph?.neighbors(node) ?? [];

			return neighbors.length === 1;
		})
		.map((node) => {
			const neighbors = graph?.neighbors(node) ?? [];

			return {
				product: node.replace('product-', ''),
				vendor: neighbors.join().replace('vendor-', ''),
			};
		});

	const singleSourceProductsColumns: readonly DynamicTableCell[] = [
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: true,
			label: 'Vendor',
		},
	];

	const singleSourceProductPerVendors: Partial<
		Record<string, { header: string; body?: string; }[]>
	> = groupBy(
		singleSourceProductsRows.map((row) => ({
			header: row.vendor,
			body: row.product,
		})),
		({ header }: { header: string }) => {
			return header;
		}
	);
	////////// Single Source Product per Vendor End ///////////////

	return (
		<div>
			{/* Graph */}
			<SigmaContainer
				style={sigmaStyle}
				settings={{ allowInvalidContainer: true }}
			>
				<LoadGraph data={data} />
			</SigmaContainer>

			{/* Legend Component */}
			<GraphLegend />

			{/* Total number of bridges */}
			{/* <div>
				<H6 className="text-left mt-8">
				Total bridges: {singleSourceProductsRows.length}
				</H6>
			</div> */}

			<div className="mt-4 d-flex flex-column flex-md-row align-items-center flex-wrap justify-content-between">
				<div className="row row-cols-3 row-cols-md-5 g-2 mx-auto">
					<div className="col">
						{/* Num. of Single Sourced Product */}
						<GaugeChart
							title="Single-Sourced"
							numOfSum={singleSourceProductsRows.length}
							sum={productNodes ? productNodes.length : 0}
							width={100}
							height={100}
							multiColor={true}
							tooltipText={SingleSourcedTooltipText}
						/>
					</div>

					<div className="col">
						{/* Num. of Bottleneck vendors */}
						<GaugeChart
							title="Bottleneck"
							numOfSum={Object.keys(singleSourceProductPerVendors).length}
							sum={vendorNodes ? vendorNodes.length : 0}
							width={100}
							height={100}
							multiColor={true}
							tooltipText={BottleneckTooltipText}
						/>
					</div>

					<div className="col">
						{/* Num. of Products with more than 2 connecting paths */}
						<GaugeChart
							title="2+ paths"
							numOfSum={
								productNodes?.filter((node) => {
									const neighbors = graph?.neighbors(node) ?? [];
									return neighbors.length >= 2;
								}).length
							}
							sum={productNodes ? productNodes.length : 0}
							width={100}
							height={100}
							tooltipText={MorePathsTooltipText}
						/>
					</div>

					<div className="col">
						{/* Num. of Products with more than 3 connecting paths */}
						<GaugeChart
							title="3+ paths"
							numOfSum={
								productNodes?.filter((node) => {
									const neighbors = graph?.neighbors(node) ?? [];
									return neighbors.length >= 3;
								}).length
							}
							sum={productNodes ? productNodes.length : 0}
							width={100}
							height={100}
							tooltipText={MorePathsTooltipText}
						/>
					</div>

					<div className="col">
						{/* Num. of Products with more than 3 connecting paths */}
						<GaugeChart
							title="Disconnected products"
							numOfSum={
								productNodes?.filter((node) => {
									const neighbors = graph?.neighbors(node) ?? [];
									return neighbors.length == 0;
								}).length
							}
							sum={productNodes ? productNodes.length : 0}
							width={100}
							height={100}
							tooltipText={DisconnectedProductsTooltipText}
							defaultColor={GAUGE_COLORS.red}
						/>
					</div>
				</div>

				<div className="mx-auto mt-4 mb-3">
					{/* Num. of products per num. of paths */}
					<ConnectingPathsChart dataset={pathCountChartData} />
				</div>
			</div>

			<div className="mt-4 d-flex flex-wrap justify-content-between">
				<div className="flex-fill pe-4 table-responsive">
					<EnhancedTable
						rows={singleSourceProductsRows}
						columns={singleSourceProductsColumns}
						title={'Single-Sourced Products'}
					/>
				</div>

				<div className="flex-fill table-responsive">
					<CollapsibleTable
						dataRows={singleSourceProductPerVendors}
						title={'Bottleneck Vendors'}
						isOpen={false}
					/>
				</div>
			</div>
		</div>
	);
}

export default NetworkGraph;
