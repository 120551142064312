import './GraphLegend.scss'; // Import the CSS file
import { GraphLegendProps } from '../../../types/interfaces';
import React from 'react';
import { GRAPH_COLORS } from 'vna/types/enums';

const GraphLegend = ({
	vendorListName = 'Vendor list name',
}: GraphLegendProps) => {
	const legendItems = [
		{ label: vendorListName, color: GRAPH_COLORS.parent, shape: 'circle', size: 15 },
		{ label: 'Country', color: GRAPH_COLORS.location, shape: 'circle', size: 15 },
		{ label: 'Vendor', color: GRAPH_COLORS.vendor, shape: 'circle', size: 15 },
		{ label: 'Product', color: GRAPH_COLORS.product, shape: 'circle', size: 15 },
		{ label: 'Bridge', color: GRAPH_COLORS.bridge, shape: 'line', size: 15 },
		// { label: "Default Edge", color: "#ccc", size: 15 },
	];

	return (
		<div className="legend-horizontal">
			<ul
				style={{
					listStyle: 'none',
					padding: 0,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{legendItems.map((item, index) => (
					<li
						key={index}
						className="legend-item-horizontal"
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: '15px',
						}}
					>
						<span
							className="legend-color"
							style={{
								width: item.shape === 'circle' ? `${item.size}px` : '5px',
								height: `${item.size}px`,
								display: 'inline-block',
								marginRight: '10px',
								borderRadius: item.shape === 'circle' ? '50%' : '0',
								borderRight:
                  item.shape === 'line' ? `2px solid ${item.color}` : 'none',
								backgroundColor:
                  item.shape === 'line' ? 'transparent' : item.color,
							}}
						></span>
						<span>{item.label}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default GraphLegend;
