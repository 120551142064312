import { TooltipItem } from 'chart.js';
import React from 'react';
import Accordion from 'vna/components/accordion/Accordion';
import BarChartMUI from 'vna/components/charts/BarChartMUI';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { AccordionCountriesPerProductProps, DynamicTableCell } from 'vna/types/interfaces';

function AccordionCountriesPerProduct({countriesPerProduct, countriesPerProductArray}: AccordionCountriesPerProductProps) {
	const countriesPerProductColumns: readonly DynamicTableCell[] = [
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
		{
			id: 'country',
			numeric: false,
			disablePadding: true,
			label: 'Country',
		},
	];

	const countriesPerProductFormatter = (context: TooltipItem<'bar'>) => {
		const product = countriesPerProductArray[context.dataIndex]?.product;
		const countries = Array.from(countriesPerProduct[product] || []);

		return (
			countries.map((value) => value) ||
			context.label ||
			''
		);
	};

	return (
		<Accordion label="No. of countries per product">
			<div className="row gx-4">
				<div className="col-12 col-md-6">
					<BarChartMUI
						dataset={countriesPerProductArray.map((item) => ({
							x: item.product,
							y: item.country as number,
						}))}
						xLabel="Products"
						yLabel="Countries"
						yFullNumbers={true}
						valueFormatter={countriesPerProductFormatter}
					/>
				</div>

				<div className="col-12 col-md-6 mt-2">
					<EnhancedTable
						rows={countriesPerProductArray}
						columns={countriesPerProductColumns}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionCountriesPerProduct;
