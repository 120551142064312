import axios, { AxiosError } from 'axios';

const search = {
	GetSearchParameters: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/searchparameters/${dataToSend}`)
			.then((data: { data: MatchRequestDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchParameterPanel: async (dataToSend: number, organizationId: number | undefined) => {
		if (organizationId === undefined) { organizationId = 0; }
		const response = await axios.get(`${apiUrl}/searchparameterpanel/${dataToSend}/${organizationId}`)
			.then((data: { data: MatchRequestDetailDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchResults: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/searchresults/${dataToSend}`)
			.then((data: { data: MatchResultDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetUsersRequests: async () => {
		const response = await axios.get(`${apiUrl}/usersrequests`)
			.then((data: { data: SearchRequestSummaryDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetMatchResultStats: async (requestId: number, organizationId: number) => {
		const response = await axios.get(`${apiUrl}/matchresultstat/${requestId}/${organizationId}`)
			.then((data: { data: MatchResultDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSavedMatches: async () => {
		const response = await axios.get(`${apiUrl}/savedmatches`)
			.then((data: { data: MatchHistoryDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostRequest: async (dataToSend: MatchRequestDTO) => {
		const response = await axios.post(`${apiUrl}/searchrequest/`, dataToSend)
			.then((data: { data: MatchRequestDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	SaveMatch: async (requestId: number, organizationId: number) => {
		const response = await axios.post(`${apiUrl}/savematch/${requestId}/${organizationId}`)
			.then((data: { data: string }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteSavedMatch: async (requestId: number, organizationId: number) => {
		const response = await axios.delete(`${apiUrl}/savematch/${requestId}/${organizationId}`)
			.then((data: {}) => {
				return data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	SendMatchNotification: async (requestId: number | undefined, organizationId: number | undefined) => {
		const response = await axios.post(`${apiUrl}/sendmatchnotification/${requestId}/${organizationId}`)
			.then((data: { data: string }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const organization = {
	GetOrganization: async () => {
		const response = await axios.get(`${apiUrl}/organization-onboarding`)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetOrganizationList: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/organizationlist`, {
			signal: signal
		})
			.then((data: { data: OrganizationDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchMatchOrganization: async (organizationId: number, requestId: number) => {
		const response = await axios.get(`${apiUrl}/searchmatchorganization/${requestId}/${organizationId}`)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostOrganization: async (dataToSend: OrganizationDTO) => {
		const response = await axios.put(`${apiUrl}/organization-onboarding`, dataToSend)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddOrganization: async (dataToSend: OrganizationDTO) => {
		const response = await axios.post(`${apiUrl}/addorganization/`, dataToSend)
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetCompanyProfile: async (signal?: AbortSignal) => {
		const response = await axios.get(`${apiUrl}/getuserorganization`, {
			signal: signal
		})
			.then((data: { data: OrganizationDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteOrganization: async (dataToSend: number) => {
		const response = await axios.delete(`${apiUrl}/deleteorganization/${dataToSend}`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	ExportOrganization: async (dataToSend: number, organizationName: string) => {
		const response = await axios.get(`${apiUrl}/exportorganization/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				//return data.data;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement('a');
				link.href = url;
				if (organizationName.length < 3) { organizationName = 'Organization'; }
				link.download = `${organizationName}.json`; // Specify the file name
				// Programmatically click the link to trigger the download
				link.click();
				// Cleanup by revoking the temporary URL
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const organizationProduct = {
	GetOrganizationProducts: async () => {
		const response = await axios.get(`${apiUrl}/productlistfororganization/`)
			.then((data: { data: OrganizationProductTypeDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	PostOrganizationProduct: async (dataToSend: OrganizationProductDTO) => {
		const response = await axios.post(`${apiUrl}/addproducttoorganization/`, dataToSend)
			.then((data: { data: OrganizationProductDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err.response?.status;
			});
		return response;
	},
	UpdateOrganizationProduct: async (dataToSend: number) => {
		const response = await axios.post(`${apiUrl}/updateproductfromorganization/${dataToSend}`)
			.then((data: { data: OrganizationProductDTO; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteOrganizationProduct: async (dataToSend: number) => {
		const response = await axios.delete(`${apiUrl}/removeproductfromorganization/${dataToSend}`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	DeleteOrganizationProductType: async (dataToSend: number) => {
		const response = await axios.delete(`${apiUrl}/removeproducttypefromorganization/${dataToSend}`)
			.then((data: { data: boolean; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	}
};
const institution = {
	GetInstitutions: async (dataToSend: OrganizationTypeEnum | undefined) => {
		const response = await axios.get(`${apiUrl}/institutions/${dataToSend}`)
			.then((data: { data: InstitutionDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddInstitution: async (dataToSend: InstitutionDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/addinstitution`, dataToSend)
			.then((data: { data: InstitutionDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const approvedManufacturers = {
	GetApprovedManufacturers: async () => {
		const response = await axios.get(`${apiUrl}/approvedmanufacturers`)
			.then((data: { data: LinkedOrganizationDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	AddApprovedManufacturer: async (dataToSend: LinkedOrganizationDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/addapprovedmanufacturer`, dataToSend)
			.then((data: { data: LinkedOrganizationDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const product = {
	GetProductTypeList: async () => {
		const response = await axios.get(`${apiUrl}/productspeclist/`)
			.then((data: { data: ProductTypeDTO[]; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};
const pickListItems = {
	AddProductSpec: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/addproductspec`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	AddProductTable: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/addproducttype`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	GetProductSpecificationList: async (dataToSend: number | undefined) => {
		const response = await axios.get(`${apiUrl}/getproductspecificationlist/${dataToSend}`)
			.then((data: { data: SpecificationGroupDTO }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
	AddValueToSpecification: async (dataToSend: SpecificationUpsertDTO | undefined) => {
		const response = await axios.post(`${apiUrl}/addspecificationvalue`, dataToSend)
			.then((data: { data: SpecificationUpsertDTO | AxiosError; }) => {
				return data.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return err;
			});
		return response;
	},
};
const dataVisualization = {
	GetNodeConnections: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/dataVisualizationNodes/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {

				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationNodeConnections.json'; // Specify the file name
				// Programmatically click the link to trigger the download
				link.click();
				// Cleanup by revoking the temporary URL
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetNodeVisualization: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/dataVisualizationChart/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationDashboard.json';
				link.click();
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetNodeVisualizationForDashboard: async () => {
		const response = await axios.get(`${apiUrl}/dataVisualizationChart`)
			.then((response) => {
				return response.data;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSearchResults: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/dataVisualizationSearches/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				//return data.data;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationSearches.json'; // Specify the file name
				// Programmatically click the link to trigger the download
				link.click();
				// Cleanup by revoking the temporary URL
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
	GetSavedSearchMatches: async (dataToSend: number) => {
		const response = await axios.get(`${apiUrl}/dataVisualizationSavedMatches/${dataToSend}?format=json`, {
			responseType: 'blob',
		})
			.then((response) => {
				//return data.data;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement('a');
				link.href = url;
				link.download = 'DataVisualizationSavedMatches.json'; // Specify the file name
				// Programmatically click the link to trigger the download
				link.click();
				// Cleanup by revoking the temporary URL
				window.URL.revokeObjectURL(url);
				return true;
			})
			.catch((err: AxiosError) => {
				console.error({ error: err.message });
				return null;
			});
		return response;
	},
};

const locationSuggestions = {
	GetLocationSuggestions: async (dataToSend: string) => {
		try {
			const response = await axios.get(`${apiUrl}/locationsuggestions/${dataToSend}`);
			return response.data;
		} catch (err) {
			console.error({ error: err.message });
			return null;
		}
	},
};
const apiUrl = process.env.REACT_APP_API_URL;
const scopeMatchAPI = {
	search,
	organization,
	institution,
	approvedManufacturers,
	organizationProduct,
	product,
	dataVisualization,
	locationSuggestions,
	pickListItems
};

export default scopeMatchAPI;