import React from 'react';
import { ActionMeta, default as ReactSelect, GroupBase, OptionsOrGroups, Props as ReactSelectProps } from 'react-select';

interface IProps extends ReactSelectProps {
	error?: boolean;
}

export const Select = (props: IProps) => {
	const { options, value, isMulti, onChange, error, ...otherProps } = props;

	return (
		<ReactSelect
			isMulti={isMulti || false}
			options={options}
			value={getValue(value, options)}
			onChange={onChangeVal}
			menuPosition="fixed"
			styles={{
				control: (provided, state) => ({
					...provided,
					backgroundColor: error ? '#FFE6DC' : '#FFFFFF',
					borderRadius: '4px',
					boxShadow: state.isFocused ? 'inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 99, 97, 0.25);' : 'none',
					borderColor: state.isFocused ? '#006361' : error ? '#F15D1E' : '#BDB3AD',
					'&:hover': {
					  borderColor: state.isFocused ? '#006361' : error ? '#F15D1E' : '#BDB3AD',
					},
				}),
				placeholder: (provided) => ({
					...provided,
					color: error ? '#F15D1E' : '#BDB3AD',
				}),
			}}
			{...otherProps}
		/>
	);

	function getValue(value: unknown, options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined) {
		if (Array.isArray(value)) {
			const val = options?.filter((v: any) => value.findIndex((j: any) => j.value === v.value) !== -1);

			return val;
		}
		if (typeof value === 'string' || typeof value === 'number') {
			const val = options?.filter((v: any) => v.value === value);

			return val;
		}
		return undefined;
	}

	function onChangeVal(newValue: unknown, actionMeta: ActionMeta<unknown>) {
		if (onChange === undefined) {
			return;
		}
		if (newValue && Array.isArray(newValue)) {
			const value = newValue.map(v => v.value);
			onChange(value, actionMeta);
			return;
		}
		if (newValue && Object.keys(newValue).includes('value')) {
			const thewNewValue = newValue as { value: any; label: any; };
			const value = thewNewValue.value!;
			onChange(value, actionMeta);
			return;
		}
		onChange(undefined, actionMeta);
		return undefined;
	}
};