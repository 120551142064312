import classNames from 'classnames';
import { HeadingProps } from '../../types/interfaces';
import React, { PropsWithChildren } from 'react';

function H6({ children, className }: PropsWithChildren<HeadingProps>) {
	return (
		<h6 className={classNames(className, 'text-sm font-bold dark:text-white')}>
			{children}
		</h6>
	);
}

export default H6;
