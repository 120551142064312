import React, { useRef, useEffect, useState, useContext } from 'react';
import { useForm, Controller, useWatch, SubmitHandler, ControllerFieldState } from 'react-hook-form';
import { ModalOverlay, FormGroup, Input, LoadingPage } from 'components/core';
import PickListContext from 'context/PickList/PickListContext';
import MultiSelectAutocomplete from 'screens/PremiumTradeDirectory/components/views/MultiSelectAutocomplete';

interface IProps {
	isOpen: boolean;
	product?: PTDProduct;
	onRequestClose: () => void;
	addUpdateProduct: (
		fields: Partial<PTDProductRequest>,
		id?: number
	) => Promise<boolean>;
	productSpecsList: ProductTypeDTO[] | undefined;
}

interface ProductSpec {
	product?: { id: string; label: string } | null;
	speckListItems?: string[];
	pickListItems?: { id: string; label: string }[];
}

interface FormValues {
	name: string;
	productSpec: ProductSpec;
}

const ERROR_MESSAGES = {
	VALID_PRODUCT_SPEC: 'Please select an option for each product specification. Choose the closest available value, or if there’s nothing suitable or you don’t have a preference, enter “Any.”',
	ONLY_ONE_REQUIRED: 'Only one required!',
	ONE_FIELD_REQUIRED: 'One field of the two is required!',
};

// Functions before they are used
const validProductSpec = (
	productSpecInput: ProductSpec,
	productSpecsList: ProductTypeDTO[] | undefined,
	fieldState: ControllerFieldState,
): string | true => {
	const prodSpec = productSpecsList?.find(s => productSpecInput.product && s.id === parseInt(productSpecInput.product.id));
	if (fieldState.isTouched && productSpecInput.product && productSpecInput.pickListItems && prodSpec?.productSpecCodes
		&& (productSpecInput.pickListItems.length < prodSpec.productSpecCodes.length)
	) {
		return ERROR_MESSAGES.VALID_PRODUCT_SPEC;
	}
	return true;
};

const onlyOneRequired = (
	productNameInput: string,
	productSpecInput: ProductSpec,
	inputValue: string
): string | true => {
	if (
		productNameInput !== '' &&
		productSpecInput.product &&
		inputValue === ''
	) {
		return ERROR_MESSAGES.ONLY_ONE_REQUIRED;
	}
	if (
		!(productNameInput !== '') &&
		!productSpecInput.product &&
		inputValue === ''
	) {
		return ERROR_MESSAGES.ONE_FIELD_REQUIRED;
	}
	return true;
};

function AddProductModal({
	isOpen,
	product,
	onRequestClose,
	addUpdateProduct,
	productSpecsList,
}: IProps) {
	const isMounted = useRef(true);

	const PickListCxt = useContext(PickListContext);
	const [suggestions, setSuggestions] = useState<SuggestionsType>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const [cleanProductSpecList, setCleanProductSpecList] = useState<
		ProductSpecList[]
	>([]);

	const methods = useForm<FormValues>({
		defaultValues: {
			productSpec: {
				product: null,
				speckListItems: [],
				pickListItems: [],
			},
			name: '',
		},
	});

	const productNameInput = useWatch({ control: methods.control, name: 'name' });
	const productSpecInput = useWatch({
		control: methods.control,
		name: 'productSpec',
	});

	methods.register('name', {
		validate: () =>
			onlyOneRequired(productNameInput, productSpecInput, inputValue),
	});
	methods.register('productSpec', {
		validate: {
			validProductSpec: () => validProductSpec(productSpecInput, productSpecsList, methods.getFieldState('productSpec')),
			onlyOneRequired: () =>
				onlyOneRequired(productNameInput, productSpecInput, inputValue),
		},
	});

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const validateProductSpec = () => {
			const result = validProductSpec(productSpecInput, productSpecsList, methods.getFieldState('productSpec'));
			if (result !== true) {
				methods.setError('productSpec', { type: 'manual', message: result });
			} else {
				methods.clearErrors('productSpec');
			}
		};

		validateProductSpec();
	}, [productSpecInput, productSpecsList, methods]);

	useEffect(() => {
		const productSpecList = product?.productSpecList || [];
		setCleanProductSpecList(productSpecList.filter((s) => s.pickListItemName));

		if (product && product.productsTypePickListItemId > 0) {
			methods.setValue('productSpec', {
				product: {
					id: product?.productsTypePickListItemId?.toString() || '',
					label: product?.name || '',
				},
				speckListItems:
					productSpecList.map((spec) => spec.speckListItemId.toString()) || [],
				pickListItems:
					productSpecList.map((spec) => ({
						id: spec.pickListItemId.toString(),
						label: spec.pickListItemName,
					})) || [],
			});
		}

		if (product && !product.productsTypePickListItemId) {
			methods.setValue('name', product?.name || '');
		}
	}, [methods, product]);

	useEffect(() => {
		const getOptions = (productSpecs: ProductTypeDTO): { [key: string]: string[] } => {
			return (productSpecs.productSpecCodes || []).reduce((acc, spec) => {
			  const pickListType = PickListCxt.pickListTypes.find(
					(x) => x.code === spec.productSpecCode
			  );

			  const pickListItems = PickListCxt.pickListItems
					.filter((x) => x.pickListType?.code === pickListType?.code)
					.map((p) => p.code || '');

			  if (pickListItems.length > 0) {
					if (pickListType?.id) {
						acc[pickListType.id] = pickListItems;
					}
			  }

			  return acc;
			}, {} as { [key: string]: string[] });
		};

		if (productSpecsList) {
			const res: SuggestionsType = productSpecsList.reduce((prev, curr) => {
				const currSecs = getOptions(curr);
				if (Object.values(currSecs).length > 0) {
					return { ...prev, [curr.productTypeCode as string]: currSecs };
				}
				return prev;
			}, {} as SuggestionsType);

			setSuggestions(res);
		}
	}, [PickListCxt.pickListItems, PickListCxt.pickListTypes, productSpecsList]);

	const submitForm: SubmitHandler<FormValues> = async (fields) => {
		setIsLoading(true);

		const anyIndexes = fields.productSpec.pickListItems?.reduce((acc, spec, index) => {
			if (spec.id.includes('any-')) {
			  acc.push(index);
			}
			return acc;
		}, [] as number[]);

		const removeIndexes = (array: any[], indexes: number[]) => {
			return array.filter((_, index) => !indexes.includes(index));
		};

		if (anyIndexes && anyIndexes.length > 0) {
			fields.productSpec.speckListItems = removeIndexes(fields.productSpec.speckListItems || [], anyIndexes);
			fields.productSpec.pickListItems = removeIndexes(fields.productSpec.pickListItems || [], anyIndexes);
		}

		const payload = {
			name: fields.name,
			productsTypePickListItemId: parseInt(
				fields.productSpec.product?.id || ''
			),
			speckListItemIds: fields.productSpec.speckListItems?.map((id) =>
				parseInt(id)
			) || [],
			pickListItemIds: fields.productSpec.pickListItems?.map((spec) =>
				parseInt(spec.id)
			) || [],
		};

		if (fields.productSpec.product || fields.name !== '') {
			if (product) {
				// Edit product if it's being passed
				await addUpdateProduct(payload, product.id);
			} else {
				// Add new product
				await addUpdateProduct(payload);
			}

			if (isMounted.current) {
				methods.reset();
				onRequestClose();
				setIsLoading(false);
			}
		} else {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	const close = () => {
		methods.reset();
		onRequestClose();
	};

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<ModalOverlay
			isOpen={isOpen}
			modalSize="modal-dialog-centered"
			onRequestClose={close}
			headerChildren={product ? 'Edit Product' : 'Add New Product'}
			confirmButtonChildren={product ? 'Save Changes' : 'Submit'}
			cancelButtonChildren="Cancel"
			confirmButtonAction={methods.handleSubmit(submitForm)}
			cancelButtonAction={close}
			hideCloseButton={true}
			confirmButtonDisabled={!methods.formState.isValid}
		>
			<Controller
				control={methods.control}
				name="productSpec"
				render={({ field, fieldState }) => (
					<FormGroup
						label="Product Specifications"
						field={field}
						fieldState={fieldState}
					>
						<MultiSelectAutocomplete
							suggestions={suggestions}
							id="productSpec"
							name={field.name}
							value={[
								...(field.value.product?.label || ''),
								...(field.value.pickListItems?.map((i) => i.label) || []),
								...(field.value.speckListItems?.map((i) => i) || []),
							]}
							onChangeValue={(values: ProductSpec) => {
								methods.setValue('productSpec', { ...field.value, ...values }, {
									shouldTouch: true,
								});
							}}
							onChangeInputValue={(value) => {
								setInputValue(value);
								// Debounce the validation triggers
								setTimeout(() => {
									methods.trigger('name');
									methods.trigger('productSpec');
								}, 300);
							}}
							error={!!fieldState.error?.message}
							disabled={productNameInput !== ''}
							categoryEdit={
								product?.productsTypePickListItemId
									? {
										id: product?.productsTypePickListItemId?.toString() || '',
										label: product?.name || '',
									  }
									: undefined
							}
							selectedItemsEdit={cleanProductSpecList.map((spec) => ({
								itemId: spec.pickListItemId.toString(),
								label: spec.pickListItemName,
								typeId: spec.speckListItemId.toString(),
							}))}
						/>
					</FormGroup>
				)}
			/>

			<div className="divider">OR</div>

			<Controller
				control={methods.control}
				name="name"
				render={({ field, fieldState }) => (
					<FormGroup label="Product Name" field={field} fieldState={fieldState}>
						<Input
							id="name"
							placeholder={
								inputValue !== '' || productSpecInput.product
									? ERROR_MESSAGES.ONLY_ONE_REQUIRED
									: 'Write...'
							}
							name={field.name}
							value={field.value}
							onChange={(e) => {
								field.onChange(e);
								// Debounce the validation triggers
								setTimeout(() => {
									methods.trigger('name');
									methods.trigger('productSpec');
								}, 300);
							}}
							error={!!fieldState.error?.message}
							disabled={inputValue !== '' || productSpecInput.product !== null}
						/>
					</FormGroup>
				)}
			/>
		</ModalOverlay>
	);
}

export default AddProductModal;
