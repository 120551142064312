import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip as ChartTooltip,
	Legend,
} from 'chart.js';
import H6 from '../headings/h6';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { GaugeChartProps } from '../../types/interfaces';
import { IconHelpCircle } from 'assets/icons';
import Tooltip from '@mui/material/Tooltip';
import { GAUGE_COLORS } from 'vna/types/enums';

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartTooltip, Legend);

const StyledCard = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	height: '20vh' /* Make the container take % viewport height */,
}));

const HelpIcon = styled(IconHelpCircle)(({ theme }) => ({
	width: 20,
	height: 20,
	marginLeft: theme.spacing(1), // Adds some space between text and icon
	color: '#666',
	cursor: 'pointer', // Makes it look clickable
	'&:hover': {
	  color: '#006361', // Change color on hover
	},
}));

const ChartContainer = styled(Box)(() => ({}));

const GaugeChart = ({
	numOfSum,
	sum = 0,
	title,
	width = 200,
	height = 200,
	tooltipText,
	multiColor,
	defaultColor = GAUGE_COLORS.default,
}: GaugeChartProps) => {
	const percent = Math.round((numOfSum / sum) * 100);

	return (
		<StyledCard className="row row-cols-1">
			<H6 className="col h-25 text-center">
				{title}: {numOfSum}
				<Tooltip title={tooltipText}>
					<HelpIcon />
				</Tooltip>
			</H6>
			<ChartContainer className="col p-0 d-flex align-items-center justify-content-between" m="auto">
				<Gauge
					value={percent}
					valueMax={100}
					startAngle={0}
					endAngle={360}
					innerRadius="80%"
					outerRadius="100%"
					width={width}
					height={height}
					text={({ value }) => `${value}%`}
					sx={() => ({
						[`& .${gaugeClasses.valueArc}`]: {
							fill: !multiColor
								? defaultColor
								: percent <= 10
									? GAUGE_COLORS.yellow
									: percent > 10 && percent <= 25
										? GAUGE_COLORS.orange
										: GAUGE_COLORS.red,
						},
					})}
				/>
			</ChartContainer>
		</StyledCard>
	);
};
export default GaugeChart;
