import H2 from '../headings/h2';
import { AccordionHeaderProps } from '../../types/interfaces';
import React, { PropsWithChildren } from 'react';

function AccordionHeader({children, openAccordion, accordionIndex, onClick}: PropsWithChildren<AccordionHeaderProps>) {
	const att = {
		id: `heading-${accordionIndex}`
	};

	return (
		<H2 className="accordion-header" attributes={att}>
			<button className="accordion-button collapsed w-100 custom-arrow" type="button"
				data-bs-toggle="collapse" data-bs-target={`#collapse-${accordionIndex}`}
				aria-expanded={openAccordion || false} aria-controls={`collapse-${accordionIndex}`} onClick={onClick}>
				{children}
			</button>
		</H2>
	);
}

export default AccordionHeader;
