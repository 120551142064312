
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { ActionIcon, DataTable, LoadingPage, ModalOverlay, Page } from 'components/core';
import React from 'react';
import { toast } from 'react-toastify';
import TitleBar from 'screens/Components/TitleBar';



const OrganizationList = () => {
	const pageTitle = 'Organization List';
	const [organization, setOrganization] = React.useState<OrganizationDTO | null>(null);
	const [organizationList, setOrganizationList] = React.useState<OrganizationDTO[]>();
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	React.useEffect(() => {
		async function getOrganizationList() {
			const orgs = await scopeMatchAPI.organization.GetOrganizationList();
			if (orgs !== null) {
				setOrganizationList(orgs);
			} else {
				toast.error('Unable to retrieve Organization list');
			}
			setIsLoading(false);
		}
		getOrganizationList();
	}, []);

	const handleCancel = React.useCallback(() => {
		setOrganization(null);
	}, []);

	const handleDelete = React.useCallback(async () => {
		if (organization) {
			const response = await scopeMatchAPI.organization.DeleteOrganization(organization.id!);
			if (response !== null) {
				toast.success('Organization  Deleted');
				setOrganizationList(orgs => orgs!.filter(x => x.id != organization.id));	// update the list to exclude the deleted one
			} else {
				toast.error('Unable to delete Organization');
			}
			setOrganization(null);
		}
	}, [organization]);

	const columns = [
		{
			Header: 'Organization Id',
			accessor: 'id',
			show: true
		},
		{
			Header: 'Organization Name',
			accessor: 'organizationName'
		},
		{
			Header: 'Organization Type',
			accessor: 'organizationType'
		},
		{
			Header: 'Company URL',
			accessor: 'companyUrl'
		},
		{
			Header: 'Onboarding Complete',
			accessor: 'OnboardingComplete'
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: any) => {
				// const openDeleteModal = () => setOrganization(rowInfo.original!);
				const exportOrg = () => exportOrganization(rowInfo.original!);
				return (
					<React.Fragment>
						<ActionIcon title="Export Data" type="download" onClick={exportOrg} className="float-right" />
						{/* < ActionIcon title="Delete Bidder" type="delete" onClick={openDeleteModal} /> */}
					</React.Fragment>
				);
			},
			Filter: <React.Fragment></React.Fragment>,
			Header: 'Actions',
			id: 'Actions'
		}

	];
	if (isLoading === true) {
		return <LoadingPage />;
	}
	return (

		<Page title={pageTitle}>
			<div className="d-flex">
				<div className="d-flex flex-column w-100">
					<TitleBar
						pageTitle={pageTitle}
					/>
				</div>
			</div>
			<div className="container-fluid mb-5 px-5">
				<DataTable
					data={organizationList}
					columns={columns}
					filterable={true}
					sortable={true}
					resizable={true}
					noDataText="No Organizations found."
				/>
			</div>
			<ModalOverlay
				isOpen={organization !== null}
				modalSize="modal-md"
				onRequestClose={handleCancel}
				headerChildren={`Delete ${organization?.organizationName}?`}
				confirmButtonChildren="Delete"
				cancelButtonChildren="Cancel"
				cancelButtonAction={handleCancel}
				confirmButtonAction={() => handleDelete()}
			>
				This cannot be undone
			</ModalOverlay>

		</Page >
	);
	async function exportOrganization(org: OrganizationDTO) {
		const response = await scopeMatchAPI.organization.ExportOrganization(org.id!, org.organizationName!);
		if (response === true) {
			toast.success(`${org.organizationName} exported to Json file`);
		} else {
			toast.error('Unable to export Json file');
		}
	}

};

export default OrganizationList;