import React, { useEffect, useRef } from 'react';
import scopeMatchAPI from 'api/ScopeMatchAPI';
import { FormGroup } from 'components/core';
import { debounce } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { Text } from 'screens/ProductCatalog/components/columns';
import classNames from 'classnames';

interface TableColumnOperatingLocationProps {
	vendorFormMethods: UseFormReturn<any, object>,
	row: any,
	editTable: VendorTable | ProductTable | null
}

function TableColumnOperatingLocation({vendorFormMethods, row, editTable}: TableColumnOperatingLocationProps) {
	const selectRef = useRef(null);

	useEffect(() => {
		const observer = new ResizeObserver(() => {
		  // Handle ResizeObserver notifications error
		});

		const currentRef = selectRef.current;
		if (currentRef) {
		  observer.observe(currentRef);
		}

		return () => {
		  if (currentRef) {
				observer.unobserve(currentRef);
		  }
		};
	  }, []);

	const fetchLocationSuggestions = debounce((inputValue, callback) => {
		if (inputValue.length <= 2) {
			callback([]);
			return;
		}

		scopeMatchAPI.locationSuggestions
			.GetLocationSuggestions(inputValue)
			.then((response) => {
				const suggestions = response.map(
					(suggestion: { location: string }) => ({
						label: suggestion.location,
						value: suggestion.location,
					})
				);
				callback(suggestions);
			})
			.catch((error) => {
				console.error(error);
				callback([]);
			});
	}, 1000);

	if (!!editTable) {
		if (editTable?.rowId === row.index) {
			return (
				<div ref={selectRef}>
					<Controller
						key={'operatingLocation' + row.original.id}
						control={vendorFormMethods.control}
						defaultValue={row.value || ''}
						rules={{
							required: 'This is a required field.',
						}}
						name="specs.operatingLocation"
						render={({ field, fieldState }) => {
							return (
								<FormGroup
									field={field}
									fieldState={fieldState}
									className="m-0 p-0"
								>
									<AsyncSelect
										id={'operating-location' + row.original.id}
										name={field.name}
										value={
											field.value
											  ? { label: field.value, value: field.value }
											  : null // Set to null when there's no value
										}
										loadOptions={(inputValue, callback) =>
											fetchLocationSuggestions(inputValue, callback)
										}
										onChange={(selectedOption) => {
											field.onChange(
												selectedOption ? selectedOption.value : ''
											);
										}}
										placeholder="Type location..."
										isClearable={true}
										menuPosition="fixed"
										className={classNames('p-1 pt-1 fs-14 border-radius-4 table-select', !!fieldState.error?.message ? 'custom-input-error' : 'custom-input')}
										styles={{
											control: (provided, state) => ({
												...provided,
												backgroundColor: fieldState.error ? '#FFE6DC' : '#FFFFFF',
												borderRadius: '4px',
												boxShadow: state.isFocused ? 'inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(0, 99, 97, 0.25);' : 'none',
												borderColor: state.isFocused ? '#006361' : fieldState.error ? '#F15D1E' : '#BDB3AD',
												'&:hover': {
												  borderColor: state.isFocused ? '#006361' : fieldState.error ? '#F15D1E' : '#BDB3AD',
												},
											}),
											placeholder: (provided) => ({
												...provided,
												color: fieldState.error ? '#F15D1E' : '#BDB3AD',
											}),
										}}
									/>
								</FormGroup>
							);}}
					/>
				</div>
			);
		}
	}
	if (row.value === undefined || row.value.length === 0) {
		return <Text></Text>;
	}
	return <Text>{row.value}</Text>;
}

export default TableColumnOperatingLocation;
