import React from 'react';
import { TooltipItem } from 'chart.js';
import Accordion from 'vna/components/accordion/Accordion';
import BarChartMUI from 'vna/components/charts/BarChartMUI';
import EnhancedTable from 'vna/components/tables/TableMUI';
import { AccordionVendorsPerProductProps, DynamicTableCell } from 'vna/types/interfaces';

function AccordionVendorsPerProduct({vendorsPerProduct, vendorsPerProductArray}: AccordionVendorsPerProductProps) {
	const vendorsPerProductColumns: readonly DynamicTableCell[] = [
		{
			id: 'product',
			numeric: false,
			disablePadding: false,
			label: 'Product',
		},
		{
			id: 'vendor',
			numeric: false,
			disablePadding: true,
			label: 'Vendor',
		},
	];

	const vendorsPerProductFormatter = (context: TooltipItem<'bar'>) => {
		const product = vendorsPerProductArray[context.dataIndex]?.product;
		const vendors = Array.from(vendorsPerProduct[product] || []).map((vendor) => vendor);

		return (
			vendors.map((value) => value) ||
			context.label ||
			''
		);
	};

	return (
		<Accordion label="No. of vendors per product">
			<div className="row gx-4">
				<div className="col-12 col-md-6">
					<BarChartMUI
						dataset={vendorsPerProductArray.map((item) => ({
							x: item.product,
							y: item.vendor as number,
						}))}
						xLabel="Products"
						yLabel="Vendors"
						yFullNumbers={true}
						valueFormatter={vendorsPerProductFormatter}
					/>
				</div>

				<div className="col-12 col-md-6 mt-2">
					<EnhancedTable
						rows={vendorsPerProductArray}
						columns={vendorsPerProductColumns}
					/>
				</div>
			</div>
		</Accordion>
	);
}

export default AccordionVendorsPerProduct;
