import React from 'react';
import classNames from 'classnames';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	label?: string;
	required?: boolean;
	field?: ControllerRenderProps<any, any>;
	fieldState?: ControllerFieldState;
}

export const FormGroup = (props: IProps) => {
	const { children, label, className, field, fieldState, ...otherProps } = props;
	return (
		<div className={classNames(className?.includes('mb-') ? className : `mb-3 pb-2 ${className}` )} {...otherProps}>
			{label && <label htmlFor={field?.name} className="form-label mb-1 fw-bold me-2">{label}</label>}
			{children}
			{fieldState?.error?.message && <p className="text-danger mt-2 mb-0 text-wrap">{fieldState?.error?.message}</p>}
		</div>
	);
};
