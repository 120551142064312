import React from 'react';
import { IconSectors } from 'assets/icons';
import Pill from 'screens/Requests/components/pills/Pill';

interface OrgSectorsCardProps {
	vendor: PTDVendorSpecs
}

function OrgSectorsCard({vendor}: OrgSectorsCardProps) {

	return (
		<div className="card shadow p-3 mb-2 rounded flex-1">
			<div className="d-flex align-items-center">
				<div className="rounded-circle bg-secondary-100 p-2 me-3">
					<IconSectors />
				</div>
				<h5 className="mb-0">Sectors</h5>
			</div>
			<hr />
			<React.Fragment>
				<div className="d-flex flex-wrap align-items-start">
					<div className="d-flex flex-wrap align-items-start">
						{vendor && vendor.sectors?.length > 0 ?
							vendor.sectors?.map((i, key) => (
								<Pill className="text-primary fw-bold" key={key}>{i}</Pill>
							)) : <p className="text-gray-600 fst-italic">No sectors added</p>}
					</div>
				</div>
			</React.Fragment>
		</div>
	);
}

export default OrgSectorsCard;
