import PTDAPIs from 'api/PTDAPI';
import { PTDVendorsContext } from 'context/PTDVendors/PTDVendorsContext';
import { groupBy } from 'lodash';
import React, { ReactNode, useCallback } from 'react';

interface IProps {
	children: ReactNode;
}

function PTDVendorsStore(props: IProps) {
	const [isInitializingPTDVendors, setIsInitializingPTDVendors] =
		React.useState(true);
	const [isInitializingPTDVendorsTab, setIsInitializingPTDVendorsTab] =
		React.useState(true);
	const [isInitializingPTDVendorsSpecs, setIsInitializingPTDVendorsSpecs] =
		React.useState(false);

	const [PTDVendors, setPTDVendors] = React.useState<{
			[x: string]: PTDVendor[];
		}>({});
	const [PTDVendorsTab, setPTDVendorsTab] = React.useState<PTDVendorTab[]>([]);
	const [PTDVendorsSpecs, setPTDVendorsSpecs] =
		React.useState<PTDVendorSpecs | null>(null);

	const fetchVendorsForPTDList = useCallback(
		async (idPTDList, signal) => {
			setPTDVendors({});
			setIsInitializingPTDVendors(true);
			try {
				const result: PTDVendor[] | null =
					await PTDAPIs.premiumTradeDirectories.GetVendorsPTD(
						{ premiumTradeDirectoryId: idPTDList },
						signal
					);
				if (result) {
					const vendorsPerProductId = groupBy(result, ({ productId }) => {
						return productId;
					});
					setPTDVendors(vendorsPerProductId);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsInitializingPTDVendors(false);
				return true;
			}
		},
		[setPTDVendors, setIsInitializingPTDVendors]
	);

	const fetchVendorsForVendorsTab = useCallback(
		async (idPTDList, signal) => {
			setPTDVendorsTab([]);
			setIsInitializingPTDVendorsTab(true);
			try {
				const result: PTDVendorTab[] | null =
					await PTDAPIs.premiumTradeDirectories.GetVendorsTab(
						{ premiumTradeDirectoryId: idPTDList },
						signal
					);
				if (result) {
					setPTDVendorsTab(result);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsInitializingPTDVendorsTab(false);
				return true;
			}
		},
		[setPTDVendorsTab, setIsInitializingPTDVendorsTab]
	);

	const fetchVendorsSpecs = useCallback(
		async (id, signal) => {
			setPTDVendorsSpecs(null);
			setIsInitializingPTDVendorsSpecs(true);
			try {
				const result: PTDVendorSpecs | null =
					await PTDAPIs.premiumTradeDirectories.GetVendorsSpecs({id}, signal);
				if (result) {
					setPTDVendorsSpecs(result);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsInitializingPTDVendorsSpecs(false);
				return true;
			}
		},
		[setPTDVendorsSpecs, setIsInitializingPTDVendorsSpecs]
	);

	const initialState = {
		isInitializingPTDVendors,
		isInitializingPTDVendorsTab,
		isInitializingPTDVendorsSpecs,
		// Vendor
		fetchVendorsForPTDList,
		fetchVendorsForVendorsTab,
		fetchVendorsSpecs,
		getPTDVendors,
		getPTDVendorsTab,
		getPTDVendorsSpecs,
		addPTDVendor,
		updatePTDVendor,
		deletePTDVendor,
	};

	function getPTDVendors(): { [x: string]: PTDVendor[] } {
		return Object.assign({}, PTDVendors);
	}

	function getPTDVendorsTab(): PTDVendorTab[] {
		return PTDVendorsTab.slice();
	}

	function getPTDVendorsSpecs(): PTDVendorSpecs {
		return Object.assign({}, PTDVendorsSpecs);
	}

	function addPTDVendor(newVendor: PTDVendor, idPTDProduct: number) {
		const completeVendor: PTDVendor = {
			...newVendor,
		};
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			if (temp[idPTDProduct]) {
				temp[idPTDProduct].push(completeVendor);
			} else {
				temp[idPTDProduct] = [completeVendor];
			}
			return temp;
		});
	}

	function updatePTDVendor(newVendor: PTDVendor, idPTDProduct: number) {
		const completeVendor: PTDVendor = {
			...newVendor,
		};
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			if (temp[idPTDProduct]) {
				temp[idPTDProduct] = temp[idPTDProduct].map((v) =>
					v.id === newVendor.id ? completeVendor : v
				);
			} else {
				temp[idPTDProduct] = [completeVendor];
			}
			return temp;
		});
	}

	function deletePTDVendor(idPTDProduct: number, idPTDVendor: number) {
		setPTDVendors((vendors) => {
			const temp = Object.assign({}, vendors);
			temp[idPTDProduct] = temp[idPTDProduct].filter(
				(v) => v.id !== idPTDVendor
			);
			return temp;
		});
	}

	return (
		<PTDVendorsContext.Provider value={initialState}>
			{props.children}
		</PTDVendorsContext.Provider>
	);
}

export default PTDVendorsStore;
